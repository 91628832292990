import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import styled from '@emotion/styled';
import * as S from '../ui';

import {
    setDisplayClientDisplay,
    setDisplayForthcoming,
    setDisplayMode,
    setFilterToDoTicketsInCustomerDisplay,
    setIsDeviceAssembleKDS,
    setItemInProgressMode,
    setOrdersInPreparationInClientDisplay,
} from '../../redux/localConfig/actions';
import { getLocalConfigurationState } from '../../redux/localConfig/selector';
import {
    getIsAssembleEnabled,
    getIsAutoDisplayEnabled,
    getIsDisplayEnabled,
} from '../../redux/configuration/selectors';
import OptionCheckBox from './components/OptionCheckBox';
import { setAutoDisplay } from '../../redux/configuration/actions';
import { Routes } from '../../routes.types';

const Separator = styled.div`
    & > * {
        margin-bottom: 8px;
    }
`;

const OptionInAccordion = styled.div`
    & > div {
        border-radius: 0;
        border-left: none;
        border-right: none;
        border-bottom: none;

        &:first-of-type {
            border-top: none;
        }
    }
`;

const SettingsOptions: React.FunctionComponent = () => {
    const dispatch = useDispatch();

    const {
        displayMode,
        itemInProgressMode,
        displayForthcoming,
        isDeviceAssembleKDS,
        filterToDoTicketsInCustomerDisplay,
        displayClientDisplay,
        ordersInPreparationInClientDisplay,
    } = useSelector(getLocalConfigurationState);

    const isAssembleEnable = useSelector(getIsAssembleEnabled);

    const isAutoDisplayEnabled = useSelector(getIsAutoDisplayEnabled);

    const isDisplayEnabled = useSelector(getIsDisplayEnabled);

    const dispatchDisplayMode = (value: boolean) => {
        dispatch(setDisplayMode(value));
    };

    const dispatchInProgressModeValue = (value: boolean) => {
        dispatch(setItemInProgressMode(value));
    };

    const dispatchDisplayForthcoming = (value: boolean) => {
        dispatch(setDisplayForthcoming(value));
    };

    const dispatchIsDeviceAssembleKDS = (value: boolean) => {
        dispatch(setIsDeviceAssembleKDS(value));
    };

    const dispatchFilterToDoTicketsInCustomerDisplay = (value: boolean) => {
        dispatch(setFilterToDoTicketsInCustomerDisplay(value));
    };

    const dispatchDisplayClientDisplay = (value: boolean) => {
        dispatch(setDisplayClientDisplay(value));
    };

    const dispatchSetAutoDisplay = (value: boolean) => {
        dispatch(setAutoDisplay(value));
    };

    const dispatchNavigateToODS = () => {
        dispatch(push(Routes.CustomerDisplay));
    };

    const dispatchOrdersInPreparationInClientDisplay = (value: boolean) => {
        dispatch(setOrdersInPreparationInClientDisplay(value));
    };

    return (
        <>
            <Separator>
                <OptionCheckBox
                    dataTestId="setting-next-orders"
                    onChange={dispatchDisplayForthcoming}
                    title="Onglet “à venir”"
                    subtitle="Affiche l'onglet des commandes à venir"
                    value={displayForthcoming}
                />
            </Separator>

            <Separator>
                <S.Accordion title="Écran de production">
                    <OptionInAccordion>
                        <OptionCheckBox
                            dataTestId="setting-read-only"
                            onChange={dispatchDisplayMode}
                            title="Mode Lecture seule"
                            subtitle="Désactive les interactions sur les tickets"
                            value={displayMode}
                        />
                        <OptionCheckBox
                            dataTestId="setting-read-only"
                            onChange={dispatchInProgressModeValue}
                            title="Double validation"
                            subtitle="Ajoute une étape de préparation aux produits"
                            value={itemInProgressMode}
                        />
                        {isAssembleEnable && (
                            <OptionCheckBox
                                dataTestId="setting-assemble"
                                onChange={dispatchIsDeviceAssembleKDS}
                                title="Assemblage"
                                value={isDeviceAssembleKDS}
                            />
                        )}
                    </OptionInAccordion>
                </S.Accordion>
            </Separator>
            {isDisplayEnabled && (
                <Separator>
                    <S.Accordion title="Ecran d'affichage des commandes">
                        <OptionInAccordion>
                            <OptionCheckBox
                                dataTestId="setting-display-client-display"
                                onChange={dispatchDisplayClientDisplay}
                                title="Afficheur client"
                                value={displayClientDisplay}
                            />
                            <OptionCheckBox
                                dataTestId="setting-auto-send"
                                onChange={dispatchSetAutoDisplay}
                                title="Envoi automatique"
                                value={isAutoDisplayEnabled}
                            />
                            <OptionCheckBox
                                dataTestId="setting-display-order-ready"
                                onChange={dispatchFilterToDoTicketsInCustomerDisplay}
                                title="Afficher seulement les commandes prêtes"
                                value={filterToDoTicketsInCustomerDisplay}
                            />
                            <OptionCheckBox
                                dataTestId="setting-display-orders-in-preparation"
                                onChange={dispatchOrdersInPreparationInClientDisplay}
                                title="Afficher les commandes en préparation"
                                subtitle="Option pour l'écran côté client"
                                value={ordersInPreparationInClientDisplay}
                            />

                            <S.Card data-testid="setting-ods" onClick={dispatchNavigateToODS}>
                                <S.SettingsLabel>Afficher l&apos;écran client</S.SettingsLabel>
                                <S.SettingsSubtitle> (appuyez n&apos;importe où pour en ressortir) </S.SettingsSubtitle>
                            </S.Card>
                        </OptionInAccordion>
                    </S.Accordion>
                </Separator>
            )}
        </>
    );
};

export default SettingsOptions;
