import * as React from 'react';
import dayjs from 'dayjs';
import dayjsDuration from 'dayjs/plugin/duration';
import styled from '@emotion/styled';

dayjs.extend(dayjsDuration);

const Label = styled.p`
    ${({ theme }) => theme.font.familySemiBold};
    font-size: ${({ theme }) => theme.size.textMedium};
    color: ${({ theme }) => theme.color.white};
    margin: 0;
    opacity: 0.8;
`;

const TIMER_SENSIBILITY_IN_SECONDS = 10;
const roundDurationToClosestTenSeconds = (duration: number): number =>
    Math.round(duration / TIMER_SENSIBILITY_IN_SECONDS) * TIMER_SENSIBILITY_IN_SECONDS;

type Props = {
    duration: number;
};

const oneHour = 60 * 60;

const Timer: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ duration }) => {
    const roundedDuration = roundDurationToClosestTenSeconds(duration);
    const format = roundedDuration > oneHour ? 'H:mm:ss' : 'm:ss';
    const formattedDuration = dayjs.duration(roundedDuration, 's').format(format);

    return <Label data-testid="header-timer">{roundedDuration > 0 ? formattedDuration : '0:00'}</Label>;
};

export default Timer;
