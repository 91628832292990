import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setChannelFilters } from '../../../redux/localConfig/actions';
import * as S from '../../ui';
import styled from '@emotion/styled';
import OptionCheckBox from '../components/OptionCheckBox';
import { getChannelOptionFilters } from '../../../redux/localConfig/selector';
import { enumValues } from '../../../utils/typescript';
import ChannelIcon from '../../tickets/TicketHeader/ChannelIcon';
import { ChannelId } from '../../../redux/tickets/types/ticket';

export const CHANNEL_DISPLAY_NAME: Record<ChannelId, string> = {
    [ChannelId.Kiosk]: 'Borne',
    [ChannelId.Web]: 'Web',
    [ChannelId.Pos]: 'POS',
    [ChannelId.UberEats]: 'UberEats',
    [ChannelId.Deliveroo]: 'Deliveroo',
    [ChannelId.JustEat]: 'JustEat',
};

const Separator = styled.div`
    & > * {
        margin-bottom: 8px;
    }
`;

const OptionInAccordion = styled.div`
    & > div {
        cursor: pointer;
        border-radius: 0;
        border-left: none;
        border-right: none;
        border-bottom: none;

        &:first-of-type {
            border-top: none;
        }
    }
`;

const ChannelsForm: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const channelOptionFilters: Record<ChannelId, boolean> = useSelector(getChannelOptionFilters);

    const onChannelFilterClick = (selectedChannel: ChannelId) => (value: boolean) => {
        const updatedChannelOptionFilters = { ...channelOptionFilters };
        updatedChannelOptionFilters[selectedChannel] = value;
        dispatch(setChannelFilters(updatedChannelOptionFilters));
    };

    return (
        <Separator>
            <S.Accordion
                title="Filtre des commandes par canaux"
                subtitle="Sélectionner un ou plusieurs canaux de votre choix"
            >
                <OptionInAccordion>
                    {enumValues<ChannelId>(ChannelId).map((channelId) => (
                        <OptionCheckBox
                            key={`setting-channel-form-filter-${channelId}`}
                            icon={<ChannelIcon channelId={channelId} stroke="#666666" fill="#666666" />}
                            dataTestId={`setting-channel-form-filter-${channelId}`}
                            onChange={onChannelFilterClick(channelId)}
                            title={CHANNEL_DISPLAY_NAME[channelId]}
                            value={channelOptionFilters[channelId]}
                        />
                    ))}
                </OptionInAccordion>
            </S.Accordion>
        </Separator>
    );
};

export default ChannelsForm;
