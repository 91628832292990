import dayjs from 'dayjs';
import { isGroupedTicket, isSimpleTicketWithGroupKey } from './isGroupedTicket';
import { KdsTicket, KdsTicketGroup, SimpleOrGroupKdsTicket, TicketStatus } from '../../redux/tickets/types/ticket';

const findSameGroupIndex = (list: SimpleOrGroupKdsTicket[], groupingKey: string) =>
    list.findIndex((ticket) => groupingKey != null && ticket.groupingKey === groupingKey);

export const groupTicketsByKey = (tickets: KdsTicket[]): SimpleOrGroupKdsTicket[] => {
    return tickets.reduce<SimpleOrGroupKdsTicket[]>((prevTicketList, currentTicket) => {
        const groupingKey = `${currentTicket.groupingKey}${currentTicket.status === TicketStatus.Cancelled ? 'x' : ''}`;
        const sameGroupTicketIndex = findSameGroupIndex(prevTicketList, groupingKey);

        if (sameGroupTicketIndex < 0) {
            if (isSimpleTicketWithGroupKey(currentTicket)) {
                const groupedTicket: KdsTicketGroup = {
                    groupingKey,
                    tickets: [currentTicket],
                    updatedAt: currentTicket.updatedAt,
                };
                prevTicketList.push(groupedTicket);
            } else {
                prevTicketList.push(currentTicket);
            }

            return prevTicketList;
        }

        const ticket = prevTicketList[sameGroupTicketIndex];

        if (isGroupedTicket(ticket)) {
            const latestUpdatedAt = dayjs(ticket.updatedAt).isAfter(dayjs(currentTicket.updatedAt))
                ? ticket.updatedAt
                : currentTicket.updatedAt;
            ticket.updatedAt = latestUpdatedAt ?? ticket.updatedAt ?? currentTicket.updatedAt;
            ticket.tickets.push(currentTicket);
        }

        return prevTicketList;
    }, []);
};
