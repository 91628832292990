import * as React from 'react';
import styled from '@emotion/styled';
import { colorMatrix } from '../../../../../constants/colors';
import CustomizationContainer from '../../CustomizationContainer';
import { LineCustomization } from '../../../../../redux/tickets/types/kds-line/customization';
import { LineStatus } from '../../../../../redux/tickets/types/kds-line/status';

const Container = styled.div<{ status: LineStatus; depth: number }>`
    background-color: ${({ status, depth }) => colorMatrix[status].formulaHeader.backgroundColor[depth]};
    border-radius: 5px 5px 0 0;
    padding: 15px 8px;
    border: 2px solid;
    border-color: ${({ status, depth }) => colorMatrix[status].formulaHeader.borderColor[depth]};
    border-bottom: 0;

    & > div {
        margin: 10px 0 0;
    }
`;

const Label = styled.p<{ status: LineStatus; depth: number }>`
    ${({ theme }) => theme.font.familySemiBold};
    font-size: ${({ theme }) => theme.size.text};
    color: ${({ status, depth }) => colorMatrix[status].formulaHeader.fontColor[depth]};
    text-decoration-style: solid;
`;

const hasCustomizations = (customizations: LineCustomization[]): boolean => customizations?.length > 0;

type Props = {
    name: string;
    quantity: number;
    status: LineStatus;
    parentPath: string;
    orderId: string;
    depth: number;
    customizations: LineCustomization[];
    dispatchLineClicked: (orderId: string, linePath: string) => void;
};

const FormulaHeader: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    name,
    quantity,
    status,
    parentPath,
    orderId,
    depth,
    customizations,
    dispatchLineClicked,
}) => {
    return (
        <Container
            data-testid="formula-header"
            status={status}
            depth={depth}
            onClick={() => dispatchLineClicked(orderId, parentPath)}
        >
            <Label status={status} depth={depth}>
                {quantity}x {name}
            </Label>
            {hasCustomizations(customizations) && (
                <CustomizationContainer customizations={customizations} status={status} depth={depth} />
            )}
        </Container>
    );
};

export default FormulaHeader;
