import type { RootState } from '..';
import { HardwareState, PrintersState } from './hardware.reducer';

export const getHardware = (state: RootState): HardwareState => state.hardware;

export const getWebBridgeStatus = (state: RootState): boolean | undefined => getHardware(state)?.isWebBridgeConnected;

export const getPrinters = (state: RootState): PrintersState | undefined => getHardware(state)?.printers;

export const isWebBridgeConnected = (state: RootState): boolean => !!getWebBridgeStatus(state);

export const isReady = (state: RootState): boolean => !!getWebBridgeStatus(state);
