import type { ActionType } from 'typesafe-actions';
import { createAction } from 'typesafe-actions';
import { User } from '.';

export type UserActions = ActionType<typeof userSet | typeof userUnset>;

export const userSet = createAction('USER_SET', (user: User, accessToken: string) => ({
    user,
    accessToken,
}))();

export const userUnset = createAction('USER_UNSET')();
