import { put, select, takeEvery } from 'redux-saga/effects';
import type { ActionType } from 'typesafe-actions';
import { get } from 'lodash';
import dayjs from 'dayjs';
import {
    cleanOldTickets,
    groupedLineClicked,
    groupedTicketClicked,
    lineClicked,
    popOverClicked,
    PopOverType,
    ticketClicked,
    undisplayGroupedTickets,
    undisplayTicket,
} from './user.actions';
import type { FireStoreUpdates } from './types';
import {
    getIsAssembleEnabled,
    getIsDoubleValidationEnabled,
    getRecallTimeBoundaries,
} from '../configuration/selectors';
import { assertUnreachable } from '../../utils/typescript';
import { FireStoreUpdatesMap, updateBatchTickets, updateTicket } from './firestore.actions';
import { getRestaurantId } from '../user/selectors';
import { getMapPath, hasChildren } from '../../services/tickets/getMapPath';
import { Routes } from '../../routes.types';
import { getSelector, TicketSelector } from './selectorLibs/getSelectors';
import { getBoardTickets } from './selector';
import { KdsTicket, TicketStatus } from './types/ticket';
import { KdsLine, KdsLinesMap } from './types/kds-line';
import { getLineNextStatus } from './middlewares/getLineNextStatus';
import { getTicketStatusUpdate } from './middlewares/getTicketStatusUpdate';

function getAllKdsLinesUpdates({
    kdsLines,
    isDoubleValidationEnabled,
    parentPath,
    route,
    isAssembleEnabled,
}: {
    kdsLines: KdsLinesMap;
    isDoubleValidationEnabled: boolean;
    isAssembleEnabled: boolean;
    parentPath?: string;
    route: Routes;
}): FireStoreUpdates {
    return Object.values(kdsLines).reduce<FireStoreUpdates>((updates, kdsLine) => {
        const { uuid: kdsLineUuid } = kdsLine;
        if (hasChildren(kdsLine)) {
            return {
                ...updates,
                ...getAllKdsLinesUpdates({
                    kdsLines: kdsLine.kdsLines,
                    isDoubleValidationEnabled,
                    parentPath: getMapPath({ kdsLineUuid, parentPath }),
                    isAssembleEnabled,
                    route,
                }),
            };
        }

        return {
            ...updates,
            [getMapPath({
                kdsLineUuid,
                parentPath,
                attribute: 'status',
            })]: getLineNextStatus({
                kdsLine,
                isDoubleValidationEnabled,
                isAssembleEnabled,
                isAChildren: true,
                route,
            }),
        };
    }, {});
}

function getKdsLineUpdates(
    linePath: string,
    line: KdsLine,
    route: Routes,
    isDoubleValidationEnabled: boolean,
    isAssembleEnabled: boolean,
) {
    let updates: FireStoreUpdates = {};

    if (hasChildren(line)) {
        updates = {
            ...getAllKdsLinesUpdates({
                kdsLines: line.kdsLines,
                isDoubleValidationEnabled,
                parentPath: linePath,
                isAssembleEnabled,
                route,
            }),
        };
    } else {
        updates = {
            [`${linePath}.status`]: getLineNextStatus({
                kdsLine: line,
                isDoubleValidationEnabled,
                isAssembleEnabled,
                isAChildren: false,
                route,
            }),
        };
    }

    return updates;
}

const ticketIsPreparingSinceLessThanTiming =
    (timing: number) =>
    (ticket: KdsTicket): boolean =>
        dayjs().isAfter(dayjs(ticket.prepareAt).add(timing, 'minutes'));

function* getContext(
    orderId: string,
    route: Routes,
): Generator<
    unknown,
    {
        ticket: KdsTicket | undefined;
        restaurantId: number;
        isDoubleValidationEnabled: boolean;
        isAssembleEnabled: boolean;
    }
> {
    const contextTickets = (yield select(getSelector(route))) as ReturnType<TicketSelector>;
    const ticket = contextTickets.find((contextTicket) => contextTicket.orderId === orderId);
    const restaurantId = (yield select(getRestaurantId)) as ReturnType<typeof getRestaurantId>;
    if (restaurantId === undefined) {
        throw new Error('RestaurantId is undefined. The configuration must have been fetched');
    }
    const isDoubleValidationEnabled = (yield select(getIsDoubleValidationEnabled)) as ReturnType<
        typeof getIsDoubleValidationEnabled
    >;
    const isAssembleEnabled = (yield select(getIsAssembleEnabled)) as ReturnType<typeof getIsAssembleEnabled>;

    return { ticket, restaurantId, isDoubleValidationEnabled, isAssembleEnabled };
}

// eslint-disable-next-line complexity
function* handleGroupedLineClicked({
    payload: { clickedLinePath, lines, route },
}: ActionType<typeof groupedLineClicked>): Generator {
    const updateLinesMap: FireStoreUpdatesMap = {};
    const clickedLineIndex = lines.findIndex((line) => line.linePath === clickedLinePath);

    if (clickedLineIndex < 0) {
        return;
    }
    const [clickedLineEvent] = lines.splice(clickedLineIndex, 1);
    const { orderId: clickedOrderId } = clickedLineEvent;
    const {
        ticket: clickedTicket,
        restaurantId: clickedRestaurantId,
        isDoubleValidationEnabled,
        isAssembleEnabled,
    } = yield* getContext(clickedOrderId, route);
    if (clickedTicket == null || clickedTicket.status === TicketStatus.Cancelled) {
        return;
    }
    const clickedLine = get(clickedTicket, clickedLinePath) as KdsLine;
    const clickedLineUpdates = getKdsLineUpdates(
        clickedLinePath,
        clickedLine,
        route,
        isDoubleValidationEnabled,
        isAssembleEnabled,
    );

    if (updateLinesMap[clickedOrderId] == null) {
        updateLinesMap[clickedOrderId] = {};
    }
    updateLinesMap[clickedOrderId] = {
        ...updateLinesMap[clickedOrderId],
        ...getTicketStatusUpdate({
            route,
            clickedLineStatus: clickedLine.status,
            clickedTicketStatus: clickedTicket.status,
        }),
        ...clickedLineUpdates,
    };

    for (const line of lines) {
        const { orderId, linePath } = line;
        const { ticket } = yield* getContext(orderId, route);
        const relatedLine = get(ticket, linePath) as KdsLine;
        relatedLine.status = clickedLine.status;

        if (updateLinesMap[orderId] == null) {
            updateLinesMap[orderId] = {};
        }
        if (ticket == null || ticket.status === TicketStatus.Cancelled) {
            return;
        }

        const updates = getKdsLineUpdates(linePath, relatedLine, route, isDoubleValidationEnabled, isAssembleEnabled);

        updateLinesMap[orderId] = {
            ...updateLinesMap[orderId],
            ...getTicketStatusUpdate({
                route,
                clickedLineStatus: relatedLine.status,
                clickedTicketStatus: ticket.status,
            }),
            ...updates,
        };
    }
    yield put(updateBatchTickets(clickedRestaurantId, updateLinesMap));
}

function* handleGroupedTicketClicked({
    payload: { orderIds, route },
}: ActionType<typeof groupedTicketClicked>): Generator {
    let groupedRestaurantId = -1;
    const updatedTicketsMap: FireStoreUpdatesMap = {};

    for (const orderId of orderIds) {
        const { ticket, restaurantId, isDoubleValidationEnabled, isAssembleEnabled } = yield* getContext(
            orderId,
            route,
        );

        if (updatedTicketsMap[orderId] == null) {
            updatedTicketsMap[orderId] = {};
        }
        if (restaurantId > -1) {
            groupedRestaurantId = restaurantId;
        }
        if (ticket === undefined || ticket.status === TicketStatus.Cancelled) {
            return;
        }
        updatedTicketsMap[orderId] = {
            ...updatedTicketsMap[orderId],
            ...getTicketStatusUpdate({ route, clickedTicketStatus: ticket.status }),
            ...getAllKdsLinesUpdates({
                kdsLines: ticket.kdsLines,
                isDoubleValidationEnabled,
                isAssembleEnabled,
                route,
            }),
        };
    }

    yield put(updateBatchTickets(groupedRestaurantId, updatedTicketsMap));
}

function* handleLineClicked({ payload: { linePath, orderId, route } }: ActionType<typeof lineClicked>): Generator {
    const { ticket, restaurantId, isDoubleValidationEnabled, isAssembleEnabled } = yield* getContext(orderId, route);
    if (ticket === undefined || ticket.status === TicketStatus.Cancelled) {
        return;
    }
    const clickedLine = get(ticket, linePath) as KdsLine;
    if (hasChildren(clickedLine)) {
        yield put(
            updateTicket(orderId, restaurantId, {
                ...getTicketStatusUpdate({
                    route,
                    clickedLineStatus: clickedLine.status,
                    clickedTicketStatus: ticket.status,
                }),
                ...getAllKdsLinesUpdates({
                    kdsLines: clickedLine.kdsLines,
                    isDoubleValidationEnabled,
                    parentPath: linePath,
                    isAssembleEnabled,
                    route,
                }),
            }),
        );
    }

    const updates: FireStoreUpdates = {
        [`${linePath}.status`]: getLineNextStatus({
            kdsLine: clickedLine,
            isDoubleValidationEnabled,
            isAssembleEnabled,
            isAChildren: false,
            route,
        }),
    };
    yield put(
        updateTicket(orderId, restaurantId, {
            ...getTicketStatusUpdate({
                route,
                clickedLineStatus: clickedLine.status,
                clickedTicketStatus: ticket.status,
            }),
            ...updates,
        }),
    );
}

function* handleTicketClicked({ payload: { orderId, route } }: ActionType<typeof ticketClicked>): Generator {
    const { ticket, restaurantId, isDoubleValidationEnabled, isAssembleEnabled } = yield* getContext(orderId, route);
    if (ticket === undefined || ticket.status === TicketStatus.Cancelled) {
        return;
    }
    if (ticket.status === TicketStatus.ToDisplay && route === Routes.Home) {
        return;
    }

    yield put(
        updateTicket(orderId, restaurantId, {
            ...getTicketStatusUpdate({ route, clickedTicketStatus: ticket.status }),
            ...getAllKdsLinesUpdates({
                kdsLines: ticket.kdsLines,
                isDoubleValidationEnabled,
                isAssembleEnabled,
                route,
            }),
        }),
    );
}

function* handleUndisplayGroupedTickets({
    payload: { orderIds },
}: ActionType<typeof undisplayGroupedTickets>): Generator {
    const updateTicketsMap: FireStoreUpdatesMap = {};
    let groupedRestaurantId = -1;

    for (const orderId of orderIds) {
        const { ticket, restaurantId } = yield* getContext(orderId, Routes.Displayer);
        if (ticket === undefined || ticket.status === TicketStatus.Cancelled) {
            return;
        }
        if (restaurantId > -1) {
            groupedRestaurantId = restaurantId;
        }
        if (updateTicketsMap[orderId] == null) {
            updateTicketsMap[orderId] = {};
        }
        updateTicketsMap[orderId] = {
            ...updateTicketsMap[orderId],
            ...{ status: TicketStatus.ToDisplay },
        };
    }
    yield put(updateBatchTickets(groupedRestaurantId, updateTicketsMap));
}

function* handleUndisplayTicket({ payload: { orderId } }: ActionType<typeof undisplayTicket>): Generator {
    const { ticket, restaurantId } = yield* getContext(orderId, Routes.Displayer);
    if (ticket === undefined || ticket.status === TicketStatus.Cancelled) {
        return;
    }

    yield put(updateTicket(orderId, restaurantId, { status: TicketStatus.ToDisplay }));
}

const getPopOverNextStatus = (type: PopOverType): TicketStatus => {
    switch (type) {
        case TicketStatus.ToCancel:
            return TicketStatus.Cancelled;
        case TicketStatus.Modified:
            return TicketStatus.ToDo;
        default:
            return assertUnreachable(type);
    }
};

function* handlePopOverClicked({ payload: { orderIds, type, route } }: ActionType<typeof popOverClicked>): Generator {
    const updateTicketsMap: FireStoreUpdatesMap = {};
    let groupedRestaurantId = -1;

    for (const orderId of orderIds) {
        const { ticket } = yield* getContext(orderId, route);
        if (ticket == null) {
            return;
        }
        if (groupedRestaurantId < 0) {
            groupedRestaurantId = ticket.restaurantId;
        }
        updateTicketsMap[orderId] = { status: getPopOverNextStatus(type) };
    }

    yield put(updateBatchTickets(groupedRestaurantId, updateTicketsMap));
}

function* handleCleanOldTickets(): Generator {
    const restaurantId = (yield select(getRestaurantId)) as ReturnType<typeof getRestaurantId>;
    if (restaurantId === undefined) {
        throw new Error('RestaurantId is undefined. The configuration must have been fetched');
    }

    const [, recallTiming] = (yield select(getRecallTimeBoundaries)) as ReturnType<typeof getRecallTimeBoundaries>;
    const tickets = (yield select(getBoardTickets)) as ReturnType<typeof getBoardTickets>;
    const updateMap: FireStoreUpdatesMap = {};

    const filteredTickets = tickets.filter(ticketIsPreparingSinceLessThanTiming(recallTiming));

    filteredTickets.forEach((ticket) => {
        updateMap[ticket.orderId] = {
            status: TicketStatus.Done,
        };
    });

    yield put(updateBatchTickets(restaurantId, updateMap));
}

export function* ticketInteractionSaga(): Generator {
    yield takeEvery(lineClicked, handleLineClicked);
    yield takeEvery(ticketClicked, handleTicketClicked);
    yield takeEvery(groupedLineClicked, handleGroupedLineClicked);
    yield takeEvery(groupedTicketClicked, handleGroupedTicketClicked);
    yield takeEvery(undisplayTicket, handleUndisplayTicket);
    yield takeEvery(undisplayGroupedTickets, handleUndisplayGroupedTickets);
    yield takeEvery(popOverClicked, handlePopOverClicked);
    yield takeEvery(cleanOldTickets, handleCleanOldTickets);
}
