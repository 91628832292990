export enum LineStatus {
    New = 'NEW',
    QuantityModified = 'QUANTITY_MODIFIED',
    ToDo = 'TO_DO',
    Doing = 'DOING',
    Cancelled = 'CANCELLED',
    ToAssemble = 'TO_ASSEMBLE',
    Done = 'DONE',
    Waiting = 'WAITING',
}
