import * as React from 'react';
import { theme as appTheme } from '../../../theme';
import styled from '@emotion/styled';
import * as S from '../../ui';
import type { KdsTicket } from '../../../redux/tickets/types/ticket';

const Container = styled.div`
    border-radius: 5px;
    padding: 8px;
    background-color: ${appTheme.color.white};
`;

const Space = styled.div`
    height: 8px;
`;

type Props = {
    ticket: KdsTicket;
    dispatchTicketClicked: (orderId: string) => void;
    dispatchUndisplayTicket: (orderId: string) => void;
};

const DisplayedTicketActions: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    dispatchTicketClicked,
    dispatchUndisplayTicket,
    ticket,
}) => {
    const { orderId } = ticket;

    return (
        <Container data-testid="displayed-ticket-actions">
            <S.Button
                text="Commande remise"
                onPress={() => dispatchTicketClicked(orderId)}
                color={appTheme.color.emerald}
            />
            <Space />
            <S.Button
                text="Retirer la commande"
                onPress={() => dispatchUndisplayTicket(orderId)}
                color={appTheme.color.bloodyMary}
            />
        </Container>
    );
};

export default DisplayedTicketActions;
