import * as React from 'react';
import { theme as appTheme } from '../../../../theme';
import { ReactComponent as POS } from './icons/POS.svg';
import { ReactComponent as WEB } from './icons/WEB.svg';
import { ReactComponent as KIOSK } from './icons/KIOSK.svg';
import { ReactComponent as UBEREATS } from './icons/UBEREATS.svg';
import { ReactComponent as DELIVEROO } from './icons/DELIVEROO.svg';
import { ReactComponent as JUSTEAT } from './icons/JUSTEAT.svg';
import { assertUnreachable } from '../../../../utils/typescript';
import { ChannelId } from '../../../../redux/tickets/types/ticket';

const getIconFromChannelId = (
    channelId: ChannelId,
): React.FunctionComponent<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>> => {
    switch (channelId) {
        case ChannelId.Pos:
            return POS;
        case ChannelId.Web:
            return WEB;
        case ChannelId.Kiosk:
            return KIOSK;
        case ChannelId.UberEats:
            return UBEREATS;
        case ChannelId.Deliveroo:
            return DELIVEROO;
        case ChannelId.JustEat:
            return JUSTEAT;
        default:
            return assertUnreachable(channelId);
    }
};

type ChannelIconProps = {
    channelId: ChannelId;
    fill?: string;
    stroke?: string;
};

const ChannelIcon: React.FunctionComponent<React.PropsWithChildren<ChannelIconProps>> = ({
    channelId,
    fill = appTheme.color.white,
    stroke = appTheme.color.white,
}) => {
    const Icon = getIconFromChannelId(channelId);

    return <Icon data-testid={`channel-icon-${channelId}`} width={35} height={35} stroke={stroke} fill={fill} />;
};

export default ChannelIcon;
