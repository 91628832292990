import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as List } from './list.svg';
import { openProductionList, openSettings } from '../../../redux/app/action';

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 30px;
`;

const IconTouchable = styled.div`
    height: 100%;
    max-height: 60px;
    min-width: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-left: 20px;
`;

const Label = styled.div`
    ${({ theme }) => theme.font.avenirHeavy};
    color: ${({ theme }) => theme.color.white};
    font-size: ${({ theme }) => theme.size.textSmall};
    margin-left: 20px;
`;

const HeaderIcons: React.FunctionComponent = () => {
    const dispatch = useDispatch();

    const openSetting = () => {
        dispatch(openSettings());
    };

    const openList = () => {
        dispatch(openProductionList());
    };

    return (
        <Container data-testid="header-icons">
            <IconTouchable onClick={openSetting} data-testid="settings-btn">
                <Settings width={32} height={32} />
            </IconTouchable>

            <IconTouchable onClick={openList} data-testid="list-btn">
                <List width={32} height={32} />
                <Label>Résumé</Label>
            </IconTouchable>
        </Container>
    );
};

export default HeaderIcons;
