import { createAction } from 'typesafe-actions';
import type { ActionType } from 'typesafe-actions';

export type HardwareActions = ActionType<
    typeof hardwareUpdate | typeof hardwareUpdateFailed | typeof requestHardwareUpdate
>;

export const hardwareUpdate = createAction('HARDWARE_UPDATE', (isWebBridgeConnected: boolean, printers: any) => ({
    isWebBridgeConnected,
    printers,
}))();

export const hardwareUpdateFailed = createAction('HARDWARE_UPDATE_FAILED')();
export const requestHardwareUpdate = createAction('REQUEST_HARDWARE_UPDATE')();
