import React from 'react';
import { useSelector } from 'react-redux';
import { getIsDoubleValidationEnabled } from '../../../redux/configuration/selectors';
import styled from '@emotion/styled';
import { theme as appTheme } from '../../../theme';

export const PRODUCIBLE_ITEM_COUNTER_WIDTH = '36px';
export const PRODUCIBLE_ITEM_COUNTER_MARGIN = '8px';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Square = styled.div<{ color: string; bgColor: string }>`
    ${({ theme }) => theme.font.familySemiBold};
    background-color: ${({ bgColor }) => bgColor};
    color: ${({ color }) => color};
    font-size: 18px;
    border-radius: 4px;
    margin-left: ${PRODUCIBLE_ITEM_COUNTER_MARGIN};
    width: ${PRODUCIBLE_ITEM_COUNTER_WIDTH};
    height: ${PRODUCIBLE_ITEM_COUNTER_WIDTH};
    line-height: ${PRODUCIBLE_ITEM_COUNTER_WIDTH};
    text-align: center;
`;

type Props = {
    doingQuantity: number;
    toDoQuantity: number;
};

const ProductionItemCounter: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    toDoQuantity,
    doingQuantity,
}) => {
    const isDoubleValidationEnabled = useSelector(getIsDoubleValidationEnabled);

    return (
        <Container data-testid="item-quantity">
            <Square data-testid="unproduced" color={appTheme.color.black} bgColor={appTheme.color.grey1}>
                {toDoQuantity}
            </Square>
            {isDoubleValidationEnabled && (
                <Square data-testid="produced" color={appTheme.color.white} bgColor={appTheme.color.sweetBlue}>
                    {doingQuantity}
                </Square>
            )}
        </Container>
    );
};

export default ProductionItemCounter;
