import { createAction } from 'typesafe-actions';
import { Routes } from '../../routes.types';
import type { TicketStatus } from './types/ticket';

export const ticketClicked = createAction('TICKET_CLICKED', (orderId: string, route: Routes) => ({
    orderId,
    route,
}))();

export const lineClicked = createAction('LINE_CLICKED', (orderId: string, linePath: string, route: Routes) => ({
    orderId,
    linePath,
    route,
}))();

export const groupedTicketClicked = createAction('GROUPED_TICKET_CLICKED', (orderIds: string[], route: Routes) => ({
    orderIds,
    route,
}))();

export const groupedLineClicked = createAction(
    'GROUPED_LINE_CLICKED',
    (clickedLinePath: string, lines: { orderId: string; linePath: string }[], route: Routes) => ({
        clickedLinePath,
        lines,
        route,
    }),
)();

export const undisplayTicket = createAction('UNDISPLAY_TICKET', (orderId: string) => ({
    orderId,
}))();

export const undisplayGroupedTickets = createAction('UNDISPLAY_GROUPED_TICKETS', (orderIds: string[]) => ({
    orderIds,
}))();

export type PopOverType = TicketStatus.ToCancel | TicketStatus.Modified;
export const popOverClicked = createAction(
    'GROUPED_POP_OVER_CLICKED',
    (orderIds: string[], type: PopOverType, route: Routes) => ({
        orderIds,
        type,
        route,
    }),
)();

export const cleanOldTickets = createAction('CLEAN_OLD_TICKETS')();
