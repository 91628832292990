import styled from '@emotion/styled';

export const SettingsSubtitle = styled.p`
    margin: 0;
    margin-top: 4px;
    ${({ theme }) => theme.font.family};
    color: ${({ theme }) => theme.color.blackGrey};
    font-size: 14px;
    max-width: 100%;
`;
