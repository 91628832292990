import type { ActionType } from 'typesafe-actions';
import { createAction } from 'typesafe-actions';
import { ChannelId, ShortConsumptionModeType } from '../tickets/types/ticket';

export type LocalConfigurationActions = ActionType<
    | typeof getConfiguration
    | typeof setDisplayMode
    | typeof setItemInProgressMode
    | typeof addTagToWhiteList
    | typeof removeTagFromWhiteList
    | typeof setTagWhiteList
    | typeof addTableNameToWhiteList
    | typeof removeTableNameFromWhiteList
    | typeof setTableNameWhiteList
    | typeof setDisplayForthcoming
    | typeof setIsDeviceAssembleKDS
    | typeof setFilterToDoTicketsInCustomerDisplay
    | typeof setDisplayClientDisplay
    | typeof setOrdersInPreparationInClientDisplay
    | typeof setChannelFilters
    | typeof setSelectedPrinters
    | typeof setConsumptionModeFilters
    | typeof setColumnsNumber
    | typeof setCompactMode
>;

export const getConfiguration = createAction('LOCAL_CONFIGURATION_GET')();

export const setDisplayMode = createAction('LOCAL_CONFIGURATION_SET_DISPLAY_MODE', (displayMode: boolean) => ({
    displayMode,
}))();

export const setItemInProgressMode = createAction(
    'LOCAL_CONFIGURATION_SET_ITEM_IN_PROGRESS_MODE',
    (itemInProgressMode: boolean) => ({
        itemInProgressMode,
    }),
)();
export const setDisplayForthcoming = createAction(
    'LOCAL_CONFIGURATION_SET_FORTHCOMING',
    (displayForthcoming: boolean) => ({
        displayForthcoming,
    }),
)();
export const setIsDeviceAssembleKDS = createAction(
    'LOCAL_CONFIGURATION_SET_ASSEMBLE_KDS',
    (isDeviceAssembleKDS: boolean) => ({
        isDeviceAssembleKDS,
    }),
)();

export const addTagToWhiteList = createAction('LOCAL_CONFIGURATION_ADD_TAG_TO_WHITE_LIST', (tag: string) => ({
    tag,
}))();

export const removeTagFromWhiteList = createAction(
    'LOCAL_CONFIGURATION_REMOVE_TAG_FROM_WHITE_LIST',
    (index: number) => ({
        index,
    }),
)();

export const setTagWhiteList = createAction('LOCAL_CONFIGURATION_SET_TAG_WHITE_LIST', (tags: string[]) => ({
    tags,
}))();

export const addTableNameToWhiteList = createAction(
    'LOCAL_CONFIGURATION_ADD_TABLE_NAME_TO_WHITE_LIST',
    (tableName: string) => ({
        tableName,
    }),
)();

export const removeTableNameFromWhiteList = createAction(
    'LOCAL_CONFIGURATION_REMOVE_TABLE_NAME_FROM_WHITE_LIST',
    (index: number) => ({
        index,
    }),
)();

export const setTableNameWhiteList = createAction(
    'LOCAL_CONFIGURATION_SET_TABLE_NAME_WHITE_LIST',
    (tableNames: string[]) => ({
        tableNames,
    }),
)();

export const setFilterToDoTicketsInCustomerDisplay = createAction(
    'LOCAL_CONFIGURATION_SET_CUSTOMER_DISPLAY_ONLY_IF_TO_DISPLAY',
    (filterToDoTicketsInCustomerDisplay: boolean) => ({
        filterToDoTicketsInCustomerDisplay,
    }),
)();

export const setDisplayClientDisplay = createAction(
    'LOCAL_CONFIGURATION_SET_DISPLAY_CLIENT_DIISPLAY',
    (displayClientDisplay: boolean) => ({
        displayClientDisplay,
    }),
)();

export const setOrdersInPreparationInClientDisplay = createAction(
    'SET_ORDERS_IN_PREPARATION_IN_CLIENT_DISPLAY',
    (ordersInPreparationInClientDisplay: boolean) => ({
        ordersInPreparationInClientDisplay,
    }),
)();

export const setChannelFilters = createAction(
    'LOCAL_CONFIGURATION_SET_CHANNEL_FILTERS',
    (channelOptionFilters: Record<ChannelId, boolean>) => ({
        channelOptionFilters,
    }),
)();

export const setSelectedPrinters = createAction(
    'LOCAL_CONFIGURATION_SET_SELECTED_PRINTERS',
    (selectedPrinters: Record<string, boolean>) => ({
        selectedPrinters,
    }),
)();

export const setConsumptionModeFilters = createAction(
    'LOCAL_CONFIGURATION_SET_CONSUMPTION_MODE_FILTERS',
    (consumptionModeOptionFilters: Record<ShortConsumptionModeType, boolean>) => ({
        consumptionModeOptionFilters,
    }),
)();

export const setColumnsNumber = createAction(
    'LOCAL_CONFIGURATION_SET_COLUMNS_NUMBER',
    (columnsNumber: number | null) => ({
        columnsNumber,
    }),
)();

export const setCompactMode = createAction('LOCAL_CONFIGURATION_SET_COMPACT_MODE', (compactMode: boolean) => ({
    compactMode,
}))();
