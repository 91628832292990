import { delay, put } from 'redux-saga/effects';
import { updateClock } from './action';

export const DELAY_IN_SECONDS = 10;
const DELAY_IN_MILLISECONDS = DELAY_IN_SECONDS * 1000;

export function* watchClock(): Generator {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    while (true) {
        yield delay(DELAY_IN_MILLISECONDS);
        yield put(updateClock());
    }
}
