import { boardStatus, boardStatusGrouped } from '../status';
import { KdsTicket, TicketStatus } from '../types/ticket';

export const filterActiveBoardTicketsByStatus = (tickets: KdsTicket[]): KdsTicket[] =>
    tickets.filter(({ status, groupingKey }) => {
        if (groupingKey == null) {
            return boardStatus.includes(status);
        }

        const ticketsInCurrentGroup = tickets.filter((ticket) => groupingKey === ticket.groupingKey);

        return (
            ticketsInCurrentGroup.some((ticket) => boardStatusGrouped.includes(ticket.status)) &&
            !ticketsInCurrentGroup.every(
                (ticket) => ticket.status === TicketStatus.Done || ticket.status === TicketStatus.ToDisplay,
            )
        );
    });
