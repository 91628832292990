import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addTagToWhiteList } from '../../../../redux/localConfig/actions';
import * as S from '../../../ui';
import styled from '@emotion/styled';
import LocalTags from '../LocalTags';
import RemoteTags from '../RemoteTags';

const Container = styled(S.Card)`
    background-color: ${({ theme }) => theme.color.white};
    padding: 0;
    margin-bottom: 8px;
`;

const TagsForm: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const [filterInputText, setFilterInputText] = useState('');

    const addTag = () => {
        if (filterInputText !== '') {
            dispatch(addTagToWhiteList(filterInputText));
            setFilterInputText('');
        }
    };

    const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement> | undefined) => {
        if (event && event.key === 'Enter') {
            event.preventDefault();
            addTag();
        }
    };

    return (
        <Container>
            <S.Padded>
                <S.SettingsLabel> Filtrer les tickets par tags </S.SettingsLabel>
                <S.SettingsSubtitle> Entrez le nom du tag à filtrer </S.SettingsSubtitle>
                <S.Input
                    onChange={(event) => setFilterInputText(event.target.value)}
                    value={filterInputText}
                    onBlur={addTag}
                    style={{ marginTop: 14 }}
                    onKeyPress={onKeyPress}
                    data-testid="tag-input"
                />
            </S.Padded>
            <LocalTags />
            <RemoteTags />
        </Container>
    );
};

export default TagsForm;
