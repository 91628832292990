import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import styled from '@emotion/styled';
import { Routes } from '../../../routes.types';
import { getActualRoute } from '../../../redux/router/selector';
import { resetHistoryTicketQuantity } from '../../../redux/app/action';
import { getIsDisplayForthcoming } from '../../../redux/localConfig/selector';
import { getBoardTicketsLength, getForthcomingTicketsLength } from '../../../redux/tickets/selector';
import Badge from '../../ui/Badge';
import { getDisplayClientDisplay } from '../../../redux/configuration/selectors';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
`;

const TabItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    margin-left: 40px;

    & > span:first-of-type {
        margin-right: 15px;
    }
`;

const Underline = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.color.white};
    height: 7px;
    border-radius: 4px 4px 0 0;
`;

const TabItemText = styled.p<{ isActive: boolean }>`
    ${({ theme }) => theme.font.familySemiBold};
    color: ${({ theme }) => theme.color.white};
    opacity: ${({ isActive }) => (isActive ? '1' : '0.5')};
    font-size: 24px;
    display: flex;
    align-items: center;
    margin: 0 15px;
`;

// eslint-disable-next-line complexity
const HeaderTabs: React.FunctionComponent = () => {
    const actualRoute = useSelector(getActualRoute);
    const dispatch = useDispatch();
    const forthcoming = useSelector(getIsDisplayForthcoming);
    const displayClientDisplay = useSelector(getDisplayClientDisplay);
    const boardLength = useSelector(getBoardTicketsLength);
    const forthcomingLength = useSelector(getForthcomingTicketsLength);

    const goToBoard = () => {
        dispatch(resetHistoryTicketQuantity());
        dispatch(push(Routes.Home));
    };

    const goToHistory = () => {
        dispatch(push(Routes.History));
    };

    const goToForthcoming = () => {
        dispatch(resetHistoryTicketQuantity());
        dispatch(push(Routes.Forthcoming));
    };

    const goToDisplayer = () => {
        dispatch(resetHistoryTicketQuantity());
        dispatch(push(Routes.Displayer));
    };

    return (
        <Container>
            <TabItem onClick={() => goToBoard()}>
                <TabItemText isActive={actualRoute === Routes.Home}>Commande en cours</TabItemText>
                {boardLength > 0 && <Badge text={boardLength} />}
                {actualRoute === Routes.Home && <Underline />}
            </TabItem>
            {forthcoming && (
                <TabItem onClick={() => goToForthcoming()}>
                    <TabItemText isActive={actualRoute === Routes.Forthcoming}>À venir</TabItemText>
                    {forthcomingLength > 0 && <Badge text={forthcomingLength} />}
                    {actualRoute === Routes.Forthcoming && <Underline />}
                </TabItem>
            )}
            {displayClientDisplay && (
                <TabItem onClick={() => goToDisplayer()}>
                    <TabItemText isActive={actualRoute === Routes.Displayer}>Affichage client</TabItemText>
                    {actualRoute === Routes.Displayer && <Underline />}
                </TabItem>
            )}
            <TabItem onClick={() => goToHistory()}>
                <TabItemText isActive={actualRoute === Routes.History}>Historique</TabItemText>
                {actualRoute === Routes.History && <Underline />}
            </TabItem>
        </Container>
    );
};

export default HeaderTabs;
