import { createReducer } from 'typesafe-actions';
import { enumToFilterObject } from '../../utils/typescript';
import {
    addTableNameToWhiteList,
    addTagToWhiteList,
    LocalConfigurationActions,
    removeTableNameFromWhiteList,
    removeTagFromWhiteList,
    setChannelFilters,
    setSelectedPrinters,
    setColumnsNumber,
    setCompactMode,
    setConsumptionModeFilters,
    setDisplayClientDisplay,
    setDisplayForthcoming,
    setDisplayMode,
    setFilterToDoTicketsInCustomerDisplay,
    setIsDeviceAssembleKDS,
    setItemInProgressMode,
    setOrdersInPreparationInClientDisplay,
    setTableNameWhiteList,
    setTagWhiteList,
} from './actions';
import { ChannelId, ShortConsumptionModeType } from '../tickets/types/ticket';

export type LocalConfigurationState = {
    displayMode: boolean;
    itemInProgressMode: boolean;
    tagsWhiteList: string[];
    displayForthcoming: boolean;
    isDeviceAssembleKDS: boolean;
    filterToDoTicketsInCustomerDisplay: boolean;
    displayClientDisplay: boolean;
    ordersInPreparationInClientDisplay: boolean;
    channelOptionFilters: Record<ChannelId, boolean>;
    selectedPrinters: Record<string, boolean>;
    consumptionModeOptionFilters: Record<ShortConsumptionModeType, boolean>;
    tableNamesWhiteList: string[];
    columnsNumber: number | null;
    compactMode: boolean;
};

const defaultState: LocalConfigurationState = {
    displayMode: false,
    itemInProgressMode: false,
    tagsWhiteList: [],
    displayForthcoming: false,
    isDeviceAssembleKDS: false,
    filterToDoTicketsInCustomerDisplay: false,
    displayClientDisplay: false,
    ordersInPreparationInClientDisplay: false,
    channelOptionFilters: enumToFilterObject(ChannelId, true),
    selectedPrinters: {},
    consumptionModeOptionFilters: enumToFilterObject(ShortConsumptionModeType, true),
    tableNamesWhiteList: [],
    columnsNumber: null,
    compactMode: false,
};

export const localConfigurationReducer = createReducer<LocalConfigurationState, LocalConfigurationActions>(defaultState)
    .handleAction(setDisplayMode, (state, { payload: { displayMode } }) => ({
        ...state,
        displayMode,
    }))
    .handleAction(setItemInProgressMode, (state, { payload: { itemInProgressMode } }) => ({
        ...state,
        itemInProgressMode,
    }))
    .handleAction(setTagWhiteList, (state, { payload: { tags } }) => ({
        ...state,
        tagsWhiteList: tags,
    }))
    .handleAction(addTagToWhiteList, (state, { payload: { tag } }) => ({
        ...state,
        tagsWhiteList: [...state.tagsWhiteList, tag],
    }))
    .handleAction(setDisplayForthcoming, (state, { payload: { displayForthcoming } }) => ({
        ...state,
        displayForthcoming,
    }))
    .handleAction(removeTagFromWhiteList, (state, { payload: { index: indexToRemove } }) => ({
        ...state,
        tagsWhiteList: [...state.tagsWhiteList.filter((_, index) => index !== indexToRemove)],
    }))
    .handleAction(setTableNameWhiteList, (state, { payload: { tableNames } }) => ({
        ...state,
        tableNamesWhiteList: tableNames,
    }))
    .handleAction(addTableNameToWhiteList, (state, { payload: { tableName } }) => ({
        ...state,
        tableNamesWhiteList: [...state.tableNamesWhiteList, tableName],
    }))
    .handleAction(removeTableNameFromWhiteList, (state, { payload: { index: indexToRemove } }) => {
        const tableNamesWhiteList = [...state.tableNamesWhiteList];
        tableNamesWhiteList.splice(indexToRemove, 1);

        return {
            ...state,
            tableNamesWhiteList,
        };
    })
    .handleAction(setIsDeviceAssembleKDS, (state, { payload: { isDeviceAssembleKDS } }) => ({
        ...state,
        isDeviceAssembleKDS,
    }))
    .handleAction(
        setFilterToDoTicketsInCustomerDisplay,
        (state, { payload: { filterToDoTicketsInCustomerDisplay } }) => ({
            ...state,
            filterToDoTicketsInCustomerDisplay,
        }),
    )
    .handleAction(setDisplayClientDisplay, (state, { payload: { displayClientDisplay } }) => ({
        ...state,
        displayClientDisplay,
    }))
    .handleAction(
        setOrdersInPreparationInClientDisplay,
        (state, { payload: { ordersInPreparationInClientDisplay } }) => ({
            ...state,
            ordersInPreparationInClientDisplay,
        }),
    )
    .handleAction(setChannelFilters, (state, { payload: { channelOptionFilters } }) => ({
        ...state,
        channelOptionFilters,
    }))
    .handleAction(setSelectedPrinters, (state, { payload: { selectedPrinters } }) => ({
        ...state,
        selectedPrinters,
    }))
    .handleAction(setConsumptionModeFilters, (state, { payload: { consumptionModeOptionFilters } }) => ({
        ...state,
        consumptionModeOptionFilters,
    }))
    .handleAction(setColumnsNumber, (state, { payload: { columnsNumber } }) => ({
        ...state,
        columnsNumber,
    }))
    .handleAction(setCompactMode, (state, { payload: { compactMode } }) => ({
        ...state,
        compactMode,
    }));
