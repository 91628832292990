import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setChannelFilters, setSelectedPrinters } from '../../../redux/localConfig/actions';
import * as S from '../../ui';
import styled from '@emotion/styled';
import OptionCheckBox from '../components/OptionCheckBox';
import { getPrinters, isWebBridgeConnected } from '../../../redux/hardware/hardware.selectors';
import { Container } from '@material-ui/core';
import { getSelectedPrinters } from '../../../redux/localConfig/selector';
import { resetPrintedTickets } from '../../../redux/print/print.actions';
import { getPrintedTicketsMap } from '../../../redux/print/print.selectors';

const Separator = styled.div`
    & > * {
        margin-bottom: 8px;
    }
`;

const OptionInAccordion = styled.div`
    & > div {
        cursor: pointer;
        border-radius: 0;
        border-left: none;
        border-right: none;
        border-bottom: none;

        &:first-of-type {
            border-top: none;
        }
    }
`;

const ChannelsForm: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const isWebBridgeEnabled = useSelector(isWebBridgeConnected);
    const printers = useSelector(getPrinters);
    //  {"USB:/dev/bus/usb/001/007": {"bd": "", "deviceId": "TM PrinterUSB:/dev/bus/usb/001/007", "driverType": 0, "ip": "", "mac": "", "name": "TM Printer", "target": "USB:/dev/bus/usb/001/007", "type": "PRINTER"}

    const selectedPrinters: Record<string, boolean> = useSelector(getSelectedPrinters);

    const onPrinterSelectionClick = (selectedPrinter: string) => (value: boolean) => {
        const updatedSelectedPrinters = { ...selectedPrinters };
        updatedSelectedPrinters[selectedPrinter] = value;
        dispatch(setSelectedPrinters(updatedSelectedPrinters));
    };

    return (
        <Separator>
            <S.Accordion title="Préférences d'impressions" subtitle="Sélectionner une ou plusieurs imprimantes">
                <Container>
                    <S.Padded>
                        <S.SettingsSubtitle>
                            {' '}
                            {isWebBridgeEnabled ? '✅ Connection active' : '❌ Pas de connexion avec le hardware.'}{' '}
                        </S.SettingsSubtitle>
                    </S.Padded>
                </Container>
                {printers && (
                    <OptionInAccordion>
                        {Object.keys(printers).map((printer) => (
                            <OptionCheckBox
                                key={`setting-printer-form-filter-${printers[printer].target}`}
                                dataTestId={`setting-printer-form-filter-${printers[printer].target}`}
                                onChange={onPrinterSelectionClick(printers[printer].target)}
                                title={printers[printer].target}
                                value={selectedPrinters && selectedPrinters[printers[printer].target]}
                            />
                        ))}
                    </OptionInAccordion>
                )}
                {!printers && (
                    <Container>
                        <S.Padded>
                            <S.SettingsSubtitle> ❌ Pas d'imprimantes détectées </S.SettingsSubtitle>
                        </S.Padded>
                    </Container>
                )}
            </S.Accordion>
        </Separator>
    );
};

export default ChannelsForm;
