import { createMigrate } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { migrations } from './migrations';

export const persistConfig = {
    key: 'root',
    whitelist: ['auth', 'user', 'localConfiguration', 'print'],
    storage,
    version: 8,
    migrate: createMigrate(migrations),
};
