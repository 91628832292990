import { createReducer } from 'typesafe-actions';
import {
    bumpHistoryTicketQuantity,
    openProductionList,
    openSettings,
    resetHistoryTicketQuantity,
    setConnectionErrorCount,
    udpateDrawerPosition,
    updateInfoDisplay,
} from './action';
import type { AppActions } from './action';
import { AppState, DrawerComponentType } from './types';

export const DEFAULT_HISTORY_TICKET_QUANTITY = 15;

const defaultState: AppState = {
    historyTicketQuantity: DEFAULT_HISTORY_TICKET_QUANTITY,
    isDrawerOpen: false,
    drawerComponent: DrawerComponentType.ProductionList,
    isInfoOpen: false,
    connectionErrorCount: 0,
};

export const appReducer = createReducer<AppState, AppActions>(defaultState)
    .handleAction(bumpHistoryTicketQuantity, (state) => ({
        ...state,
        historyTicketQuantity: state.historyTicketQuantity + DEFAULT_HISTORY_TICKET_QUANTITY,
    }))
    .handleAction(resetHistoryTicketQuantity, (state) => ({
        ...state,
        historyTicketQuantity: DEFAULT_HISTORY_TICKET_QUANTITY,
    }))
    .handleAction(udpateDrawerPosition, (state, { payload: { isDrawerOpen } }) => ({
        ...state,
        isDrawerOpen,
    }))
    .handleAction(openProductionList, (state) => ({
        ...state,
        isDrawerOpen: true,
        drawerComponent: DrawerComponentType.ProductionList,
    }))
    .handleAction(openSettings, (state) => ({
        ...state,
        isDrawerOpen: true,
        drawerComponent: DrawerComponentType.Settings,
    }))
    .handleAction(updateInfoDisplay, (state, { payload: { isInfoOpen } }) => ({
        ...state,
        isInfoOpen,
    }))
    .handleAction(setConnectionErrorCount, (state, { payload: { connectionErrorCount } }) => ({
        ...state,
        connectionErrorCount,
    }));
