export enum BRIDGE_MESSAGE {
    ERROR_BRIDGE_MESSAGE_NOT_FOUND = 'ERROR_BRIDGE_MESSAGE_NOT_FOUND',
    ERROR_CATCH = 'ERROR_CATCH',
    PRINT_KDS_TICKET = 'PRINT_KDS_TICKET',
    PING = 'PING',
    PONG = 'PONG',
    BRIDGE_READY = 'BRIDGE_READY',
    PRINTER_LIST_UPDATED = 'PRINTER_LIST_UPDATED',
    PRINTER_START_DISCOVER = 'PRINTER_START_DISCOVER',
    GET_PRINTERS_STATUS = 'GET_PRINTERS_STATUS',
    SET_PRINTERS_STATUS = 'SET_PRINTERS_STATUS',
    PRINT_TICKET = 'PRINT_TICKET',
    PRINT_INGENICO_TICKET = 'PRINT_INGENICO_TICKET',
    PRINT_TICKET_SUCCEEDED = 'PRINT_TICKET_SUCCEEDED',
    RING = 'RING',
    RING_DRIVE = 'RING_DRIVE',
    RFID_INITIATE_CARD_READER = 'RFID_INITIATE_CARD_READER',
    RFID_START_CARD_DISCOVERY = 'RFID_START_CARD_DISCOVERY',
    RFID_STOP_CARD_DISCOVERY = 'RFID_STOP_CARD_DISCOVERY',
    RFID_CARD_READ = 'RFID_CARD_READ',
    INGENICO_START = 'INGENICO_START',
    INGENICO_ACTION = 'INGENICO_ACTION',
    INGENICO_ACTION_SUCCESS = 'INGENICO_ACTION_SUCCESS',
    INGENICO_ACTION_FAIL = 'INGENICO_ACTION_FAIL',
    INGENICO_TICKET_TO_PRINT = 'INGENICO_TICKET_TO_PRINT',
    ORDER_PRINT_SUCCEEDED = 'ORDER_PRINT_SUCCEEDED',
    ORDER_PRINT_RETRY = 'ORDER_PRINT_RETRY',
    ORDER_PRINT_ERROR = 'ORDER_PRINT_ERROR',
    ORDER_PRINT_STARTING = 'ORDER_PRINT_STARTING',
    LOG = 'LOG',
    NEPTING_PINPAD_INITIALIZE = 'NEPTING_PINPAD_INITIALIZE',
    NEPTING_PINPAD_START_TRANSACTION = 'NEPTING_PINPAD_START_TRANSACTION',
    NEPTING_PINPAD_ABORT_TRANSACTION = 'NEPTING_PINPAD_ABORT_TRANSACTION',
    NEPTING_PINPAD_GET_TERMINAL_INFORMATION = 'NEPTING_PINPAD_GET_TERMINAL_INFORMATION',
    NEPTING_PINPAD_RESPONSE = 'NEPTING_PINPAD_RESPONSE',
    IOPAY_IS_ENABLED = 'IOPAY_IS_ENABLED',
    IOPAY_IS_ENABLED_RESPONSE = 'IOPAY_IS_ENABLED_RESPONSE',
    IOPAY_START_PAYMENT = 'IOPAY_START_PAYMENT',
    IOPAY_PAYMENT_RESPONSE = 'IOPAY_PAYMENT_RESPONSE',
    SAVE_IOPAY_TERMINAL_CONFIG = 'SAVE_IOPAY_TERMINAL_CONFIG',
    REQUIRE_IOPAY_TERMINAL_CONFIG = 'REQUIRE_IOPAY_TERMINAL_CONFIG',
    REQUIRE_IOPAY_TERMINAL_CONFIG_RESPONSE = 'REQUIRE_IOPAY_TERMINAL_CONFIG_RESPONSE',
    IOPAY_SAVE_SECRET_KEY = 'IOPAY_SAVE_SECRET_KEY',
    KIOSK_PRINTERS_STATUSES_UPDATED = 'KIOSK_PRINTERS_STATUSES_UPDATED',
}

export type SubscriberMap = {
    [name in BRIDGE_MESSAGE]?: CallbackMap;
};

export type CallbackMap = {
    [key: string]: (arg: MessageResponsePayload) => void | undefined;
};

export type WebBridgeMessage = {
    eventName: BRIDGE_MESSAGE;
    eventData: unknown;
    eventTimestamp: string;
};

export type EventDataToSend = {
    message: unknown;
    name: BRIDGE_MESSAGE;
    stack: unknown;
    messageId?: string;
};
export type MessageResponsePayload = {
    payload: unknown;
    error?: string;
    messageId?: string;
};
