import * as React from 'react';
import { isEqual } from 'lodash';
import styled from '@emotion/styled';
import { Routes } from '../../../../routes.types';
import TicketHeader from '../../TicketHeader';
import DisplayedTicketActions from '../../DisplayedTicketActions';
import TicketPopOver from '../../TicketPopOver';
import { PopOverType } from '../../../../redux/tickets/user.actions';
import { useGroupedTicketVm } from './GroupedTicket.viewmodel';
import GroupedTicketBlock from './GroupedTicketBlock/GroupedTicketBlock';
import { KdsTicketGroup, TicketStatus } from '../../../../redux/tickets/types/ticket';
import { KdsLineItemBlock } from '../../../../redux/tickets/types/kds-line-item';
import Overlay from '../../../Overlay/Overlay';
import { TicketBody, TicketContainer } from '../style';

const TicketNumbersBanner = styled.div`
    padding: 12px;
    color: ${({ theme }) => theme.color.white};
    background: ${({ theme }) => theme.color.evilBlack};
    font-weight: ${({ theme }) => theme.font.familyBold};
    margin-bottom: 0;
`;

type Props = {
    ticket: KdsTicketGroup;
    route: Routes;
    isCompactMode: boolean;
    dispatchGroupedTicketClicked: (orderIds: string[]) => void;
    dispatchGroupedLineClicked: (clickedPath: string, lines: { orderId: string; linePath: string }[]) => void;
    dispatchGroupedUndisplayTickets: (orderIds: string[]) => void;
    dispatchStatusChange: (orderIds: string[], type: PopOverType, route: Routes) => void;
};

const GroupedTicket: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    ticket,
    route,
    isCompactMode,
    dispatchGroupedTicketClicked,
    dispatchGroupedLineClicked,
    dispatchGroupedUndisplayTickets,
    dispatchStatusChange,
}) => {
    const {
        orderIds,
        ticketNumbers,
        toCancelTickets,
        modifiedTickets,
        groupedTicket,
        ticketBlocks,
        onGroupedTicketClick,
        onGroupedLineClick,
        onGroupedTicketsUndisplay,
        onPopOverStatusChange,
    } = useGroupedTicketVm({
        ticket,
        dispatchGroupedTicketClicked,
        dispatchGroupedLineClicked,
        dispatchGroupedUndisplayTickets,
        dispatchStatusChange,
    });

    const { orderId, status } = groupedTicket;

    return (
        <TicketContainer data-testid="grouped-ticket" className="ticket-container" compactMode={isCompactMode}>
            <TicketHeader {...groupedTicket} dispatchTicketClicked={() => onGroupedTicketClick(orderIds)} />

            {status === TicketStatus.Displaying ? (
                <DisplayedTicketActions
                    dispatchTicketClicked={() => onGroupedTicketClick(orderIds)}
                    dispatchUndisplayTicket={() => onGroupedTicketsUndisplay(orderIds)}
                    ticket={groupedTicket}
                />
            ) : (
                <>
                    <TicketNumbersBanner>Commandes: {ticketNumbers.join(' - ')}</TicketNumbersBanner>
                    <TicketBody data-testid="ticket-body" compactMode={isCompactMode}>
                        <Overlay isVisible={status === TicketStatus.Cancelled} />
                        {ticketBlocks.map((block: KdsLineItemBlock) => (
                            <GroupedTicketBlock
                                key={`${groupedTicket.ticketNumber}${block.blockKey ?? 'n/a'}`}
                                block={block}
                                orderId={orderId}
                                onGroupedLineClick={onGroupedLineClick}
                                isCompactMode={isCompactMode}
                            />
                        ))}
                    </TicketBody>
                </>
            )}
            {toCancelTickets.length > 0 && (
                <TicketPopOver
                    orderIds={toCancelTickets.map((t) => t.orderId)}
                    type={TicketStatus.ToCancel}
                    route={route}
                    ticketNumbers={toCancelTickets.map((t) => t.ticketNumber)}
                    onPopOverClick={(clickedOrderIds, type, clickedRoute) =>
                        onPopOverStatusChange(clickedOrderIds, type, clickedRoute)
                    }
                />
            )}
            {toCancelTickets.length < 1 && modifiedTickets.length > 0 && (
                <TicketPopOver
                    orderIds={modifiedTickets.map((t) => t.orderId)}
                    type={TicketStatus.Modified}
                    route={route}
                    ticketNumbers={modifiedTickets.map((t) => t.ticketNumber)}
                    onPopOverClick={(clickedOrderIds, type, clickedRoute) =>
                        onPopOverStatusChange(clickedOrderIds, type, clickedRoute)
                    }
                />
            )}
        </TicketContainer>
    );
};

export default React.memo(GroupedTicket, isEqual);
