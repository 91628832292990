import * as Sentry from '@sentry/react';
import { Routes } from '../../../../routes.types';
import { PopOverType } from '../../../../redux/tickets/user.actions';
import { transformTicketsToLineItems } from '../../../../services/tickets/transformTicketsToLineItems';
import { findItemByLinePath } from '../../../../services/tickets/findItemByLinePath';
import { findAllItemsByGroupKey } from '../../../../services/tickets/findAllItemsByGroupKey';
import { sortLinesByIndex } from '../../utils';
import { groupTicketLines } from '../../../../services/tickets/groupTicketLines';
import { KdsTicket, KdsTicketGroup, TicketStatus } from '../../../../redux/tickets/types/ticket';
import { KdsLineItem, KdsLineItemBlock } from '../../../../redux/tickets/types/kds-line-item';
import { createTicketLineBlocks } from '../../../../services/tickets/createTicketLineBlocks';
import { activeStatusComparator } from '../../../../redux/tickets/middlewares/activeStatusComparator';

type PropsVm = {
    ticket: KdsTicketGroup;
    dispatchGroupedTicketClicked: (orderIds: string[]) => void;
    dispatchGroupedLineClicked: (clickedPath: string, lines: { orderId: string; linePath: string }[]) => void;
    dispatchGroupedUndisplayTickets: (orderIds: string[]) => void;
    dispatchStatusChange: (orderIds: string[], type: PopOverType, route: Routes) => void;
};

type ReturnTypeVm = {
    orderIds: string[];
    ticketNumbers: string[];
    toCancelTickets: KdsTicket[];
    modifiedTickets: KdsTicket[];
    groupedTicket: KdsTicket;
    ticketBlocks: KdsLineItemBlock[];
    onGroupedTicketClick: (orderIds: string[]) => void;
    onGroupedLineClick: (clickedLinePath: string) => void;
    onGroupedTicketsUndisplay: (orderIds: string[]) => void;
    onPopOverStatusChange: (orderIds: string[], type: PopOverType, route: Routes) => void;
};

function getGroupedInfoFromTickets(tickets: KdsTicket[]): KdsTicket {
    return tickets.reduce((prevTicket: KdsTicket, currTicket: KdsTicket) => {
        const { status: prevStatus } = prevTicket;
        const { status: currStatus } = currTicket;
        const newTicket: KdsTicket = { ...prevTicket };

        if (!Object.values(currTicket.kdsLines).length) {
            Sentry.captureEvent({
                message: `Ticket ${currTicket.ticketNumber} of grouped ticket arrived without line`,
                extra: {
                    brandId: currTicket.brandId,
                    orderId: currTicket.orderId,
                    restaurantId: currTicket.restaurantId,
                    ticketNumber: currTicket.ticketNumber,
                },
            });
        }

        newTicket.ticketNumber = prevTicket.groupingKey ?? prevTicket.ticketNumber;
        newTicket.customerFirstName = null;
        newTicket.customerLastName = null;

        if (newTicket.tableName == null) {
            newTicket.tableName = currTicket.tableName;
        }
        if (newTicket.updatedAt != null && currTicket.updatedAt != null && currTicket.updatedAt > newTicket.updatedAt) {
            newTicket.updatedAt = currTicket.updatedAt;
        }
        if (currTicket.expectedAt > newTicket.expectedAt) {
            newTicket.expectedAt = currTicket.expectedAt;
        }

        const [groupedStatus] = [prevStatus, currStatus].sort(activeStatusComparator);
        newTicket.status = groupedStatus;

        return newTicket;
    }, tickets[0]);
}

export const useGroupedTicketVm = ({
    ticket,
    dispatchGroupedTicketClicked,
    dispatchGroupedLineClicked,
    dispatchGroupedUndisplayTickets,
    dispatchStatusChange,
}: PropsVm): ReturnTypeVm => {
    const { tickets = [] } = ticket;
    const orderIds = tickets.map((t) => t.orderId);
    const ticketNumbers = tickets.map((t) => t.ticketNumber);
    const toCancelTickets = tickets.filter((t) => t.status === TicketStatus.ToCancel);
    const modifiedTickets = tickets.filter((t) => t.status === TicketStatus.Modified);
    const groupedTicket = getGroupedInfoFromTickets(ticket.tickets);

    const lineItems: KdsLineItem[] = transformTicketsToLineItems(tickets);

    const sortedLines = sortLinesByIndex(lineItems);

    const allLineBlocks: KdsLineItemBlock[] = createTicketLineBlocks(sortedLines);

    const allGroupedLineBlocks = allLineBlocks.map((block) => {
        const groupedBlock = { ...block };
        groupedBlock.lines = groupTicketLines(groupedBlock.lines);

        return groupedBlock;
    });

    const onGroupedLineClick = (clickedLinePath: string) => {
        const clickedLine = findItemByLinePath({ kdsLines: lineItems } as unknown as KdsLineItem, clickedLinePath);
        if (clickedLine) {
            const relatedItems = findAllItemsByGroupKey(
                { kdsLines: lineItems } as unknown as KdsLineItem,
                clickedLine.groupingKey as string,
            );
            const lines = relatedItems.map(({ linePath, orderId: itemOrderId }) => ({
                linePath: linePath as string,
                orderId: itemOrderId,
            }));
            dispatchGroupedLineClicked(clickedLinePath, lines);
        }
    };

    return {
        orderIds,
        ticketNumbers,
        toCancelTickets,
        modifiedTickets,
        groupedTicket,
        ticketBlocks: allGroupedLineBlocks,
        onGroupedTicketClick: dispatchGroupedTicketClicked,
        onGroupedLineClick,
        onGroupedTicketsUndisplay: dispatchGroupedUndisplayTickets,
        onPopOverStatusChange: dispatchStatusChange,
    };
};
