import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import { useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import { ProducibleItem } from '../../redux/tickets/types';
import animationData from './searching-item.json';
import { ReactComponent as TODO } from '../tickets/components/ItemIcon/icons/TO_DO.svg';
import { ReactComponent as DOING } from './DOING.svg';
import {
    PRODUCIBLE_ITEM_COUNTER_MARGIN,
    PRODUCIBLE_ITEM_COUNTER_WIDTH,
} from './ProductionListItem/ProductionItemCounter';
import styled from '@emotion/styled';
import ProductionListItem from './ProductionListItem';
import { getIsDoubleValidationEnabled } from '../../redux/configuration/selectors';
import { DRAWER_HEADER_ACTION_PORTAL_CONTAINER } from '../Drawer/DrawerHeader';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

type ProductionListOptions = {
    isExtended: boolean;
};

const Container = styled.div<ProductionListOptions>`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 0;
    transition: width 0.6s;
    margin: 8px;
    width: ${({ isExtended }) => (isExtended ? '90vw' : '400px')};
`;

const Column = styled.div<{ isExtended: boolean }>`
    display: flex;
    flex-direction: column;
    min-width: ${({ isExtended }) => (isExtended ? '60%' : '95%')};
    max-width: 95%;
`;

const LottieContainer = styled.div`
    width: 400px;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const IconsContainer = styled.div<ProductionListOptions>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding-left: 8px;

    > * {
        margin-left: ${PRODUCIBLE_ITEM_COUNTER_MARGIN};
    }
`;
const IconContainer = styled.div`
    margin-left: ${PRODUCIBLE_ITEM_COUNTER_MARGIN};
    width: ${PRODUCIBLE_ITEM_COUNTER_WIDTH};
    height: ${PRODUCIBLE_ITEM_COUNTER_WIDTH};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ListContainer = styled.div`
    background-color: ${({ theme }) => theme.color.grey3};
    border: 1px solid ${({ theme }) => theme.color.grey1};
    border-radius: 4px;

    & > div:not(:last-of-type) {
        border-bottom: 1px solid ${({ theme }) => theme.color.grey1};
    }
`;

const Label = styled.p`
    text-align: center;
    ${({ theme }) => theme.font.family};
    font-size: ${({ theme }) => theme.size.text};
    padding: 20px;
`;

const StyledButton = styled.button`
    padding: 6px 12px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.color.grey1};
    background-color: ${({ theme }) => theme.color.grey3};
    ${({ theme }) => theme.font.familySemiBold};
    color: ${({ theme }) => theme.color.evilBlack};
    font-size: ${({ theme }) => theme.size.textSmall};
    line-height: 35px;
`;

const Bold = styled.b`
    ${({ theme }) => theme.font.familySemiBold};
`;

type Props = {
    items: ProducibleItem[];
};

const ProductionListExtendButton = ({
    isExtended,
    setExtendedMode,
}: {
    isExtended: boolean;
    setExtendedMode: (isExtended: boolean) => void;
}) => {
    const [parentNode, setParentNode] = useState<HTMLElement | null>(null);

    useEffect(() => {
        setParentNode(document.getElementById(DRAWER_HEADER_ACTION_PORTAL_CONTAINER));
    }, [parentNode]);

    return parentNode
        ? ReactDOM.createPortal(
              <StyledButton onClick={() => setExtendedMode(!isExtended)}>
                  {isExtended ? 'Réduire' : 'Étendre'}
              </StyledButton>,
              parentNode,
          )
        : null;
};

const ProductionList: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ items }) => {
    const isDoubleValidationEnabled = useSelector(getIsDoubleValidationEnabled);
    const [isExtended, setExtendedMode] = useState(false);

    return (
        <>
            <ProductionListExtendButton isExtended={isExtended} setExtendedMode={setExtendedMode} />
            {items.length > 0 && (
                <Container isExtended={isExtended}>
                    <Column isExtended={isExtended}>
                        <IconsContainer isExtended={isExtended}>
                            <IconContainer>
                                <TODO width={25} height={25} />
                            </IconContainer>
                            {isDoubleValidationEnabled && (
                                <IconContainer>
                                    <DOING width={30} height={30} data-testid="doing-icon" />
                                </IconContainer>
                            )}
                        </IconsContainer>
                        <ListContainer>
                            {items.map((item) => (
                                <ProductionListItem {...item} key={`product-list-item-${item.productId}`} />
                            ))}
                        </ListContainer>
                    </Column>
                </Container>
            )}

            {items.length === 0 && (
                <LottieContainer>
                    <Lottie {...defaultOptions} height={350} width={350} />
                    <Label>
                        Pas encore de commande avec des <Bold>produits preparables</Bold>.
                    </Label>
                </LottieContainer>
            )}
        </>
    );
};

export default ProductionList;
