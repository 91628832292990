import { ActionType, createAction } from 'typesafe-actions';
import { AuthState } from '../auth';
import { UserState } from '../user';

export type PersistRehydrateAction = ActionType<typeof persistRehydrate>;

export const persistRehydrate = createAction('persist/REHYDRATE', (user: UserState | null, auth: AuthState | null) => ({
    user,
    auth,
}))();
