import type { ActionType } from 'typesafe-actions';
import { createAction } from 'typesafe-actions';
import type { FireStoreUpdates } from './types';

export type FirestoreTicketsActions =
    | ActionType<typeof synchroniseTickets>
    | ActionType<typeof updateBatchTickets>
    | ActionType<typeof updateTicket>;

export type FireStoreUpdatesMap = {
    [orderId: string]: FireStoreUpdates;
};

export const synchroniseTickets = createAction('SYCHRONISE_TICKETS', (restaurantId: number) => ({
    restaurantId,
}))();

export const updateTicket = createAction(
    'UPDATE_TICKET',
    (orderId: string, restaurantId: number, updates: FireStoreUpdates) => ({
        orderId,
        restaurantId,
        updates,
    }),
)();

export const updateBatchTickets = createAction(
    'UPDATE_BATCH_TICKETS',
    (restaurantId: number, updatesMap: FireStoreUpdatesMap) => ({
        restaurantId,
        updatesMap,
    }),
)();
