import dayjs from 'dayjs';
import { DELAY_IN_SECONDS } from '../../clock/saga';
import { KdsTicket, TicketStatus } from '../types/ticket';

export const nextTick = (now: string): dayjs.Dayjs => dayjs(now).add(DELAY_IN_SECONDS, 'second');

export const filterForthcomingTickets = (tickets: KdsTicket[], now: string): KdsTicket[] =>
    tickets.filter((ticket) =>
        ticket?.groupingKey != null
            ? isPartOfForthcomingTickets(ticket, tickets, now)
            : isForthcomingTicket(ticket, now),
    );

const isPartOfForthcomingTickets = (ticket: KdsTicket, tickets: KdsTicket[], now: string) => {
    return tickets.some((t) => ticket.groupingKey === t.groupingKey && isForthcomingTicket(t, now));
};

export const isForthcomingTicket = (ticket: KdsTicket, now: string): boolean =>
    dayjs(ticket.prepareAt).isAfter(nextTick(now)) || ticket.status === TicketStatus.Waiting;
