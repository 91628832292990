import * as React from 'react';
import styled from '@emotion/styled';
import FormulaHeader from './FormulaHeader';
import TicketItem from '../TicketItem';
import { computeFormulaStatus, lineHasChild, sortLinesByIndex } from '../../utils';
import { getMapPath } from '../../../../services/tickets/getMapPath';
import { colorMatrix } from '../../../../constants/colors';
import { LineStatus } from '../../../../redux/tickets/types/kds-line/status';
import { KdsLine } from '../../../../redux/tickets/types/kds-line';

const FormulaContainer = styled.div<{ status: LineStatus; depth: number }>`
    border-radius: 5px;
    background-color: ${({ status, depth }) => colorMatrix[status].formula.backgroundColor[depth]};
    min-width: 240px;
`;

const FormulaBody = styled.div<{ hasFormulaChild: boolean; status: LineStatus; depth: number }>`
    overflow: hidden;
    padding: ${({ hasFormulaChild }) => (hasFormulaChild ? '4px' : '0')};
    padding-top: 0;
    border: 2px solid;
    border-top: ${({ hasFormulaChild }) => (hasFormulaChild ? '0' : '')};
    border-color: ${({ status, depth }) => colorMatrix[status].formula.borderColor[depth]};
    border-radius: 0 0 5px 5px;
`;

type Props = KdsLine & {
    orderId: string;
    dispatchLineClicked: (orderId: string, linePath: string) => void;
    depth: number;
    parentPath?: string;
    isCompactMode: boolean;
};

const Formula: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    name,
    quantity,
    kdsLines,
    uuid,
    orderId,
    dispatchLineClicked,
    depth,
    parentPath,
    customizations,
    isCompactMode,
}) => {
    const MAX_DEPTH = 2;
    const lines = Object.values(kdsLines);
    const status = computeFormulaStatus(lines);
    const sortedLines = sortLinesByIndex(lines);
    const formulaPath = getMapPath({ kdsLineUuid: uuid, parentPath });
    const isMaxDepth = depth > MAX_DEPTH;
    const hasFormulaChild = sortedLines.some((line) => lineHasChild(line) && !isMaxDepth);

    return (
        <FormulaContainer data-testid="formula" data-depth={depth.toString()} depth={depth} status={status}>
            <FormulaHeader
                {...{
                    name,
                    quantity,
                    orderId,
                    status,
                    dispatchLineClicked,
                    customizations,
                    depth,
                }}
                parentPath={formulaPath}
            />
            <FormulaBody hasFormulaChild={hasFormulaChild} depth={depth} status={status}>
                {sortedLines.map((line, index) =>
                    lineHasChild(line) && !isMaxDepth ? (
                        <Formula
                            {...line}
                            key={line.uuid}
                            orderId={orderId}
                            dispatchLineClicked={dispatchLineClicked}
                            parentPath={formulaPath}
                            depth={depth + 1}
                            isCompactMode={isCompactMode}
                        />
                    ) : (
                        <TicketItem
                            {...line}
                            key={line.uuid}
                            isFormulaItem={!hasFormulaChild}
                            isFirst={index === 0}
                            orderId={orderId}
                            parentPath={formulaPath}
                            dispatchLineClicked={dispatchLineClicked}
                            depth={depth + 1}
                            isCompactMode={isCompactMode}
                        />
                    ),
                )}
            </FormulaBody>
        </FormulaContainer>
    );
};

export default Formula;
