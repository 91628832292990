import * as React from 'react';
import styled from '@emotion/styled';
import { colorMatrix } from '../../../../constants/colors';
import { CustomizationType, LineCustomization } from '../../../../redux/tickets/types/kds-line/customization';
import { LineStatus } from '../../../../redux/tickets/types/kds-line/status';

const Label = styled.p<{ status: LineStatus; depth: number }>`
    ${({ theme }) => theme.font.family};
    font-size: ${({ theme }) => theme.size.text};
    color: ${({ depth, status }) => colorMatrix[status].item.fontColor[depth]};
    text-decoration-line: ${({ status }) => (status === LineStatus.Cancelled ? 'line-through' : '')};
    text-decoration-style: solid;
    padding: 4px 0;
`;

type Props = LineCustomization & { depth: number; status: LineStatus };

const CustomizationItem: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    value,
    quantity,
    depth,
    status,
    type,
}) => {
    if (type === CustomizationType.Remove) {
        return null;
    }
    return (
        <Label data-testid="customisation-item" status={status} depth={depth}>
            {quantity > 1 ? `${quantity}x ${value}` : value}
        </Label>
    );
};

export default CustomizationItem;
