import { createAction } from 'typesafe-actions';
import type { ActionType } from 'typesafe-actions';
import { Printer } from './print.reducer';
import { KdsTicket } from '../tickets/types/ticket';

export type PrintActions = ActionType<
    typeof printTicketFailed | typeof printTicketSucceeded | typeof requestTicketPrint | typeof resetPrintedTickets
>;

export const printTicketFailed = createAction('PRINT_TICKET_FAILED', (ticket: KdsTicket, targetPrinter: Printer) => ({
    ticket,
    targetPrinter,
}))();
export const printTicketSucceeded = createAction(
    'PRINT_TICKET_SUCCEEDED',
    (ticket: KdsTicket, targetPrinter: Printer) => ({
        ticket,
        targetPrinter,
    }),
)();
export const requestTicketPrint = createAction(
    'REQUEST_TICKET_PRINT',
    (ticket: KdsTicket, targetPrinter: Printer, force: boolean) => ({
        ticket,
        targetPrinter,
        force,
    }),
)();

export const resetPrintedTickets = createAction('RESET_PRINTED_TICKETS')();
