import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeTableNameFromWhiteList } from '../../../redux/localConfig/actions';
import { getLocalConfigurationState } from '../../../redux/localConfig/selector';
import styled from '@emotion/styled';
import { ReactComponent as Icon } from '../close.svg';
import Tags from '../tags/Tag';

const TableRemoveButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 18px;
    background-color: ${({ theme }) => theme.color.bloodyMary};
    color: ${({ theme }) => theme.color.grey3};
    margin: 2px;
`;

const LocalTables: React.FunctionComponent = () => {
    const localConfiguration = useSelector(getLocalConfigurationState);
    const dispatch = useDispatch();

    const removeTable = (index: number) => dispatch(removeTableNameFromWhiteList(index));

    return (
        <div>
            {localConfiguration.tableNamesWhiteList.map((tableName, index) => (
                <Tags key={tableName} text={tableName}>
                    <TableRemoveButton data-testid="table-remove" onClick={() => removeTable(index)}>
                        <Icon width={10} height={10} />
                    </TableRemoveButton>
                </Tags>
            ))}
        </div>
    );
};

export default LocalTables;
