import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Gate from '../Gate';
import { Routes } from '../../routes.types';
import { getAuthState } from '../../redux/auth/selectors';
import { getUserState } from '../../redux/user/selectors';
import { getActualRoute } from '../../redux/router/selector';
import { setBeforeLoginRoute } from '../../redux/auth/actions';

type Props = {
    children: React.ReactNode;
};

const AuthGate: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ children }) => {
    const user = useSelector(getUserState);
    const auth = useSelector(getAuthState);
    const currentRoute = useSelector(getActualRoute);
    const dispatch = useDispatch();

    const isLoggedIn: boolean = auth.isAuthenticated || user.user !== null || user.accessToken !== null;

    useEffect(() => {
        if (currentRoute !== Routes.Login && !isLoggedIn) {
            dispatch(setBeforeLoginRoute(currentRoute));
        }
    }, [isLoggedIn, currentRoute, dispatch]);

    return (
        <Gate isActive={isLoggedIn} redirect={Routes.Login}>
            {children}
        </Gate>
    );
};

export default AuthGate;
