import dayjs from 'dayjs';
import { Routes } from '../../../routes.types';
import { FireStoreUpdates } from '../types';
import { LineStatus } from '../types/kds-line/status';
import { TicketStatus } from '../types/ticket';

const computeDisplayerTicketNextStatus = (
    clickedTicketStatus: TicketStatus,
    isTicketClicked: boolean,
): FireStoreUpdates => {
    switch (true) {
        case isTicketClicked && clickedTicketStatus === TicketStatus.ToDisplay:
            return { status: TicketStatus.Displaying };
        case isTicketClicked && clickedTicketStatus === TicketStatus.Displaying:
            return { status: TicketStatus.Done };
        default:
            return { status: TicketStatus.ToDo };
    }
};

const isCancelled = (clickedLineStatus?: LineStatus, clickedTicketStatus?: TicketStatus) =>
    clickedTicketStatus === TicketStatus.Cancelled || clickedLineStatus === LineStatus.Cancelled;

export function getTicketStatusUpdate({
    route,
    clickedLineStatus,
    clickedTicketStatus,
}: {
    route: Routes;
    clickedLineStatus?: LineStatus;
    clickedTicketStatus: TicketStatus;
}): FireStoreUpdates {
    if (isCancelled(clickedLineStatus, clickedTicketStatus)) {
        return {};
    }

    switch (route) {
        case Routes.Forthcoming:
            return {
                prepareAt: dayjs().toISOString(),
                status: TicketStatus.ToDo,
            };
        case Routes.History:
            return {
                status: TicketStatus.ToDo,
            };
        case Routes.Displayer:
            return computeDisplayerTicketNextStatus(clickedTicketStatus, clickedLineStatus === undefined);
        case Routes.Home:
            if (clickedTicketStatus === TicketStatus.ToDisplay) {
                return {};
            }
            return {
                status: TicketStatus.ToDo,
            };
        default:
            return {
                status: TicketStatus.ToDo,
            };
    }
}
