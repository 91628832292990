import { fork, delay, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { WebBridgeServiceFactory } from '../../services/webBridge/index';
import { hardwareUpdate, hardwareUpdateFailed, requestHardwareUpdate } from './hardware.actions';

const HARDWARE_UPDATE_POLLING_INTERVAL = 1000 * 5;
const HARDWARE_UPDATE_ERROR_POLLING_INTERVAL = 1000 * 5;

function* updateHardwarehandler() {
    while (true) {
        try {
            const bridge = WebBridgeServiceFactory.getInstance();
            bridge.searchConnection(); // improve put in a call

            const isWebBridgeConnected = !!bridge.ready;

            if (isWebBridgeConnected) {
                // look for printer
                bridge.searchPrinter();
                if (bridge.printers) {
                    yield put(hardwareUpdate(isWebBridgeConnected, bridge.printers));
                } else {
                    yield put(hardwareUpdate(isWebBridgeConnected, {}));
                }
            }

            yield delay(HARDWARE_UPDATE_POLLING_INTERVAL);
        } catch (e) {
            yield put(hardwareUpdateFailed());
            yield delay(HARDWARE_UPDATE_ERROR_POLLING_INTERVAL);
        }
    }
}

export function* watchHardwareActions() {
    yield fork(updateHardwarehandler);
    yield takeLatest(getType(requestHardwareUpdate), updateHardwarehandler);
}
