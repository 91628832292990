import React, { ReactElement } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { theme as appTheme } from '../../../../theme';
import * as S from '../../../ui';
import styled from '@emotion/styled';

const OptionContainer = styled(S.Card)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0px;
`;

const OptionTitle = styled.div`
    flex: 1;
`;

const OptionIcon = styled.div`
    margin-right: 16px;
`;

// MUI way to customize components style
const CustomColorCheckbox = withStyles({
    root: {
        color: appTheme.color.slate,
        '&$checked': {
            color: appTheme.color.slate,
        },
    },
    checked: {},
})(Checkbox);

type Props = {
    onChange: (value: boolean) => void;
    value: boolean;
    title: string;
    subtitle?: string;
    dataTestId?: string;
    icon?: ReactElement;
};

const OptionCheckBox: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    onChange,
    icon,
    value,
    title,
    subtitle,
    dataTestId,
}) => {
    return (
        <OptionContainer data-testid={dataTestId} onClick={() => onChange(!value)}>
            {icon && <OptionIcon>{icon}</OptionIcon>}
            <OptionTitle>
                <S.SettingsLabel>{title}</S.SettingsLabel>
                {subtitle !== undefined && <S.SettingsSubtitle>{subtitle}</S.SettingsSubtitle>}
            </OptionTitle>
            <CustomColorCheckbox
                onChange={(_evt: unknown, v: boolean) => onChange(v)}
                checked={value}
                color="default"
            />
        </OptionContainer>
    );
};

export default OptionCheckBox;
