import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes } from '../../../routes.types';
import {
    groupedLineClicked,
    groupedTicketClicked,
    lineClicked,
    popOverClicked,
    PopOverType,
    ticketClicked,
    undisplayGroupedTickets,
    undisplayTicket,
} from '../../../redux/tickets/user.actions';
import { getCompactModeState } from '../../../redux/localConfig/selector';

type PropsVm = {
    isDisplayMode: boolean;
    route: Routes;
};

type ReturnTypeVm = {
    isCompactMode: boolean;
    dispatchUndisplayTicketClicked: (ordersId: string) => void;
    dispatchGroupedUndisplayTickets: (ordersIds: string[]) => void;
    dispatchGroupedTicketClicked: (orderIds: string[]) => void;
    dispatchGroupedLineClicked: (clickedPath: string, lines: { orderId: string; linePath: string }[]) => void;
    dispatchTicketClicked: (orderId: string) => void;
    dispatchLineClicked: (orderId: string, linePath: string) => void;
    dispatchStatusChange: (orderIds: string[], type: PopOverType, dispatchRoute: Routes) => void;
};

export const useTicketCardVm = ({ isDisplayMode, route }: PropsVm): ReturnTypeVm => {
    const dispatch = useDispatch();
    const isCompactMode = useSelector(getCompactModeState);

    const dispatchUndisplayTicketClicked = useCallback(
        (ordersId: string) => {
            if (!isDisplayMode) {
                dispatch(undisplayTicket(ordersId));
            }
        },
        [dispatch, isDisplayMode],
    );

    const dispatchGroupedUndisplayTickets = useCallback(
        (ordersIds: string[]) => {
            if (!isDisplayMode) {
                dispatch(undisplayGroupedTickets(ordersIds));
            }
        },
        [dispatch, isDisplayMode],
    );

    const dispatchGroupedTicketClicked = useCallback(
        (orderIds: string[]) => {
            if (!isDisplayMode) {
                dispatch(groupedTicketClicked(orderIds, route));
            }
        },
        [dispatch, isDisplayMode, route],
    );

    const dispatchGroupedLineClicked = useCallback(
        (clickedLinePath: string, lines: { orderId: string; linePath: string }[]) => {
            if (!isDisplayMode) {
                dispatch(groupedLineClicked(clickedLinePath, lines, route));
            }
        },
        [dispatch, isDisplayMode, route],
    );

    const dispatchTicketClicked = useCallback(
        (orderId: string) => {
            if (!isDisplayMode) {
                dispatch(ticketClicked(orderId, route));
            }
        },
        [dispatch, isDisplayMode, route],
    );

    const dispatchLineClicked = useCallback(
        (orderId: string, linePath: string) => {
            if (!isDisplayMode) {
                dispatch(lineClicked(orderId, linePath, route));
            }
        },
        [dispatch, isDisplayMode, route],
    );

    const dispatchStatusChange = useCallback(
        (orderIds: string[], type: PopOverType, dispatchRoute: Routes) => {
            if (!isDisplayMode) {
                dispatch(popOverClicked(orderIds, type, dispatchRoute));
            }
        },
        [dispatch, isDisplayMode],
    );

    return {
        isCompactMode,
        dispatchUndisplayTicketClicked,
        dispatchGroupedUndisplayTickets,
        dispatchGroupedTicketClicked,
        dispatchGroupedLineClicked,
        dispatchTicketClicked,
        dispatchLineClicked,
        dispatchStatusChange,
    };
};
