import { TicketStatus } from '../types/ticket';

const ActiveTicketStatusFlow: Partial<Record<TicketStatus, number>> = {
    [TicketStatus.Waiting]: 0,
    [TicketStatus.ToDo]: 1,
    [TicketStatus.ToDisplay]: 2,
    [TicketStatus.Displaying]: 3,
    [TicketStatus.Done]: 4,
};

export const activeStatusComparator = (status1: TicketStatus, status2: TicketStatus) => {
    const statusIndex1 = ActiveTicketStatusFlow[status1];
    const statusIndex2 = ActiveTicketStatusFlow[status2];
    const { MAX_SAFE_INTEGER } = Number;

    return (statusIndex1 ?? MAX_SAFE_INTEGER) - (statusIndex2 ?? MAX_SAFE_INTEGER);
};
