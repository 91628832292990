import { theme } from '../../../../theme';
import { LineStatus } from '../../../../redux/tickets/types/kds-line/status';

type TicketColors =
    | 'white'
    | 'electricBlue'
    | 'slime'
    | 'emerald'
    | 'grey1'
    | 'grey2'
    | 'grey3'
    | 'evilBlack'
    | 'goldenHour'
    | 'black'
    | 'bloodyMary'
    | 'peach'
    | 'sweetBlue';

export const getBackgroundColor = (status: LineStatus): TicketColors => {
    switch (status) {
        case LineStatus.New:
        case LineStatus.QuantityModified:
            return 'goldenHour';
        case LineStatus.Doing:
            return 'sweetBlue';

        default:
            return 'grey3';
    }
};

export const getBorderColor = (status: LineStatus): TicketColors => {
    switch (status) {
        case LineStatus.New:
        case LineStatus.QuantityModified:
            return 'peach';
        case LineStatus.Doing:
            return 'sweetBlue';

        default:
            return 'grey1';
    }
};

export const getFontColor = (status: LineStatus): TicketColors => {
    switch (status) {
        case LineStatus.Doing:
        case LineStatus.New:
        case LineStatus.QuantityModified:
            return 'white';
        case LineStatus.Cancelled:
            return 'bloodyMary';
        case LineStatus.Done:
            return 'grey2';

        default:
            return 'evilBlack';
    }
};
export const getCommentFontColor = (status: LineStatus): string => {
    switch (status) {
        case LineStatus.Doing:
        case LineStatus.New:
        case LineStatus.QuantityModified:
            return theme.color.white;
        case LineStatus.Cancelled:
        case LineStatus.Done:
            return theme.color.grey2;
        case LineStatus.ToDo:
            return theme.color.bloodyMary;

        default:
            return theme.color.evilBlack;
    }
};
