import React, { useState } from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { theme } from '../../theme';

// MUI way to customize components style
const StyledOutlinedInput = withStyles({
    input: {
        backgroundColor: theme.color.white,
        borderColor: theme.color.grey2,
        padding: `18px 15px`,
    },
    root: {
        borderRadius: '8px',
    },
})(OutlinedInput);

type Props = {
    onChange: (value: string) => void;
    value: string;
};

const label = 'Mot de passe';

const PasswordInput: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ onChange, value }) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
            <StyledOutlinedInput
                id="outlined-adornment-password"
                data-testid="password"
                type={showPassword ? 'text' : 'password'}
                label={label}
                fullWidth
                value={value}
                onChange={(event) => onChange(event.target.value)}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleClickShowPassword}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                labelWidth={70}
            />
        </FormControl>
    );
};

export default PasswordInput;
