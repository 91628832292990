import type { ActionType } from 'typesafe-actions';
import { createAction } from 'typesafe-actions';

import type { ConfigurationData } from './index';

export type ConfigurationActions = ActionType<
    | typeof configurationGet
    | typeof configurationSet
    | typeof configurationFail
    | typeof configurationSync
    | typeof setAutoDisplay
    | typeof reloadKDS
>;

export const configurationGet = createAction('CONFIGURATION_GET')();

export const configurationSet = createAction('CONFIGURATION_SET', (configuration: ConfigurationData) => ({
    configuration,
    receivedAt: Date.now(),
}))();

export const configurationFail = createAction('CONFIGURATION_FAIL')();

export const configurationSync = createAction('CONFIGURATION_SYNC', (restaurantId: number) => ({
    restaurantId,
}))();

export const setAutoDisplay = createAction('SET_AUTO_DISPLAY', (isAutoDisplayEnabled: boolean) => ({
    isAutoDisplayEnabled,
}))();

export const reloadKDS = createAction('RELOAD_KDS')();
