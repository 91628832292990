import { Routes } from '../../../routes.types';
import { assertUnreachable } from '../../../utils/typescript';
import { KdsLine } from '../types/kds-line';
import { LineStatus } from '../types/kds-line/status';

const computeToDoNextStatus = (isDoubleValidationEnabled: boolean, isAssembleEnabled: boolean): LineStatus => {
    switch (true) {
        case isDoubleValidationEnabled:
            return LineStatus.Doing;
        case !isDoubleValidationEnabled && isAssembleEnabled:
            return LineStatus.ToAssemble;

        default:
            return LineStatus.Done;
    }
};

// eslint-disable-next-line complexity
export function getLineNextStatus({
    kdsLine: { status },
    isDoubleValidationEnabled,
    isAChildren,
    isAssembleEnabled,
    route,
}: {
    kdsLine: KdsLine;
    isDoubleValidationEnabled: boolean;
    isAssembleEnabled: boolean;
    isAChildren: boolean;
    route: Routes;
}): LineStatus {
    if (route === Routes.Forthcoming) {
        return status;
    }

    switch (status) {
        case LineStatus.New:
        case LineStatus.QuantityModified:
            if (isAChildren) {
                return computeToDoNextStatus(isDoubleValidationEnabled, isAssembleEnabled);
            }

            return LineStatus.ToDo;
        case LineStatus.ToDo:
            return computeToDoNextStatus(isDoubleValidationEnabled, isAssembleEnabled);
        case LineStatus.Doing:
            return isAssembleEnabled ? LineStatus.ToAssemble : LineStatus.Done;
        case LineStatus.ToAssemble:
            return LineStatus.Done;
        case LineStatus.Cancelled:
            return LineStatus.Cancelled;
        case LineStatus.Waiting:
            return LineStatus.Waiting;
        case LineStatus.Done:
            if (route === Routes.History) {
                return LineStatus.ToDo;
            }

            return isAChildren ? LineStatus.Done : LineStatus.ToDo;
        default:
            return assertUnreachable(status);
    }
}
