import React from 'react';
import dayjs from 'dayjs';
import Modal from '@material-ui/core/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { theme as styledTheme } from '../../theme';
import styled from '@emotion/styled';
import { getisInfoOpen } from '../../redux/app/selector';
import { updateInfoDisplay } from '../../redux/app/action';
import packageJson from '../../../package.json';
import { getInfoFromUser } from '../../redux/user/getInfoFromUser';
import { getUserState } from '../../redux/user/selectors';
import { InfoLine } from './InfoLine';
import { Button } from '../ui';

const ModalBody = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
    background-color: white;
    border-radius: 10px;
    padding: 24px 24px;
    outline: none;
`;

const InfoTitle = styled.p`
    ${({ theme }) => theme.font.familySemiBold};
    font-size: ${({ theme }) => theme.size.textLarge};
    color: ${({ theme }) => theme.color.evilBlack};
`;

const Version = styled.p`
    margin-bottom: 16px;
    ${({ theme }) => theme.font.familySemiBold};
    font-size: ${({ theme }) => theme.size.textBig};
    color: ${({ theme }) => theme.color.midnightBlue};
`;

const Copyright = styled.p`
    margin-bottom: 24px;
    ${({ theme }) => theme.font.familyLight};
    color: ${({ theme }) => theme.color.evilBlack};
`;
const { version } = packageJson;

const InfoModal: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector(getisInfoOpen);
    const { user } = useSelector(getUserState);
    const userInfo = user ? getInfoFromUser(user) : null;

    const close = () => {
        dispatch(updateInfoDisplay(false));
    };

    return (
        <Modal open={isOpen} onClose={close}>
            <ModalBody>
                <InfoTitle>Kitchen Display</InfoTitle>
                <Version>v{version}</Version>
                {userInfo && (
                    <>
                        <InfoLine title="Enseigne" id={userInfo.brandId} name={userInfo.brandName.toUpperCase()} />
                        <InfoLine
                            title="Restaurant"
                            id={userInfo.restaurantId}
                            name={userInfo.restaurantName.toUpperCase()}
                        />
                        <InfoLine title="Connecté en tant que" name={userInfo.email} />
                    </>
                )}
                <Copyright>©Innovorder {dayjs().year()}</Copyright>
                <Button onPress={close} text="OK" color={styledTheme.color.midnightBlue} />
            </ModalBody>
        </Modal>
    );
};

export default InfoModal;
