import * as Sentry from '@sentry/react';

export const crashReporterMiddleware = (store: any) => (next: any) => (action: any) => {
    try {
        // FIXME
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
        return next(action);
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                // FIXME
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                action,
                // FIXME
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                state: store.getState(),
            },
        });
        throw err;
    }
};
