import { assertUnreachable } from '../../../utils/typescript';
import { KdsTicket, TicketStatus } from '../types/ticket';

// eslint-disable-next-line complexity
const computeStatusWeight = (ticketStatus: TicketStatus): number => {
    switch (ticketStatus) {
        case TicketStatus.Displaying:
            return 0;
        case TicketStatus.ToDisplay:
            return 1;
        case TicketStatus.Waiting:
        case TicketStatus.ToDo:
        case TicketStatus.Modified:
        case TicketStatus.ToCancel:
        case TicketStatus.Cancelled:
        case TicketStatus.Done:
            return 3;
        default:
            return assertUnreachable(ticketStatus);
    }
};

export const sortTicketByStatus = (tickets: KdsTicket[]): KdsTicket[] =>
    tickets.sort(
        ({ status: status1 }, { status: status2 }) => computeStatusWeight(status1) - computeStatusWeight(status2),
    );
