// NPM Modules
import { enumValues, throwIfFalsy } from '../utils/typescript';

type Env = {
    API_URL: string;
    ENV_SENTRY_DSN: string | undefined;
    APP_ENV: AppEnv;
    LAUNCH_DARKLY_CLIENT_ID: string;
    LAUNCH_DARKLY_ACTIVE: boolean;
};

export enum AppEnv {
    Local = 'local',
    Dev = 'dev',
    Preprod = 'preprod',
    Production = 'production',
}

const appEnv = process.env.REACT_APP_ENV;

throwIfFalsy(process.env.REACT_APP_API_URL, `'REACT_APP_API_URL' variable must be set`);
throwIfFalsy(appEnv, `'REACT_APP_ENV' variable must be set`);
throwIfFalsy(process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID, `'REACT_APP_LAUNCH_DARKLY' variable must be set`);
throwIfFalsy(enumValues(AppEnv).includes(appEnv), `APP_ENV=${appEnv} is not allowed`);

export const ENV: Env = {
    API_URL: process.env.REACT_APP_API_URL,
    ENV_SENTRY_DSN: process.env.REACT_APP_ENV_SENTRY_DSN,
    APP_ENV: appEnv as AppEnv, // typsesafe l:22
    LAUNCH_DARKLY_CLIENT_ID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    LAUNCH_DARKLY_ACTIVE: process.env.REACT_APP_LAUNCH_DARKLY_ACTIVE === 'true',
};
