import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { logout } from '../../redux/auth/actions';
import { udpateDrawerPosition } from '../../redux/app/action';
import { getLocalTagsWhiteList, getRemoteTagsWhiteList } from '../../redux/configuration/selectors';

const shadow = `
    -webkit-box-shadow: 0px - 4px 8px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px -4px 8px 0px rgba(0,0,0,0.2);
`;

const Container = styled.div<{ hasShadow: boolean }>`
    ${({ hasShadow }) => (hasShadow ? shadow : '')}
    padding: 8px;
    z-index: 1;
`;

const ActionButton = styled.div<{ secondary?: boolean }>`
    padding: 12px 18px;
    border-radius: 4px;
    border: 1px solid ${({ theme, secondary = false }) => (secondary ? theme.color.bloodyMary : theme.color.grey1)};
    margin-top: 10px;
    background-color: ${({ secondary = false, theme }) => (secondary ? theme.color.bloodyMary : theme.color.grey3)};
`;

const ButtonText = styled.p<{ isWhite?: boolean }>`
    ${({ theme }) => theme.font.familySemiBold};
    color: ${({ isWhite, theme }) => (isWhite !== undefined && isWhite ? theme.color.white : theme.color.evilBlack)};
    font-size: ${({ theme }) => theme.size.textSmall};
    line-height: 35px;
`;

const SettingsActions: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const localTagLength = useSelector(getLocalTagsWhiteList).length;
    const remoteTagLength = useSelector(getRemoteTagsWhiteList).length;
    const shouldDisplayShadow = localTagLength ? localTagLength > 4 : remoteTagLength > 4;

    const dispatchLogout = () => {
        dispatch(udpateDrawerPosition(false));
        dispatch(logout());
    };

    const restart = () => {
        window.location.reload();
    };

    return (
        <Container hasShadow={shouldDisplayShadow}>
            <ActionButton data-testid="restart" onClick={restart}>
                <ButtonText>Redémarrer</ButtonText>
            </ActionButton>

            <ActionButton data-testid="sign-out" secondary onClick={dispatchLogout}>
                <ButtonText isWhite> Se déconnecter </ButtonText>
            </ActionButton>
        </Container>
    );
};

export default SettingsActions;
