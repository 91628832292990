import * as React from 'react';
import styled from '@emotion/styled';
import type { KdsTicket } from '../../../redux/tickets/types/ticket';
import { ReactComponent as ToPayIcon } from '../../../icons/TO_PAY.svg';
import { isOrderPaid } from '../../tickets/utils';
import { theme as mainTheme } from '../../../theme';

const Label = styled.p`
    ${({ theme }) => theme.font.familyBold};
    color: ${({ theme }) => theme.color.white};
    font-size: 72px;
    text-align: center;
`;

const PreparingOrderContainer = styled.div`
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

type Props = {
    ticket: KdsTicket;
};

const PreparingOrder: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ ticket }) => {
    const { ticketNumber, orderStatus } = ticket;
    const shouldDisplayToPayIcon = !isOrderPaid(orderStatus);

    return (
        <PreparingOrderContainer>
            <Label data-testid="in-preparation-order">{ticketNumber}</Label>
            {shouldDisplayToPayIcon && (
                <ToPayIcon
                    data-testid="to-pay-icon"
                    width={64}
                    height={64}
                    stroke={mainTheme.color.white}
                    fill={mainTheme.color.white}
                    strokeWidth={0.5}
                    style={{ marginLeft: '8px' }}
                />
            )}
        </PreparingOrderContainer>
    );
};

export default PreparingOrder;
