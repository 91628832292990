import dayjs from 'dayjs';
import { boardStatus, displayerStatus } from '../../../redux/tickets/status';
import { Routes } from '../../../routes.types';
import { theme as mainTheme } from '../../../theme';
import { ShortConsumptionModeType, TicketStatus } from '../../../redux/tickets/types/ticket';

const routesWithTimer = [Routes.Displayer, Routes.Home];

export const computeDisplayerColor = (ticketStatus: TicketStatus): string => {
    if (ticketStatus === TicketStatus.Displaying) {
        return mainTheme.color.mblue;
    }

    return mainTheme.color.electricBlue;
};

export const computeHeaderBackgroundColor = (
    timer: number,
    timers: number[],
    colors: string[],
    route: Routes,
    status: TicketStatus,
): string => {
    if (timers.length < 2) {
        throw new Error('Missing time boundaries for compinting colors');
    }
    if (colors.length < 3) {
        throw new Error('Missing colors for displaying ticket header');
    }

    switch (true) {
        case route === Routes.History:
            return status === TicketStatus.Cancelled ? mainTheme.color.bloodyMary : mainTheme.color.blackGrey;
        case route === Routes.Forthcoming:
            return mainTheme.color.midnightBlue;
        case route === Routes.Displayer && displayerStatus.includes(status):
            return computeDisplayerColor(status);
        default:
            return computeColorFromTimer(timer, timers, colors);
    }
};

export const computeColorFromTimer = (timer: number, timers: number[], colors: string[]): string => {
    const minutes = timer / 60;

    const [firstBoundarie, secondBoundarie] = timers;
    const [firstColor, secondColor, thirdColor] = colors;

    if (minutes < firstBoundarie) {
        return firstColor;
    }
    if (minutes < secondBoundarie) {
        return secondColor;
    }

    return thirdColor;
};

export const computeShouldDisplayTimer = (status: TicketStatus, route: Routes): boolean =>
    routesWithTimer.includes(route) && boardStatus.includes(status);

// eslint-disable-next-line complexity
export const computeLegendText = ({
    tableName,
    customerFirstName,
    customerLastName,
    expectedAt,
}: {
    consumptionMode: ShortConsumptionModeType;
    ticketNumber: string;
    tableName: string | null;
    customerFirstName: string | null;
    customerLastName: string | null;
    expectedAt: string;
}): string => {
    const informations: string[] = [];

    informations.push(dayjs(expectedAt).format('HH:mm'));

    if (customerFirstName !== null || customerLastName !== null) {
        informations.push(
            `${customerFirstName ?? ''}${customerFirstName !== null && customerLastName !== null ? ' ' : ''}${
                customerLastName ?? ''
            }`,
        );
    }

    if (tableName !== null) {
        informations.push(`Table : ${tableName}`);
    }

    return informations.join(' | ');
};
