import type { ActionType } from 'typesafe-actions';
import { createAction } from 'typesafe-actions';
import { DocumentChangeType } from '@firebase/firestore-types';
import { KdsTicket } from './types/ticket';

/**
 * These actions must only be dispatched from the tickets middleware.
 */

export type StoredTicketsActions = ActionType<typeof updateStoredTickets | typeof updateDoneTickets>;

export type KdsTicketUpdate = {
    ticket: KdsTicket;
    type: DocumentChangeType;
};
export const updateStoredTickets = createAction('UPDATE_STORED_TICKETS', (tickets: KdsTicketUpdate[]) => ({
    tickets,
}))();

export const updateDoneTickets = createAction('UPDATE_DONE_TICKETS', (tickets: KdsTicket[]) => ({
    tickets,
}))();
