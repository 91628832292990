import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setConsumptionModeFilters } from '../../../redux/localConfig/actions';
import * as S from '../../ui';
import styled from '@emotion/styled';
import OptionCheckBox from '../components/OptionCheckBox';
import { getConsumptionModeOptionFilters } from '../../../redux/localConfig/selector';
import ConsumptionModeIcon from '../../tickets/TicketHeader/ConsumptionModeIcon';
import { ShortConsumptionModeType } from '../../../redux/tickets/types/ticket';

export const CONSUMPTION_MODE_DISPLAY_NAME: Record<ShortConsumptionModeType, string> = {
    [ShortConsumptionModeType.SIT_IN]: 'Sur place',
    [ShortConsumptionModeType.TAKE_AWAY]: 'À emporter',
    [ShortConsumptionModeType.DELIVERY]: 'Livraison',
    [ShortConsumptionModeType.DRIVE]: 'Drive',
};

const Separator = styled.div`
    & > * {
        margin-bottom: 8px;
    }
`;

const OptionInAccordion = styled.div`
    & > div {
        cursor: pointer;
        border-radius: 0;
        border-left: none;
        border-right: none;
        border-bottom: none;

        &:first-of-type {
            border-top: none;
        }
    }
`;

const ConsumptionModesForm: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const consumptionModeOptionFilters = useSelector(getConsumptionModeOptionFilters);
    const consumptionModes = Object.values(ShortConsumptionModeType);

    const onConsumptionModeFilterClick = (selectedConsumptionMode: ShortConsumptionModeType) => (value: boolean) => {
        const updatedConsumptionModeOptionFilters = { ...consumptionModeOptionFilters };
        updatedConsumptionModeOptionFilters[selectedConsumptionMode] = value;
        dispatch(setConsumptionModeFilters(updatedConsumptionModeOptionFilters));
    };

    return (
        <Separator>
            <S.Accordion
                dataTestId="consumption-mode-form-accordion"
                title="Filtre des commandes par mode de consommation"
                subtitle="Sélectionner un ou plusieurs modes de consommation de votre choix"
            >
                <OptionInAccordion>
                    {consumptionModes.map((consumptionMode) => (
                        <OptionCheckBox
                            key={`setting-consumption-mode-form-filter-${consumptionMode}`}
                            icon={<ConsumptionModeIcon mode={consumptionMode} fill="#666666" stroke="#666666" />}
                            dataTestId={`setting-consumption-mode-form-filter-${consumptionMode}`}
                            onChange={onConsumptionModeFilterClick(consumptionMode)}
                            title={CONSUMPTION_MODE_DISPLAY_NAME[consumptionMode]}
                            value={consumptionModeOptionFilters[consumptionMode] ?? false}
                        />
                    ))}
                </OptionInAccordion>
            </S.Accordion>
        </Separator>
    );
};

export default ConsumptionModesForm;
