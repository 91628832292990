import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CloseIcon } from './close.svg';
import { ReactComponent as InfoIcon } from './info.svg';
import { DrawerComponentType } from '../../../redux/app/types';
import { getDrawerComponentType } from '../../../redux/app/selector';
import { udpateDrawerPosition, updateInfoDisplay } from '../../../redux/app/action';
import styled from '@emotion/styled';

export const DRAWER_HEADER_ACTION_PORTAL_CONTAINER = 'drawer-header-action-button-placeholder';

const Container = styled.div`
    padding: 15px 20px 11px 20px;
    margin-bottom: 8px;
    display: grid;
    grid-template-columns: max-content max-content 1fr max-content;
    grid-template-areas: 'title info  . close';
    align-items: center;
    border-bottom-width: 1px;
    min-width: 270px;
    width: 100%;
    border-bottom: 2px solid;
    border-color: ${({ theme }) => theme.color.grey2};
`;

const Title = styled.p`
    grid-area: title;
    ${({ theme }) => theme.font.familySemiBold};
    font-size: ${({ theme }) => theme.size.textMedium};
    color: ${({ theme }) => theme.color.black};
`;

const ButtonContainer = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.color.black};
`;

const InfoContainer = styled(ButtonContainer)`
    grid-area: info;
`;

const CloseContainer = styled(ButtonContainer)`
    grid-area: close;
`;

const DrawerHeaderActionPlaceholder = styled.div`
    margin-left: 24px;
`;

type DrawerComponentLabel = {
    [key in DrawerComponentType]: string;
};

const mappedDrawerLabel: DrawerComponentLabel = {
    [DrawerComponentType.ProductionList]: 'À cuisiner',
    [DrawerComponentType.Settings]: 'Paramètres',
};

type displayInfoButton = {
    [key in DrawerComponentType]: boolean;
};

const mappedDisplayInfoButton: displayInfoButton = {
    [DrawerComponentType.ProductionList]: false,
    [DrawerComponentType.Settings]: true,
};

const DrawerHeader: React.FunctionComponent = () => {
    const componentType = useSelector(getDrawerComponentType);
    const label = mappedDrawerLabel[componentType];
    const isDisplayingIconInfo = mappedDisplayInfoButton[componentType];

    const dispatch = useDispatch();

    const close = () => {
        dispatch(udpateDrawerPosition(false));
    };

    const openInfo = () => {
        dispatch(updateInfoDisplay(true));
    };

    return (
        <Container>
            <Title>{label}</Title>
            <DrawerHeaderActionPlaceholder
                id={DRAWER_HEADER_ACTION_PORTAL_CONTAINER}
                data-testid={DRAWER_HEADER_ACTION_PORTAL_CONTAINER}
            />
            {isDisplayingIconInfo && (
                <InfoContainer onClick={openInfo} data-testid="info-btn">
                    <InfoIcon height={20} width={20} />
                </InfoContainer>
            )}
            <CloseContainer onClick={close} data-testid="close-btn">
                <CloseIcon height={20} width={20} />
            </CloseContainer>
        </Container>
    );
};

export default DrawerHeader;
