import React from 'react';
import styled from '@emotion/styled';
import TagsForm from './tags/TagsForm';
import SettingsActions from './SettingsActions';
import SettingsOptions from './SettingsOptions';
import CleanTickets from './CleanTickets';
import ChannelsForm from './ChannelsForm';
import ConsumptionModesForm from './ConsumptionModesForm';
import TableNameFilter from './tables/TableNameFilter/TableNameFilter';
import { DisplayPreferences } from './DisplayPreferences/DisplayPreferences';
import PrintersForm from './PrintersForm';
import PrintersReset from './PrintersReset';
import PrintersAudit from './PrintersAudit';

const Container = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    background-color: white;
    padding-top: 0;
`;
const ContentContainer = styled.div`
    display: flex;
    flex: 1;
    min-height: 0;
    flex-direction: column;
    justify-content: space-between;
`;

const Scroll = styled.div`
    flex-grow: 1;
    overflow: auto;
    /* for Firefox */
    min-height: 0;
    padding: 8px;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const Settings: React.FunctionComponent = () => {
    return (
        <Container>
            <ContentContainer>
                <Scroll>
                    <CleanTickets />
                    <SettingsOptions />
                    <TagsForm />
                    <TableNameFilter />
                    <ChannelsForm />
                    <ConsumptionModesForm />
                    <DisplayPreferences />
                    <PrintersForm />
                    <PrintersReset />
                    <PrintersAudit />
                </Scroll>
                <SettingsActions />
            </ContentContainer>
        </Container>
    );
};

export default Settings;
