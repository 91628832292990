import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TicketAutoSize } from '../TicketAutoSize/TicketAutoSize';

const TicketContainerDefault = css`
    width: 100%;
`;

const TicketContainerCompact = css`
    min-width: 0;
    width: min-content;
    min-width: 254px;
    display: flex;
    flex-direction: column;
`;

export const TicketContainer = styled(TicketAutoSize)<{ compactMode?: boolean }>`
    border-radius: 7px;
    background-color: ${({ theme }) => theme.color.white};
    position: relative;
    align-self: start;
    ${({ compactMode }) => (compactMode ? TicketContainerCompact : TicketContainerDefault)}
`;

const TicketBodyDefault = css`
    overflow: hidden;
`;

const TicketBodyCompact = css`
    flex-wrap: wrap;
    max-height: calc(90vh - 80px - 64px);
    min-width: 0;
`;

export const TicketBody = styled.div<{ compactMode?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
    ${({ compactMode }) => (compactMode ? TicketBodyCompact : TicketBodyDefault)}
`;
