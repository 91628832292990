import * as Sentry from '@sentry/react';
import { call, cancel, cancelled, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { Action } from 'redux';
import { Task } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { AuthResponse, login } from '../../api/user';
import {
    authFirestore,
    AuthFirestoreAction,
    loginFailed,
    loginRequest,
    LoginRequestAction,
    loginSuccess,
    logout,
} from './actions';
import { configurationGet, configurationSync } from '../configuration/actions';
import { userSet, userUnset } from '../user/actions';
import getResponseData from '../../api/getResponseData';
import type { User } from '../user';
import { Routes } from '../../routes.types';
import { synchroniseTickets } from '../tickets/firestore.actions';
import { authenticateToFirestore } from '../../services/firestore/authentification';
import { persistRehydrate, PersistRehydrateAction } from '../persist/actions';
import { getInfoFromUser } from '../user/getInfoFromUser';
import { getBeforeLoginRoute } from './selectors';

const setSentryContext = (user: User) => {
    const userInfo = getInfoFromUser(user);

    Sentry.configureScope((scope) => {
        scope.setTags({
            brand_name: userInfo.brandName,
            brand_id: userInfo.brandId.toString(),
            restaurant_name: userInfo.restaurantName,
            restaurant_id: userInfo.restaurantId.toString(),
        });

        scope.setUser({ email: user.email, userID: userInfo.userId });
    });
};

const kitchenDisplayRole = 'kitchenDisplay';

function* tryLogin({ payload }: { payload: { email: string; password: string } }) {
    try {
        const loginResponsePayload = (yield call(login, payload)) as AuthResponse;
        const responseData = getResponseData(loginResponsePayload);

        if (responseData.user.role !== kitchenDisplayRole) {
            throw Error("Votre compte ne permet pas d'accéder au KDS");
        }

        yield put(userSet(responseData.user, responseData.accessToken));
        setSentryContext(responseData.user);
        yield put(loginSuccess());
        yield put(configurationGet());
        yield put(authFirestore(responseData.accessToken, getInfoFromUser(responseData.user).restaurantId));
    } catch (error) {
        const message =
            error != null && typeof error === 'object' && 'message' in error && typeof error.message === 'string'
                ? error.message
                : 'Login failed';
        yield put(loginFailed(message));
    } finally {
        if ((yield cancelled()) as boolean) {
            yield put<Action>(push(Routes.Login));
        }
    }
}

const DELAY_BEFORE_RELOAD_IN_MS = 100;
function* authLogout() {
    yield put(userUnset());
    // The clean of the local storage takes some time
    setTimeout(() => window.location.reload(), DELAY_BEFORE_RELOAD_IN_MS);
}

export function* loginHandler({ payload }: LoginRequestAction): Generator {
    const task = (yield fork(tryLogin, {
        payload,
    })) as Task[];

    const action = (yield take([userUnset, loginFailed, logout])) as { type: string };

    if (action.type === getType(userUnset)) {
        yield cancel(task);
    }

    if (action.type !== getType(loginFailed)) {
        yield call(authLogout);
    }
}

function* verifyAuth({ payload }: PersistRehydrateAction) {
    if (
        payload === undefined ||
        payload.user === null ||
        payload.user.user === null ||
        payload.user.accessToken === null ||
        payload.auth === null ||
        !payload.auth.isAuthenticated
    ) {
        yield put<Action>(push(Routes.Login));

        return;
    }

    yield put(authFirestore(payload.user.accessToken, getInfoFromUser(payload.user.user).restaurantId));

    setSentryContext(payload.user.user);
    yield put(configurationGet());
}

function* navigateToAuthenticatedRoute() {
    const beforeLoginRoute = (yield select(getBeforeLoginRoute)) as ReturnType<typeof getBeforeLoginRoute>;
    yield put<Action>(push(beforeLoginRoute ?? Routes.Home));
}

function* authFirestoreHandler({ payload }: AuthFirestoreAction): Generator {
    yield call(authenticateToFirestore, payload.accessToken, payload.restaurantId);

    yield put(configurationSync(payload.restaurantId));
    yield put(synchroniseTickets(payload.restaurantId));
}

export function* watchAuth(): Generator {
    yield takeLatest(loginRequest, loginHandler);
    yield takeLatest(persistRehydrate, verifyAuth);
    yield takeLatest(authFirestore, authFirestoreHandler);
    yield takeLatest(logout, authLogout);
    yield takeLatest(loginSuccess, navigateToAuthenticatedRoute);
}
