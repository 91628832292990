import { isEmpty } from 'lodash';
import { activeStatus } from './filterActiveTicketByChildrenStatus';
import { KdsTicket, TicketStatus } from '../types/ticket';
import { KdsLinesMap } from '../types/kds-line';

const areAllChildrenUnactive = (linesMap: KdsLinesMap, isLocalAssemble: boolean): boolean =>
    Object.values(linesMap).reduce<boolean>((allChildrenAreUnactive, { status, kdsLines }) => {
        if (!allChildrenAreUnactive) {
            return false;
        }

        if (!isEmpty(kdsLines)) {
            return areAllChildrenUnactive(kdsLines, isLocalAssemble);
        }

        return !activeStatus(isLocalAssemble).includes(status) && allChildrenAreUnactive;
    }, true);

const filterWithDisplayerStatus = (status: TicketStatus, displayClientDisplay: boolean): boolean => {
    if (!displayClientDisplay) {
        return true;
    }

    return status !== TicketStatus.ToDisplay && status !== TicketStatus.Displaying;
};

const isPartOfDoneGroup = (
    groupingKey: string,
    tickets: KdsTicket[],
    isLocalAssemble: boolean,
    displayClientDisplay: boolean,
): boolean => {
    const groupedTickets = tickets.filter((ticket) => ticket.comment == null && ticket.groupingKey === groupingKey);

    return groupedTickets.every(
        ({ status, kdsLines }) =>
            isUnactiveTicket(status) || isTicketOnDisplayer(status, kdsLines, isLocalAssemble, displayClientDisplay),
    );
};

const isTicketOnDisplayer = (
    status: TicketStatus,
    kdsLines: KdsLinesMap,
    isLocalAssemble: boolean,
    displayClientDisplay: boolean,
): boolean => {
    return (
        status !== TicketStatus.Modified &&
        status !== TicketStatus.ToCancel &&
        filterWithDisplayerStatus(status, displayClientDisplay) &&
        areAllChildrenUnactive(kdsLines, isLocalAssemble)
    );
};

const isUnactiveTicket = (status: TicketStatus): boolean => {
    return status === TicketStatus.Done || status === TicketStatus.Cancelled;
};

export const filterUnactiveTicketByChildrenStatus = (
    tickets: KdsTicket[],
    isLocalAssemble: boolean,
    displayClientDisplay: boolean,
): KdsTicket[] => {
    return tickets.filter(({ kdsLines, status, groupingKey, comment }) =>
        groupingKey != null && comment == null
            ? status === TicketStatus.Cancelled ||
              isPartOfDoneGroup(groupingKey, tickets, isLocalAssemble, displayClientDisplay)
            : isUnactiveTicket(status) || isTicketOnDisplayer(status, kdsLines, isLocalAssemble, displayClientDisplay),
    );
};
