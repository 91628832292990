import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeTagFromWhiteList } from '../../../redux/localConfig/actions';
import Tag from './Tag';
import { getLocalConfigurationState } from '../../../redux/localConfig/selector';
import styled from '@emotion/styled';
import { ReactComponent as Icon } from '../close.svg';

const TagRemoveButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 18px;
    background-color: ${({ theme }) => theme.color.bloodyMary};
    color: ${({ theme }) => theme.color.grey3};
    margin: 2px;
`;

const LocalTags: React.FunctionComponent = () => {
    const localConfiguration = useSelector(getLocalConfigurationState);
    const dispatch = useDispatch();

    const removeTag = (index: number) => dispatch(removeTagFromWhiteList(index));

    return (
        <div>
            {localConfiguration.tagsWhiteList.map((tag, index) => (
                <Tag key={tag} text={tag}>
                    <TagRemoveButton data-testid="tag-remove" onClick={() => removeTag(index)}>
                        <Icon width={10} height={10} />
                    </TagRemoveButton>
                </Tag>
            ))}
        </div>
    );
};

export default LocalTags;
