import { boardStatus, displayerStatus } from '../status';
import { KdsTicket, TicketStatus } from '../types/ticket';

const shouldDisplayTicketInDisplayer = (status: TicketStatus, filterToDoTicketsInCustomerDisplay: boolean): boolean => {
    let filteredDisplayerStatus = [...displayerStatus];

    if (!filterToDoTicketsInCustomerDisplay) {
        filteredDisplayerStatus = [...boardStatus, ...filteredDisplayerStatus];
    }

    return filteredDisplayerStatus.includes(status);
};

export const filterActiveDisplayerTicketsByStatus = (
    tickets: KdsTicket[],
    filterToDoTicketsInCustomerDisplay: boolean,
): KdsTicket[] =>
    tickets.filter(({ status, groupingKey }) =>
        groupingKey != null
            ? tickets
                  .filter((ticket) => ticket.groupingKey === groupingKey)
                  .every((ticket) => shouldDisplayTicketInDisplayer(ticket.status, filterToDoTicketsInCustomerDisplay))
            : shouldDisplayTicketInDisplayer(status, filterToDoTicketsInCustomerDisplay),
    );
