import { Theme } from '@emotion/react';

export const theme: Theme = {
    color: {
        slate: '#3f4459',
        midnightBlue: '#243148',
        sweetBlue: '#53abe5',
        electricBlue: '#2881EB',
        mblue: '#2E4D7C',
        electricBlue2: '#3591FF',
        electricBlue3: '#86BDFF',
        slime: '#22D76E',
        emerald: '#3EC978',
        sweetMint: '#7EB4AF',
        goldenHour: '#EFBD95',
        peach: '#FFCFA9',
        bloodyMary: '#ff7777',
        black: '#333333',
        sweetBlack: '#262626',
        evilBlack: '#666666',
        blackGrey: '#999999',
        grey0: '#EDEDED',
        grey1: '#D4D4D4',
        grey2: '#D8D8D8',
        grey3: '#F8F8F8',
        white: '#fff',
        innovorder: '#F96D6D',
    },
    font: {
        familyLight: `
            font-family: 'Poppins-Regular', sans-serif;
            font-weight: 300;
        `,
        family: `
            font-family: 'Poppins-Regular', sans-serif;
            font-weight: 400;
        `,
        familySemiBold: `
            font-family: 'Poppins-Semi-bold', sans-serif;
            font-weight: 600;
        `,
        familyBold: `
            font-family: 'Poppins', sans-serif;
            font-weight: 700;
        `,
        avenirHeavy: `
         font-family: 'avenirHeavy', sans-serif;
         font-weight: 600;
        `,
    },
    size: {
        textVeryTiny: '12px',
        textTiny: '14px',
        textSmall: '16px',
        text: '20px',
        textMedium: '23px',
        textBig: '28px',
        textLarge: '32px',
    },
    layer: {
        page: '1000',
        component: '2000',
        footer: '3000',
        header: '4000',
        bar: '5000',
        drawer: '6000',
        modal: '7000',
        popup: '8000',
        overlay: '9000',
    },
};
