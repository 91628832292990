import { createReducer } from 'typesafe-actions';
import { UserActions, userSet, userUnset } from './actions';

type Brand = {
    brandId: number;
    name: string;
};

type Restaurants = {
    restaurantId: number;
    name: string;
    restaurant_user: {
        restaurantId: number;
        userId: number;
    };
    address: any;
};

export type User = {
    accessFlags: any;
    userId: number;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    password: string;
    passwordSalt: string;
    passwordService: null;
    role: string;
    forgottenPasswordToken: string | null;
    forgottenPasswordTokenDate: string | null;
    additionalAccessFlags: string | null;
    disallowedAccessFlags: string | null;
    lastConnection: string;
    brandId: number;
    created_at: string;
    updated_at: string;
    brands: Brand[];
    restaurants: Restaurants[];
};

export type UserState = {
    user: User | null;
    accessToken: string | null;
};

const defaultState: UserState = {
    user: null,
    accessToken: null,
};

export const userReducer = createReducer<UserState, UserActions>(defaultState)
    .handleAction(userSet, (state, { payload: { user, accessToken } }) => ({
        ...state,
        user,
        accessToken,
    }))
    .handleAction(userUnset, (state) => ({
        ...state,
        ...defaultState,
    }));
