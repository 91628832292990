import React from 'react';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@emotion/react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import persistedStore, { history } from './redux';
import Router from './Router';
import { theme } from './theme';
import { ENV } from './constants/env';
import { GlobalStyle } from './App.style';

Sentry.init({
    dsn: ENV.ENV_SENTRY_DSN,
    environment: ENV.APP_ENV,
});

const { store, persistor } = persistedStore;

const App: React.FunctionComponent = () => {
    return (
        <Provider store={store}>
            <GlobalStyle />
            <ThemeProvider theme={theme}>
                <PersistGate loading={null} persistor={persistor}>
                    <Router history={history} />
                </PersistGate>
            </ThemeProvider>
        </Provider>
    );
};

export default withLDProvider({
    clientSideID: ENV.LAUNCH_DARKLY_CLIENT_ID,
})(App);
