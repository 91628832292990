import * as React from 'react';
import styled from '@emotion/styled';
import { lineHasChild } from '../../../utils';
import Formula from '../../Formula';
import TicketItem from '../../TicketItem/TicketItem';
import { KdsLineItemBlock } from '../../../../../redux/tickets/types/kds-line-item';

export type Props = {
    block: KdsLineItemBlock;
    onGroupedLineClick: (clickedLinePath: string) => void;
    orderId: string;
    isCompactMode: boolean;
};

const Comment = styled.p`
    font-size: ${({ theme }) => theme.size.textSmall};
    font-family: ${({ theme }) => theme.font.familySemiBold};
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.bloodyMary};
    text-align: center;
    padding-top: 6px;
    padding-bottom: 4px;
`;

const Container = styled.div<{
    hasComment: boolean;
}>`
    background-color: ${({ hasComment, theme }) => (hasComment ? theme.color.bloodyMary : undefined)};
    border-radius: ${({ hasComment }) => (hasComment ? '8px' : '0px')};
    margin: ${({ hasComment }) => (hasComment ? '4px' : '0px')};
    padding: ${({ hasComment }) => (hasComment ? '4px' : '0px')};
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const GroupedTicketBlock: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    block,
    orderId,
    onGroupedLineClick,
    isCompactMode,
}) => {
    const hasComment = typeof block.blockKey === 'string' && block.blockKey.length > 0;

    return (
        <Container key={`${orderId}${block.blockKey ?? ''}`} hasComment={hasComment}>
            {hasComment ? <Comment data-testid="GroupedTicketBlock-Comment">{block.blockKey}</Comment> : null}
            {block.lines.map((line) =>
                lineHasChild(line) ? (
                    <Formula
                        {...line}
                        key={line.uuid}
                        orderId={orderId}
                        dispatchLineClicked={(_, linePath) => onGroupedLineClick(linePath)}
                        depth={0}
                        isCompactMode={isCompactMode}
                    />
                ) : (
                    <TicketItem
                        {...line}
                        key={line.uuid}
                        orderId={orderId}
                        dispatchLineClicked={(_, linePath) => onGroupedLineClick(linePath)}
                        depth={0}
                        isCompactMode={isCompactMode}
                    />
                ),
            )}
        </Container>
    );
};

export default GroupedTicketBlock;
