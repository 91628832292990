import { User } from '.';

export interface UserInfo {
    userId: number;
    brandId: number;
    brandName: string;
    restaurantId: number;
    restaurantName: string;
    email: string;
}

export const getInfoFromUser = (user: User): UserInfo => {
    return {
        userId: user.userId,
        brandId: user.brands[0].brandId,
        brandName: user.brands[0].name,
        restaurantId: user.restaurants[0].restaurantId,
        restaurantName: user.restaurants[0].name,
        email: user.email,
    };
};
