import type { LocalConfigurationState } from '.';
import type { RootState } from '..';
import { ChannelId, ShortConsumptionModeType } from '../tickets/types/ticket';

export const getLocalConfigurationState = (state: RootState): LocalConfigurationState => state.localConfiguration;

export const getIsDisplayModeActive = (state: RootState): boolean => getLocalConfigurationState(state).displayMode;

export const getColumnsNumberToDisplay = (state: RootState): number | null =>
    getLocalConfigurationState(state).columnsNumber;

export const getCompactModeState = (state: RootState): boolean => getLocalConfigurationState(state).compactMode;

export const getIsDisplayForthcoming = (state: RootState): boolean =>
    getLocalConfigurationState(state).displayForthcoming;

export const getIsDeviceAssembleKDS = (state: RootState): boolean =>
    getLocalConfigurationState(state).isDeviceAssembleKDS;

export const getFilterToDoTicketsInCustomerDisplay = (state: RootState): boolean =>
    getLocalConfigurationState(state).filterToDoTicketsInCustomerDisplay;

export const getOrdersInPreparationInClientDisplay = (state: RootState): boolean =>
    getLocalConfigurationState(state).ordersInPreparationInClientDisplay;

export const getChannelOptionFilters = (state: RootState): Record<ChannelId, boolean> =>
    getLocalConfigurationState(state).channelOptionFilters;

export const getSelectedPrinters = (state: RootState): Record<string, boolean> =>
    getLocalConfigurationState(state)?.selectedPrinters;

export const getConsumptionModeOptionFilters = (state: RootState): Record<ShortConsumptionModeType, boolean> =>
    getLocalConfigurationState(state).consumptionModeOptionFilters;
