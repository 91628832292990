import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import * as S from '../../../ui';

const OptionContainer = styled(S.Card)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 12px;
`;

const OptionTitle = styled.div`
    flex: 1;
`;

const OptionIcon = styled.div`
    margin-right: 16px;
`;

const DropdownSelect = styled(MuiSelect)`
    background-color: ${({ theme }) => theme.color.white};
    width: 64px;
    height: 40px;
    text-align: center;

    :before {
        border: none;
    }
`;
const DropdownSelectOption = styled(MenuItem)``;

type DropdownOption = {
    value: number;
    label: string;
};

type Props = {
    onChange: (value: number) => void;
    value: number;
    title: string;
    subtitle?: string;
    dataTestId?: string;
    icon?: ReactElement;
    options: DropdownOption[];
};

const OptionDropdown: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    onChange,
    icon,
    value,
    title,
    subtitle,
    options,
    dataTestId,
}) => {
    return (
        <OptionContainer data-testid={dataTestId}>
            {icon && <OptionIcon>{icon}</OptionIcon>}
            <OptionTitle>
                <S.SettingsLabel>{title}</S.SettingsLabel>
                {subtitle !== undefined && <S.SettingsSubtitle>{subtitle}</S.SettingsSubtitle>}
            </OptionTitle>
            <DropdownSelect labelId="dropdown-select" data-testid="dropdown-select" value={value}>
                {options.map(({ value, label }) => (
                    <DropdownSelectOption
                        key={`dropdown-select-option-${label}`}
                        data-testid={`dropdown-select-option-${label}`}
                        value={value}
                        onClick={() => onChange(value)}
                    >
                        {label}
                    </DropdownSelectOption>
                ))}
            </DropdownSelect>
        </OptionContainer>
    );
};

export default OptionDropdown;
