import { ENV } from '../constants/env';

type AuthResponse = {
    access_token: string;
    status: number;
    code: string;
    message: string;
    data: { accessToken: string };
    token_type: string;
};

export const getAuthFirestoreToken = async (apiAccessToken: string, restaurantId: number): Promise<string> => {
    const response = await fetch(`${ENV.API_URL}/restaurants/${restaurantId}/kitchen_display/authenticate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiAccessToken}`,
        },
    });
    const json = (await response.json()) as AuthResponse;

    return json.data.accessToken;
};
