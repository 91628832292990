import { KdsLine } from '../../redux/tickets/types/kds-line';

const KDS_LINES_ATTRIBUTE = 'kdsLines';
const MAP_PATH_SEPARATOR = '.';
export const getMapPath = ({
    kdsLineUuid,
    parentPath,
    attribute,
}: {
    kdsLineUuid: string;
    parentPath?: string;
    attribute?: string;
}): string =>
    [
        ...(parentPath !== undefined ? [parentPath] : []),
        KDS_LINES_ATTRIBUTE,
        kdsLineUuid,
        ...(attribute !== undefined ? [attribute] : []),
    ].join(MAP_PATH_SEPARATOR);

export const hasChildren = ({ kdsLines }: KdsLine): boolean => Object.keys(kdsLines ?? {}).length > 0;
