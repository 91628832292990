import React from 'react';

import styled from '@emotion/styled';

const Container = styled.button<{ color?: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 25px;
    padding-right: 25px;
    height: 60px;
    border-color: transparent;
    background-color: ${({ theme, color }) => color ?? theme.color.slate};
    border-radius: 3px;
    width: 100%;
`;

const ChildContainer = styled.div`
    margin-left: 10px;
`;

const Text = styled.p`
    color: ${({ theme }) => theme.color.white};
    ${({ theme }) => theme.font.familySemiBold};
    font-size: ${({ theme }) => theme.size.text};
`;

type Props = {
    text: string;
    onPress: () => void;
    color?: string;
};

const Button: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ onPress, children, text, color }) => {
    return (
        <Container data-testid="button" onClick={onPress} color={color}>
            <Text>{text}</Text>
            <ChildContainer>{children}</ChildContainer>
        </Container>
    );
};

export default Button;
