import React from 'react';
import { Redirect, Route, Switch } from 'react-router'; /* react-router v4/v5 */
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router/immutable';
import Login from './pages/Login';
import AuthGate from './components/AuthGate';
import Home from './pages/Home';
import CustomerDisplay from './pages/CustomerDisplay';
import { Routes } from './routes.types';

const HOME_ROUTE_PREFIX = '/home';
interface Props {
    history: History;
}

const Router: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ history }) => {
    return (
        <ConnectedRouter history={history}>
            <Switch>
                <AuthGate>
                    <Route path={HOME_ROUTE_PREFIX}>
                        <Home />
                    </Route>
                    <Route exact path={Routes.CustomerDisplay} component={CustomerDisplay} />
                    <Redirect push to={Routes.Home} />
                </AuthGate>
            </Switch>
            <Route exact path={Routes.Login} component={Login} />
        </ConnectedRouter>
    );
};

export default Router;
