import * as React from 'react';
import { theme as appTheme } from '../../../../theme';
import { assertUnreachable } from '../../../../utils/typescript';
import { ShortConsumptionModeType } from '../../../../redux/tickets/types/ticket';
import { ReactComponent as SITIN } from '../../../../icons/SITIN.svg';
import { ReactComponent as DRIVE } from '../../../../icons/DRIVE.svg';
import { ReactComponent as TAKEAWAY } from '../../../../icons/TAKEAWAY.svg';
import { ReactComponent as DELIVERY } from '../../../../icons/DELIVERY.svg';

const getIconFromConsumptionMode = (
    mode: ShortConsumptionModeType,
): React.FunctionComponent<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>> => {
    switch (mode) {
        case ShortConsumptionModeType.DELIVERY:
            return DELIVERY;
        case ShortConsumptionModeType.DRIVE:
            return DRIVE;
        case ShortConsumptionModeType.TAKE_AWAY:
            return TAKEAWAY;
        case ShortConsumptionModeType.SIT_IN:
            return SITIN;

        default:
            return assertUnreachable(mode);
    }
};

type ConsumptionModeIconProps = {
    mode: ShortConsumptionModeType;
    fill?: string;
    stroke?: string;
    size?: number;
};

const ConsumptionModeIcon: React.FunctionComponent<React.PropsWithChildren<ConsumptionModeIconProps>> = ({
    mode,
    fill = appTheme.color.white,
    stroke = appTheme.color.white,
    size = 35,
}) => {
    const Icon = getIconFromConsumptionMode(mode);

    return (
        <Icon data-testid={`consumption-mode-icon-${mode}`} width={size} height={size} stroke={stroke} fill={fill} />
    );
};

export default ConsumptionModeIcon;
