import { createSelector } from 'reselect';
import { getLocalConfigurationState } from '../localConfig/selector';
import type { RootState } from '..';
import type { ConfigurationData, ConfigurationState } from '.';
import { ChannelId, ShortConsumptionModeType } from '../tickets/types/ticket';

export const getConfigurationState = (state: RootState): ConfigurationState => state.configuration;

export const getConfigurationStateData = (state: RootState): ConfigurationData => getConfigurationState(state).data;

export const getRecallTimeBoundaries = (state: RootState): number[] => {
    const { minutesBeforeFirstRecall, minutesBeforeEmergency } = getConfigurationStateData(state);

    return [minutesBeforeFirstRecall, minutesBeforeEmergency];
};

export const getRecallColors = (state: RootState): string[] => {
    const { firstRecallColor, emergencyColor } = getConfigurationStateData(state);

    return [firstRecallColor, emergencyColor];
};

export const getLocalTableNamesWhiteList = (state: RootState): string[] =>
    getLocalConfigurationState(state).tableNamesWhiteList;

export const getLocalTagsWhiteList = (state: RootState): string[] => getLocalConfigurationState(state).tagsWhiteList;

export const getRemoteTagsWhiteList = (state: RootState): string[] => getConfigurationState(state).tagsWhiteList;

export const getActiveTags = createSelector(
    getLocalTagsWhiteList,
    getRemoteTagsWhiteList,
    (localTagWhiteList, remoteTagWhiteList): string[] => {
        if (localTagWhiteList.length > 0) {
            return localTagWhiteList;
        }

        if (remoteTagWhiteList.length > 0) {
            return remoteTagWhiteList;
        }

        return [];
    },
);

export const getIsDoubleValidationEnabled = (state: RootState): boolean =>
    getLocalConfigurationState(state).itemInProgressMode;

export const getIsAssembleEnabled = (state: RootState): boolean => getConfigurationStateData(state).isAssembleEnabled;

export const getCountdownValuesInMinutes = createSelector(getConfigurationStateData, (configuration) => {
    const { minutesBeforeDisplayDelivering, minutesBeforeDisplayEatIn, minutesBeforeDisplayTakeAway } = configuration;

    return {
        DELIVERY: minutesBeforeDisplayDelivering ?? 0,
        SIT_IN: minutesBeforeDisplayEatIn ?? 0,
        TAKE_AWAY: minutesBeforeDisplayTakeAway ?? 0,
        DRIVE: minutesBeforeDisplayTakeAway ?? 0, // DRIVE countdown doesn't exist in api conf so we use TAKE_AWAY instead (remove when drive conf exist)
    };
});

export const getIsAutoDisplayEnabled = (state: RootState): boolean =>
    getConfigurationStateData(state).isAutoDisplayEnabled;

export const getIsDisplayEnabled = (state: RootState): boolean => getConfigurationStateData(state).isDisplayEnabled;

export const getDisplayClientDisplay = (state: RootState): boolean =>
    getLocalConfigurationState(state).displayClientDisplay && getIsDisplayEnabled(state);

export const getChannelOptionFilters = createSelector(
    (state: RootState) => getLocalConfigurationState(state).channelOptionFilters,
    (channelOptionFilters: Record<ChannelId, boolean>): number[] => {
        return Object.keys(channelOptionFilters ?? {})
            .filter((channel) => channelOptionFilters[channel as unknown as ChannelId])
            .map((key) => Number.parseInt(key, 10));
    },
);

export const getConsumptionModeOptionFilters = createSelector(
    (state: RootState) => getLocalConfigurationState(state).consumptionModeOptionFilters,
    (consumptionModeOptionFilters: Record<ShortConsumptionModeType, boolean>): string[] => {
        return Object.keys(consumptionModeOptionFilters ?? {}).filter(
            (consumptionMode) => consumptionModeOptionFilters[consumptionMode as ShortConsumptionModeType],
        );
    },
);
