import { assertUnreachable } from '../utils/typescript';
import { AppEnv, ENV } from './env';

type FirebaseConfig = {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    name: string;
};

const firebaseEnv = {
    dev: {
        apiKey: 'AIzaSyBvhVPRVW4MAzA53llZDM53453tAtmS--Q',
        authDomain: 'innovorder-dev-kitchen.firebaseapp.com',
        projectId: 'innovorder-dev-kitchen',
        storageBucket: 'innovorder-dev-kitchen.appspot.com',
        messagingSenderId: '236095621184',
        appId: '1:236095621184:web:25ad69e40119709673b7a5',
        name: 'innovorder-dev-kitchen',
    },
    preprod: {
        apiKey: 'AIzaSyCHqGD-qzF90-CsT4vpMHz3avYe_LyoSSg',
        authDomain: 'innovorder-preprod-kitchen.firebaseapp.com',
        projectId: 'innovorder-preprod-kitchen',
        storageBucket: 'innovorder-preprod-kitchen.appspot.com',
        messagingSenderId: '410247117417',
        appId: '1:410247117417:web:bcb77cedce76417d1600f8',
        name: 'innovorder-preprod-kitchen',
    },
    prod: {
        apiKey: 'AIzaSyAsAjFW9F-60ic8s7EHL2dDkQS_Zx0N9Fc',
        authDomain: 'innovorder-prod-kitchen.firebaseapp.com',
        projectId: 'innovorder-prod-kitchen',
        storageBucket: 'innovorder-prod-kitchen.appspot.com',
        messagingSenderId: '6656385767',
        appId: '1:6656385767:web:0099eeb843c4e479f67842',
        name: 'innovorder-kitchen',
    },
};

const getFirebaseConfig = (env: AppEnv): FirebaseConfig => {
    switch (env) {
        case AppEnv.Dev:
        case AppEnv.Local:
            return firebaseEnv.dev;
        case AppEnv.Preprod:
            return firebaseEnv.preprod;
        case AppEnv.Production:
            return firebaseEnv.prod;
        default:
            return assertUnreachable(env);
    }
};

export const firebaseConfig = getFirebaseConfig(ENV.APP_ENV);
