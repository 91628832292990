import { ENV } from '../constants/env';
import type { User } from '../redux/user';

export type AuthResponseData = {
    user: User;
    tokenType: string;
    accessToken: string;
};

export type AuthResponse = {
    access_token: string;
    status: number;
    code: string;
    message: string;
    data: AuthResponseData;
    token_type: string;
};

export const login = ({ email, password }: { email: string; password: string }): Promise<AuthResponse> => {
    return fetch(`${ENV.API_URL}/oauth/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            grant_type: 'password',
            username: email,
            password,
            rememberMe: true,
            role: 'kitchenDisplay',
        }),
    })
        .then((response) => {
            return response.json();
        })
        .then((json: AuthResponse): AuthResponse => {
            return json;
        });
};
