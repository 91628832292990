import { delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import { WebBridgeServiceFactory } from '../../services/webBridge/index';
import { printTicketFailed, printTicketSucceeded, requestTicketPrint } from './print.actions';
import { getSelectedPrinters } from '../localConfig/selector';
import { getBoardTickets } from '../tickets/selector';
import { getPrinters } from '../hardware/hardware.selectors';
import { getPrintedTicketsMap } from './print.selectors';

function* requestTicketPrintHandler({
    payload: { ticket, targetPrinter, force },
}: ActionType<typeof requestTicketPrint>) {
    try {
        const printedTicketsMap = (yield select(getPrintedTicketsMap)) as ReturnType<typeof getPrintedTicketsMap>;
        if (force || !printedTicketsMap[ticket.orderId] || !printedTicketsMap[ticket.orderId][targetPrinter.target]) {
            const bridge = WebBridgeServiceFactory.getInstance();
            bridge.printTicket(ticket, targetPrinter);
            yield put(printTicketSucceeded(ticket, targetPrinter));
        }
    } catch (e) {
        window.alert(e);
        yield put(printTicketFailed(ticket, targetPrinter));
    }
}

function* handleUpdateStoredTickets(): Generator {
    const ticketMaps = {} as Record<string, Record<string, boolean>>;
    while (true) {
        try {
            const tickets = (yield select(getBoardTickets)) as ReturnType<typeof getBoardTickets>;
            const printers = (yield select(getPrinters)) as ReturnType<typeof getPrinters>;
            const selectedPrinters = (yield select(getSelectedPrinters)) as ReturnType<typeof getSelectedPrinters>;
            if (
                selectedPrinters &&
                Object.keys(selectedPrinters).length > 0 &&
                printers &&
                Object.keys(printers).length > 0
            ) {
                const printersMap = Object.keys(selectedPrinters).filter(
                    (printer) => selectedPrinters[printer] === true && printers[printer],
                );
                const bridge = WebBridgeServiceFactory.getInstance();
                for (let i = 0; i < tickets.length; i++) {
                    for (let j = 0; j < printersMap.length; j++) {
                        yield put(requestTicketPrint(tickets[i], printers[printersMap[j]], false));
                    }
                }
            }
        } catch (e) {
            window.alert(e);
            window.alert('error');
        }
        yield delay(1000 * 5);
    }
}

export function* watchPrintActions() {
    yield fork(handleUpdateStoredTickets);
    yield takeLatest(getType(requestTicketPrint), requestTicketPrintHandler);
}
