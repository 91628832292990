import { KdsLineItem, KdsLineItemBlock } from '../../redux/tickets/types/kds-line-item';

export const createTicketLineBlocks = (kdsLineItems: KdsLineItem[]): KdsLineItemBlock[] => {
    const linesMapByBlock: { [blockId: string]: KdsLineItemBlock } = {};

    kdsLineItems.forEach((line) => {
        const blockKey = line.blockKey ?? '';

        if (linesMapByBlock[blockKey] == null) {
            linesMapByBlock[blockKey] = { blockKey, lines: [] };
        }

        linesMapByBlock[blockKey].lines.push(line);
    });

    return Object.values(linesMapByBlock);
};
