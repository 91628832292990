import { sortBy } from 'lodash';
import { theme } from '../../../theme';
import { LineStatus } from '../../../redux/tickets/types/kds-line/status';
import { KdsLine } from '../../../redux/tickets/types/kds-line';
import { KdsTicket } from '../../../redux/tickets/types/ticket';

export const getFormulaHeaderFontColor = (status: LineStatus): string => {
    switch (status) {
        case LineStatus.Doing:
            return theme.color.white;
        case LineStatus.Done:
            return theme.color.blackGrey;

        default:
            return theme.color.black;
    }
};

export const lineHasChild = (kdsLine: KdsLine): boolean => Object.keys(kdsLine.kdsLines ?? {}).length > 0;

export const sortLinesByIndex = <T extends { index?: number }>(lines: T[]): T[] =>
    lines.length && lines[0].index !== undefined ? sortBy(lines, 'index') : sortBy(lines, 'uuid');

export const sortLinesByName = <T extends { name?: string }>(lines: T[]): T[] =>
    lines.length && lines[0].name !== undefined ? sortBy(lines, 'name') : sortBy(lines, 'uuid');

const startedState = [LineStatus.Doing, LineStatus.Done, LineStatus.ToAssemble];

const terminalState = [LineStatus.Cancelled, LineStatus.Done];

const getLinesStatus = (lines: KdsLine[]): LineStatus[] =>
    lines.map((line) => (lineHasChild(line) ? computeFormulaStatus(Object.values(line.kdsLines)) : line.status));

export const computeFormulaStatus = (lines: KdsLine[]): LineStatus => {
    switch (true) {
        case getLinesStatus(lines).every((status) => terminalState.includes(status)):
            return LineStatus.Done;

        case getLinesStatus(lines).some((status) => startedState.includes(status)):
            return LineStatus.Doing;

        default:
            return LineStatus.ToDo;
    }
};

export const isOrderPaid = (orderStatus: KdsTicket['orderStatus']) =>
    !orderStatus || !['ORDERED', 'VALIDATED'].includes(orderStatus);
