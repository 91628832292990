import { ENV } from '../constants/env';
import { ConfigurationData } from '../redux/configuration';
import type { Response } from './getResponseData';

export type ConfigurationResponse = Response<ConfigurationData>;

export const getConfiguration = async (accessToken: string, restaurantId: string): Promise<ConfigurationData> => {
    const response = await fetch(`${ENV.API_URL}/restaurants/${restaurantId}/kitchen_display`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
    });
    const json = (await response.json()) as ConfigurationData;

    return json;
};

export const updateKitchenDisplay = async (
    accessToken: string,
    restaurantId: string,
    kitchenDisplayConfiguration: ConfigurationData,
): Promise<void> => {
    const response = await fetch(`${ENV.API_URL}/restaurants/${restaurantId}/kitchen_display`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            kitchenDisplay: kitchenDisplayConfiguration,
        }),
    });

    if (response.status !== 200) {
        throw new Error('KDS CONFIGURATION UPDATE FAILED');
    }
};
