import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { theme as appTheme } from '../../../theme';
import styled from '@emotion/styled';

const MAccordion = withStyles({
    root: {
        border: `2px solid ${appTheme.color.grey1}`,
        width: '100%',
        borderRadius: '4px',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        borderBottom: `2px solid ${appTheme.color.grey1}`,
        marginBottom: -2,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        flexDirection: 'column',
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
    root: {
        padding: 0,
        display: 'block',
    },
}))(MuiAccordionDetails);

const Title = styled.div`
    ${({ theme }) => theme.font.familySemiBold};
    font-size: ${({ theme }) => theme.size.textSmall};
    color: ${({ theme }) => theme.color.evilBlack};
    flex: 1;
`;

const SubTitle = styled.div`
    ${({ theme }) => theme.font.familySemiBold};
    margin-top: 4px;
    font-size: ${({ theme }) => theme.size.textTiny};
    color: ${({ theme }) => theme.color.blackGrey};
    flex: 1;
`;

const ActionText = styled.div`
    ${({ theme }) => theme.font.family};
    font-size: ${({ theme }) => theme.size.textVeryTiny};
    color: ${({ theme }) => theme.color.bloodyMary};
    width: max-content;
    margin-top: 15px;
    margin-left: auto;
`;

type AccordionProps = {
    title: string;
    subtitle?: string;
    actionText?: string;
    onAtionClick?: () => void;
    dataTestId?: string;
};

export const Accordion: React.FunctionComponent<React.PropsWithChildren<AccordionProps>> = ({
    title,
    subtitle,
    actionText,
    onAtionClick,
    dataTestId,
    children,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const additionalProps = typeof dataTestId === 'string' ? { 'data-testid': dataTestId } : {};

    return (
        <div>
            <MAccordion {...additionalProps} square expanded={isOpen} onChange={() => setIsOpen(!isOpen)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Title>{title}</Title>
                    {typeof subtitle === 'string' && <SubTitle>{subtitle}</SubTitle>}
                    {typeof actionText === 'string' && onAtionClick && (
                        <ActionText
                            onClick={(e) => {
                                e.stopPropagation();
                                onAtionClick();
                            }}
                        >
                            {actionText}
                        </ActionText>
                    )}
                </AccordionSummary>
                <AccordionDetails>{children}</AccordionDetails>
            </MAccordion>
        </div>
    );
};
