import type { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import type { ActionType } from 'typesafe-actions';
import { isActionOf } from 'typesafe-actions';
import firebase from 'firebase';
import { QueryDocumentSnapshot, QuerySnapshot, WhereFilterOp } from '@firebase/firestore-types';
import type { AllActions, RootState } from '..';
import { synchroniseTickets, updateBatchTickets, updateTicket } from './firestore.actions';
import { updateDoneTickets, updateStoredTickets } from './stored.actions';
import 'firebase/firestore';
import { KdsTicket, TicketStatus } from './types/ticket';

type queryParams = [WhereFilterOp, string[]];

const { firestore } = firebase;

/**
 * Declaration of Firestore function
 */

export const watchFirestoreUpdate = (
    path: string,
    [operator, value]: queryParams,
    callback: (querySnapshot: QuerySnapshot) => void,
): void => {
    firestore()
        .collection(path)
        .where('status', operator, value)
        .onSnapshot(callback, (error) => {
            console.error('Error connecting to firebase', error);
            throw error;
        });
};

const mapTicketData = (document: QueryDocumentSnapshot<KdsTicket>) => {
    return document.data();
};

const connectToFirestoreStream = (
    storeApi: MiddlewareAPI<Dispatch, RootState>,
    action: ActionType<typeof synchroniseTickets>,
) => {
    const {
        payload: { restaurantId },
    } = action;

    const path = `restaurants/${restaurantId.toString()}/kds-tickets`;

    // fill board tickets
    watchFirestoreUpdate(path, ['not-in', [TicketStatus.Done, TicketStatus.Cancelled]], (querySnapshot) => {
        // querySnapshot is badly typed by firestore. So we casted it.
        const tickets = (querySnapshot as QuerySnapshot<KdsTicket>).docChanges().map((docChange) => ({
            ticket: docChange.doc.data(),
            type: docChange.type,
        }));

        storeApi.dispatch(updateStoredTickets(tickets));
    });

    // fill history tickets
    watchFirestoreUpdate(path, ['in', [TicketStatus.Done, TicketStatus.Cancelled]], (querySnapshot) => {
        // querySnapshot is badly typed by firestore. So we casted it.
        const tickets = (querySnapshot as QuerySnapshot<KdsTicket>).docs.map(mapTicketData);

        storeApi.dispatch(updateDoneTickets(tickets));
    });
};

const updateFirestore = async (action: ActionType<typeof updateTicket>): Promise<void> => {
    const {
        payload: { restaurantId, orderId, updates },
    } = action;

    await firestore()
        .collection(`restaurants/${restaurantId.toString()}/kds-tickets`)
        .doc(orderId)
        .update({ ...updates, updatedAt: new Date().toISOString() });
};

const batchUpdateFirestore = async (action: ActionType<typeof updateBatchTickets>): Promise<void> => {
    const {
        payload: { restaurantId, updatesMap },
    } = action;
    const batch = firestore().batch();
    const updates = Object.keys(updatesMap ?? {});

    updates.forEach((orderId) => {
        const document = firestore().collection(`restaurants/${restaurantId.toString()}/kds-tickets`).doc(orderId);
        batch.update(document, { ...updatesMap[orderId], updatedAt: new Date().toISOString() });
    });

    await batch.commit();
};

/**
 * Middleware triggering firestrore functions
 */
export const ticketsMiddleware: Middleware<
    unknown, // Most middleware do not modify the dispatch return value
    RootState
> = (storeApi) => (next) => (action: AllActions) => {
    const returnValue: AllActions = next(action);

    switch (true) {
        case isActionOf(synchroniseTickets, action):
            connectToFirestoreStream(storeApi, action as ActionType<typeof synchroniseTickets>);

            return returnValue;

        case isActionOf(updateTicket, action):
            // we want the middleware to be synchronous
            // eslint-disable-next-line no-void
            void updateFirestore(action as ActionType<typeof updateTicket>);

            return returnValue;

        case isActionOf(updateBatchTickets, action):
            // we want the middleware to be synchronous
            // eslint-disable-next-line no-void
            void batchUpdateFirestore(action as ActionType<typeof batchUpdateFirestore>);

            return returnValue;

        // ACTION

        default:
            return returnValue;
    }
};
