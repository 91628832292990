import * as React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as TODO } from './icons/TO_DO.svg';
import { ReactComponent as DOING } from './icons/DOING.svg';
import { ReactComponent as NEW } from './icons/NEW.svg';
import { ReactComponent as QUANTITY } from './icons/QUANTITY.svg';
import { ReactComponent as DONE } from './icons/DONE.svg';
import { ReactComponent as TO_ASSEMBLE } from './icons/TO_ASSEMBLE.svg';
import { assertUnreachable } from '../../../../utils/typescript';
import { LineStatus } from '../../../../redux/tickets/types/kds-line/status';

const size = 24;

const Container = styled.span`
    display: inline-block;
    width: ${size}px;
    height: ${size}px;
`;

type Props = {
    status: LineStatus;
};

type MappedIcons = {
    Icon: React.FunctionComponent<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>> | undefined;
    label: string;
};

const mapIconToStatus = (status: LineStatus): MappedIcons => {
    switch (status) {
        case LineStatus.New:
            return { Icon: NEW, label: LineStatus.New };
        case LineStatus.ToDo:
            return { Icon: TODO, label: LineStatus.ToDo };
        case LineStatus.Cancelled:
            return { Icon: undefined, label: LineStatus.Cancelled };
        case LineStatus.Doing:
            return { Icon: DOING, label: LineStatus.Doing };
        case LineStatus.Done:
            return { Icon: DONE, label: LineStatus.Done };
        case LineStatus.QuantityModified:
            return { Icon: QUANTITY, label: LineStatus.QuantityModified };
        case LineStatus.ToAssemble:
            return { Icon: TO_ASSEMBLE, label: LineStatus.ToAssemble };
        case LineStatus.Waiting:
            return { Icon: undefined, label: LineStatus.Waiting };
        default:
            return assertUnreachable(status);
    }
};

const ItemIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ status }) => {
    const { Icon, label } = mapIconToStatus(status);

    return <Container>{Icon && <Icon data-testid={label} width={size} height={size} />}</Container>;
};

export default ItemIcon;
