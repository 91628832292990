import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { theme as appTheme } from '../../theme';
import { getAuthMessage } from '../../redux/auth/selectors';
import { loginRequest } from '../../redux/auth/actions';
import * as S from '../../components/ui';
import PasswordInput from '../../components/PasswordInput/PasswordInput';
import styled from '@emotion/styled';

const Container = styled.div`
    background-color: ${({ theme }): string => theme.color.grey0};
    min-height: 100vh;
`;

const MarginTop = styled.div`
    margin-top: 40px;
`;

const Header = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;
`;
const Logo = styled.img`
    max-width: 190px;
    margin-bottom: 25px;
`;
const ProductTitle = styled.h2`
    font-family: ${({ theme }): string => theme.font.avenirHeavy};
    font-size: ${({ theme }): string => theme.size.textMedium};
    color: ${({ theme }): string => theme.color.evilBlack};
    letter-spacing: 5px;
    text-transform: uppercase;
`;
const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const FormContainer = styled.form`
    padding: 25px;
    /* box-shadow: 0px 0px 30px 10px rgba(0,0,0,0.15); */
    border-radius: 8px;
    background-color: ${({ theme }): string => theme.color.white};
    width: 500px;
    border: 1px solid ${({ theme }) => theme.color.grey1};
`;
const FormTitle = styled.div`
    padding-top: 10px;
    padding-bottom: 30px;
    text-align: center;
    font-size: ${({ theme }): string => theme.size.textMedium};
    font-family: ${({ theme }): string => theme.font.avenirHeavy};
`;
const Input = styled.input`
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }): string => theme.color.grey2};
    border-radius: 8px;
    width: 100%;
    height: 50px;
    margin-bottom: 30px;
    padding: 18px 15px;
    font-size: ${({ theme }) => theme.size.textSmall}; ;
`;

const ErrorMessage = styled.p`
    color: ${({ theme }): string => theme.color.bloodyMary};
    margin-top: 10px;
    text-align: center;
`;

const Login: React.FunctionComponent = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const errorMessage = useSelector(getAuthMessage, shallowEqual);
    const dispatch = useDispatch();

    const submit = () => {
        dispatch(loginRequest(email, password));
    };

    return (
        <Container>
            <Header>
                <Logo src="https://static-dev.innovorder.fr/io/images/logo-new.png" />
                <ProductTitle>Kitchen display</ProductTitle>
            </Header>
            <Center>
                <FormContainer onSubmit={(e) => e.preventDefault()}>
                    <FormTitle>Connexion</FormTitle>
                    <Input
                        type="text"
                        onChange={(event): unknown => setEmail(event.target.value)}
                        placeholder="Email"
                    />
                    <PasswordInput value={password} onChange={(value) => setPassword(value)} />
                    {errorMessage !== undefined && <ErrorMessage>{errorMessage}</ErrorMessage>}
                    <MarginTop>
                        <S.Button text="Se connecter" onPress={submit} color={appTheme.color.innovorder} />
                    </MarginTop>
                </FormContainer>
            </Center>
        </Container>
    );
};

export default Login;
