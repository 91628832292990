import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@emotion/react';
import { ReactComponent as WARNING } from './WARNING.svg';
import type { PopOverType } from '../../../redux/tickets/user.actions';
import { assertUnreachable } from '../../../utils/typescript';
import { Routes } from '../../../routes.types';
import { TicketStatus } from '../../../redux/tickets/types/ticket';

const getBackgroundColor = ({ theme, type }: { theme: Theme; type: PopOverType }): string => {
    switch (type) {
        case TicketStatus.ToCancel:
            return theme.color.bloodyMary;
        case TicketStatus.Modified:
            return theme.color.midnightBlue;
        default:
            return assertUnreachable(type);
    }
};

const Container = styled.div<{ type: PopOverType }>`
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 7px;

    &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: ${getBackgroundColor};
        opacity: 0.9;
        border-radius: 7px;
    }

    & > * {
        z-index: 1;
    }
`;

const Label = styled.p`
    ${({ theme }) => theme.font.familySemiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.color.white};
    text-decoration-style: solid;
    margin-top: 10px;
`;

const LabelBig = styled(Label)`
    ${({ theme }) => theme.font.familyBold};
    font-size: ${({ theme }) => theme.size.textLarge};
`;

type Props = {
    orderIds: string[];
    ticketNumbers: string[];
    type: PopOverType;
    route: Routes;
    onPopOverClick: (orderIds: string[], type: PopOverType, route: Routes) => void;
};

const getLabel = (type: PopOverType, isPlural: boolean): string => {
    switch (type) {
        case TicketStatus.ToCancel:
            return `Commande${isPlural ? 's' : ''} annulée${isPlural ? 's' : ''}`;
        case TicketStatus.Modified:
            return `Commande${isPlural ? 's' : ''} modifiée${isPlural ? 's' : ''}`;
        default:
            return assertUnreachable(type);
    }
};

const TicketPopOver: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    orderIds,
    ticketNumbers,
    type,
    route,
    onPopOverClick,
}) => {
    return (
        <Container data-testid="ticket-pop-over" onClick={() => onPopOverClick(orderIds, type, route)} type={type}>
            <WARNING width={45} />
            <LabelBig>{ticketNumbers.join(' - ')}</LabelBig>
            <Label>{getLabel(type, ticketNumbers.length > 1)}</Label>
        </Container>
    );
};

export default TicketPopOver;
