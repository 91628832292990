import type { ActionType } from 'typesafe-actions';
import { createAction } from 'typesafe-actions';

export type AppActions = ActionType<
    | typeof bumpHistoryTicketQuantity
    | typeof resetHistoryTicketQuantity
    | typeof udpateDrawerPosition
    | typeof openSettings
    | typeof openProductionList
    | typeof updateInfoDisplay
    | typeof setConnectionErrorCount
>;

export const bumpHistoryTicketQuantity = createAction('BUMP_HISTORY_TICKET_QUANTITY')();

export const resetHistoryTicketQuantity = createAction('RESET_HISTORY_TICKET_QUANTITY')();

export const udpateDrawerPosition = createAction('UPDATE_DRAWER_POSITION', (isDrawerOpen: boolean) => ({
    isDrawerOpen,
}))();

export const openSettings = createAction('OPEN_SETTINGS')();

export const openProductionList = createAction('OPEN_PRODUCTION_LIST')();

export const updateInfoDisplay = createAction('UPDATE_INFO_DISPLAY', (isInfoOpen: boolean) => ({
    isInfoOpen,
}))();

export const setConnectionErrorCount = createAction('SET_CONNECTION_ERROR_COUNT', (connectionErrorCount: number) => ({
    connectionErrorCount,
}))();
