/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { KdsTicket } from '../../redux/tickets/types/ticket';
import {
    SubscriberMap,
    BRIDGE_MESSAGE,
    WebBridgeMessage,
    EventDataToSend,
    MessageResponsePayload,
} from './webBridge.types';
import {
    computeRecursiveQuantityOnKdsLines,
    flattenKdsLines,
} from '../../redux/tickets/selectorLibs/getProducibleProducts';
import { CONSUMPTION_MODE_DISPLAY_NAME } from '../../components/settings/ConsumptionModesForm/ConsumptionModesForm';
import dayjs from 'dayjs';

declare global {
    const WebViewBridge: {
        send: (serializedMessage: string) => void;
        onMessage: (serializedMessage: string) => void;
    };
}

function isEventDataToSend(data: EventDataToSend | { messageId: string }): data is EventDataToSend {
    if ('name' in data) {
        return true;
    }
    return false;
}

function onKeyPress({ keyCode }: { keyCode: number }, externalArray: string[], callBack: (message: string) => void) {
    if (keyCode === 13) {
        callBack(externalArray.join(''));
        externalArray = [];
    } else {
        externalArray.push(String.fromCharCode(keyCode));
    }
}

export class WebBridge {
    private subscribers: SubscriberMap;

    public ready: boolean;
    public printers: any;

    constructor() {
        this.ready = false;
        this.printers = [];
        this.searchConnection();
        this.subscribers = this.initSubscribtion();
    }

    searchConnection = () => {
        if (typeof WebViewBridge !== 'undefined') {
            if (this.ready) {
                return this;
            }

            this.injectOnMessageMethod();

            this.ping();
            return this;
        }
        this.ready = false;
        return false;
    };

    updatePrinter = (printers: any) => {
        this.printers = printers;
    };
    searchPrinter = () => {
        if (typeof WebViewBridge !== 'undefined') {
            this.on(BRIDGE_MESSAGE.PRINTER_LIST_UPDATED, 'SEARCH_PRINTER_CALLBACK', (x) => {
                this.updatePrinter(x);
                this.off(BRIDGE_MESSAGE.PRINTER_LIST_UPDATED, 'SEARCH_PRINTER_CALLBACK');
            });
            this.emitMessage(BRIDGE_MESSAGE.PRINTER_START_DISCOVER, null, true);

            return this;
        }
        return false;
    };

    printTicket = async (currentTicket: KdsTicket, targetPrinter: object) => {
        if (typeof WebViewBridge !== 'undefined') {
            if (currentTicket && currentTicket.status == 'TO_DO') {
                const isTest = false;
                const orderTicketUuid = currentTicket.orderId + new Date().getTime();

                var eventData = {
                    serializedTicket: this.serializeTicket(currentTicket),
                    printerData: targetPrinter,
                    isTest: isTest,
                    orderId: currentTicket.orderId,
                    orderTicketUuid,
                };

                this.emitMessage(BRIDGE_MESSAGE.PRINT_KDS_TICKET, eventData, true);
            }
        }
    };

    flattenKdsTicketLines = (currentTicket: KdsTicket) => {
        const summarizedKdsLines = computeRecursiveQuantityOnKdsLines(currentTicket.kdsLines, 1);
        return flattenKdsLines(summarizedKdsLines);
    };

    serializeTicket = (currentTicket: KdsTicket) => {
        let serializedTicket = [
            {
                action: 'line',
                attributes: ['center', '1', '0', '2'],
                value: `Commande N°${currentTicket.ticketNumber}`,
            },
        ];
        serializedTicket.push({
            action: 'line',
            attributes: ['center', '1', '0', '2'],
            value: `${CONSUMPTION_MODE_DISPLAY_NAME[currentTicket.consumptionMode]}`,
        });
        serializedTicket.push({ action: 'feedline', attributes: ['1'], value: '' });
        serializedTicket.push({
            action: 'line',
            attributes: ['center', '1', '0', '1'],
            value: `Pour le ${dayjs(currentTicket.expectedAt).format('DD-MM-YYYY HH:mm')}`,
        });
        if (currentTicket.tableName) {
            serializedTicket.push({
                action: 'line',
                attributes: ['center', '1', '0', '1'],
                value: `Table ${currentTicket.tableName}`,
            });
        }
        if (currentTicket.customerFirstName) {
            serializedTicket.push({
                action: 'line',
                attributes: ['center', '1', '0', '1'],
                value: `Prénom ${currentTicket.customerFirstName}`,
            });
        }
        serializedTicket.push({ action: 'feedline', attributes: ['1'], value: '' });

        let flattenedTicket = this.flattenKdsTicketLines(currentTicket);
        for (let i = 0; i < flattenedTicket.length; i++) {
            const element = flattenedTicket[i];
            serializedTicket.push({
                action: 'line',
                attributes: ['left', '0', '0', '2'],
                value: `-----------------------`,
            });
            serializedTicket.push({
                action: 'line',
                attributes: ['left', '0', '0', '2'],
                value: `${element.quantity} ${element.name}`,
            });
            if (element.comment) {
                serializedTicket.push({
                    action: 'line',
                    attributes: ['left', '1', '0', '1'],
                    value: `     "${element.comment}"`,
                });
            }
            if (element.customizations) {
                for (let j = 0; j < element.customizations.length; j++) {
                    const customization = element.customizations[j];
                    serializedTicket.push({
                        action: 'line',
                        attributes: ['left', '0', '0', '1'],
                        value: `     ${customization.quantity} ${customization.value}`,
                    });
                }
            }
        }
        if (currentTicket.comment) {
            serializedTicket.push({
                action: 'line',
                attributes: ['left', '0', '0', '2'],
                value: `-----------------------`,
            });
            serializedTicket.push({
                action: 'line',
                attributes: ['center', '0', '0', '2'],
                value: `"${currentTicket.comment}"`,
            });
        }
        serializedTicket.push({ action: 'feedline', attributes: ['1'], value: '' });
        return serializedTicket;
    };

    injectOnMessageMethod = () => {
        WebViewBridge.onMessage = (serializedMessage: string) => {
            try {
                const messageObject = this.unserializeMessage(serializedMessage);
                this.dispatchEventToSubscribers(messageObject);

                if (messageObject.eventName === BRIDGE_MESSAGE.PONG) {
                    if (!this.ready) {
                        this.ready = true;
                        return true;
                    }
                    this.pong();
                }
            } catch (error) {
                this.emitMessage(BRIDGE_MESSAGE.ERROR_CATCH, null);
            }
            return this;
        };
    };

    private initSubscribtion = (): SubscriberMap => {
        const eventNames = Object.keys(BRIDGE_MESSAGE) as BRIDGE_MESSAGE[];
        const map: SubscriberMap = {};
        // eslint-disable-next-line array-callback-return
        eventNames.map((eventName) => {
            map[eventName] = {};
        });

        return map;
    };

    on = (eventName: BRIDGE_MESSAGE, key: string, callBack: (messageResponse: any) => void) => {
        this.subscribers[eventName]![key] = callBack;
    };

    off = (eventName: BRIDGE_MESSAGE, key: string) => {
        delete this.subscribers[eventName]![key];
    };

    dispatchEventToSubscribers = (messageObject: WebBridgeMessage) => {
        if (this.subscribers[messageObject.eventName]) {
            const keys = Object.keys(this.subscribers[messageObject.eventName]!);
            keys.forEach((key) => {
                this.subscribers[messageObject.eventName]![key](messageObject.eventData as MessageResponsePayload);
            });
        }
    };

    ping() {
        this.emitMessage(BRIDGE_MESSAGE.PING, null, true);
    }

    pong() {
        this.emitMessage(BRIDGE_MESSAGE.PONG, null, true);
    }

    emitMessage(eventName: BRIDGE_MESSAGE, eventData: any, blindCheck?: boolean) {
        this.emit(this.serializeMessage(BRIDGE_MESSAGE[eventName], eventData), blindCheck);
    }

    emit(serializedMessage: string, blindCheck?: boolean) {
        if (blindCheck || this.ready) {
            WebViewBridge.send(serializedMessage);
        }
    }

    serializeMessage(eventName: BRIDGE_MESSAGE, eventData: EventDataToSend | null | { messageId: string }): string {
        if (eventData && isEventDataToSend(eventData)) {
            eventData = {
                message: eventData.message,
                name: eventData.name,
                stack: eventData.stack,
            };
        }
        return JSON.stringify({
            eventName,
            eventData,
            eventTimestamp: new Date(),
        });
    }

    unserializeMessage(serializedMessage: string): WebBridgeMessage {
        // eslint-disable-next-line no-useless-catch
        try {
            const messageObject: WebBridgeMessage = JSON.parse(serializedMessage);
            if (messageObject.eventName && BRIDGE_MESSAGE[messageObject.eventName]) {
                if (messageObject.eventData !== undefined && messageObject.eventTimestamp) {
                    const a = {
                        eventName: messageObject.eventName,
                        eventData: messageObject.eventData,
                        eventTimestamp: messageObject.eventTimestamp,
                    };
                    return a;
                }
                throw new Error('bridge_message_incorrect_format');
            } else {
                throw new Error('bridge_message_not_found');
            }
        } catch (e) {
            throw e;
        }
    }
}

export const WebBridgeServiceFactory = (function () {
    let instance: WebBridge;
    return {
        getInstance() {
            if (instance == null) {
                instance = new WebBridge();
            }
            return instance;
        },
    };
})();
