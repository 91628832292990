import * as React from 'react';
import styled from '@emotion/styled';
import CustomizationItem from '../CustomizationItem';
import { colorMatrix } from '../../../../constants/colors';
import { LineCustomization } from '../../../../redux/tickets/types/kds-line/customization';
import { LineStatus } from '../../../../redux/tickets/types/kds-line/status';

const Container = styled.div<{ status: LineStatus; depth: number }>`
    border-style: solid;
    border-width: 2px;
    border-color: ${({ depth, status }) => colorMatrix[status].customisation.borderColor[depth]};
    background-color: ${({ depth, status }) => colorMatrix[status].customisation.backgroundColor[depth]};
    border-radius: 4px;
    padding: 0 4px;
    margin: 10px;
    width: 100%;
`;

type Props = { customizations: LineCustomization[]; status: LineStatus; depth: number };

const CustomizationContainer: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    customizations,
    status,
    depth,
}) => {
    if (!customizations?.length) {
        throw new Error("if customisations are empty don't call CustomizationContainer");
    }

    return (
        <Container data-testid="customisation-container" {...{ status, depth }}>
            {customizations.map((customisation) => (
                <CustomizationItem {...{ ...customisation, status, depth }} key={customisation.value} />
            ))}
        </Container>
    );
};

export default CustomizationContainer;
