import { LineStatus } from './types/kds-line/status';
import { TicketStatus } from './types/ticket';

// Tickets Status
export const boardStatus = [TicketStatus.ToDo, TicketStatus.Modified, TicketStatus.ToCancel];
export const boardStatusGrouped = [
    TicketStatus.ToDo,
    TicketStatus.Modified,
    TicketStatus.ToCancel,
    TicketStatus.Done,
    TicketStatus.ToDisplay,
];

export const displayerStatus = [TicketStatus.ToDisplay, TicketStatus.Displaying];

export const ticketStartedStatus = [TicketStatus.ToDo, TicketStatus.Modified, TicketStatus.ToDisplay];

// Line status
export const producibleStatus = [LineStatus.ToDo, LineStatus.Doing];

export const lineStartedStatus = [LineStatus.Doing, LineStatus.Done, LineStatus.ToAssemble];
