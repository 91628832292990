import { PosStatus } from '@innovorder/events-definitions/src/pos_order';
import { KdsLinesMap } from './kds-line';

export enum TicketStatus {
    Waiting = 'WAITING',
    Displaying = 'DISPLAYING',
    ToDisplay = 'TO_DISPLAY',
    ToDo = 'TO_DO',
    Modified = 'MODIFIED',
    ToCancel = 'TO_CANCEL',
    Cancelled = 'CANCELLED',
    Done = 'DONE',
}

export type TicketsState = {
    activeTickets: KdsTicket[];
    doneTickets: KdsTicket[];
};

export enum ChannelId {
    Kiosk = 1,
    Web = 2,
    Pos = 5,
    UberEats = 9,
    Deliveroo = 10,
    JustEat = 11,
}

export enum ShortConsumptionModeType {
    DELIVERY = 'DELIVERY',
    TAKE_AWAY = 'TAKE_AWAY',
    SIT_IN = 'SIT_IN',
    DRIVE = 'DRIVE',
}

export type KdsTicket = {
    orderId: string;
    brandId: number;
    restaurantId: number;
    channelId: ChannelId;
    ticketNumber: string;
    tableName: string | null;
    comment: string | null;
    consumptionMode: ShortConsumptionModeType;
    customerFirstName: string | null;
    customerLastName: string | null;
    expectedAt: string;
    status: TicketStatus;
    kdsLines: KdsLinesMap;
    tags: string[];
    expireAt: string;
    prepareAt: string;
    createdAt?: string; // added at the creation
    updatedAt?: string; // added at the update
    groupingKey?: string;
    orderStatus: PosStatus;
};

export type KdsTicketGroup = {
    groupingKey: string;
    tickets: KdsTicket[];
    updatedAt?: string; // added at the update
};

export type SimpleOrGroupKdsTicket = KdsTicket | KdsTicketGroup;
