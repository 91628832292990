import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEADER_BAR_HEIGHT } from '../header/HeaderBar/HeaderBar';

export const ContainerDefault = (columns: number | null) => css`
    display: grid;
    grid-template-columns: repeat(${columns ?? 6}, 1fr);

    @media screen and (max-width: 1530px) {
        grid-template-columns: repeat(${columns ?? 5}, 1fr);
    }

    @media screen and (max-width: 1500px) {
        grid-template-columns: repeat(${columns ?? 4}, 1fr);
    }

    @media screen and (max-width: 1000px) {
        grid-template-columns: repeat(${columns ?? 3}, 1fr);
    }
`;

export const ContainerCompact = css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
`;

export const Container = styled.div<{ compactMode: boolean; columns: number | null }>`
    width: 100%;
    padding: 8px;
    align-content: start;
    justify-items: center;
    gap: 8px;
    ${({ compactMode, columns }) => (compactMode ? ContainerCompact : ContainerDefault(columns))}
`;

export const LottieContainer = styled.div`
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
`;

export const ShowMoreContainer = styled.div`
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 50px;
`;

export const Scroll = styled.div`
    height: calc(100vh - ${HEADER_BAR_HEIGHT});
    overflow: scroll;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;
