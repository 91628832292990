import { createReducer } from 'typesafe-actions';
import { StoredTicketsActions, updateDoneTickets, updateStoredTickets } from './stored.actions';
import { KdsTicket, TicketsState } from './types/ticket';

const defaultState: TicketsState = {
    activeTickets: [],
    doneTickets: [],
};

export const ticketsReducer = createReducer<TicketsState, StoredTicketsActions>(defaultState)
    .handleAction(updateStoredTickets, (state, { payload: { tickets } }) => {
        const removedTicketsIds = tickets.filter(({ type }) => type === 'removed').map(({ ticket }) => ticket.orderId);
        const addedTickets = tickets.filter(({ type }) => type === 'added').map(({ ticket }) => ticket);
        const modifiedTickets = tickets.filter(({ type }) => type === 'modified').map(({ ticket }) => ticket);
        const modifiedTicketsIds = tickets
            .filter(({ type }) => type === 'modified')
            .map(({ ticket }) => ticket.orderId);

        return {
            ...state,
            activeTickets: [
                ...state.activeTickets
                    .map((ticket) => {
                        if (removedTicketsIds.includes(ticket.orderId)) {
                            return null;
                        }
                        if (modifiedTicketsIds.includes(ticket.orderId)) {
                            return modifiedTickets.find(({ orderId }) => orderId === ticket.orderId);
                        }

                        return ticket;
                    })
                    .filter((ticket): ticket is KdsTicket => ticket !== null && ticket !== undefined),
                ...addedTickets,
            ],
        };
    })
    .handleAction(updateDoneTickets, (state, { payload: { tickets } }) => ({
        ...state,
        doneTickets: tickets,
    }));
