export enum DrawerComponentType {
    Settings = 'SETTINGS',
    ProductionList = 'PRODUCTION_LIST',
}

export type AppState = {
    historyTicketQuantity: number;
    isDrawerOpen: boolean;
    drawerComponent: DrawerComponentType;
    isInfoOpen: boolean;
    connectionErrorCount: number;
};
