import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'lottie-react';
import { useCallback } from 'react';
import TicketCard from '../tickets/TicketCard';
import animationData from './empty.json';

import Button from '../ui/Button';
import { ReactComponent as ADD } from '../ui/Button/ADD.svg';
import { bumpHistoryTicketQuantity } from '../../redux/app/action';
import { getActualRoute } from '../../redux/router/selector';
import { Routes } from '../../routes.types';
import { getSelector } from '../../redux/tickets/selectorLibs/getSelectors';
import { getAllHistoryTicketLength } from '../../redux/tickets/selector';
import { isGroupedTicket } from '../../services/tickets/isGroupedTicket';
import { groupTicketsByKey } from '../../services/tickets/groupTicketsByKey';
import {
    getColumnsNumberToDisplay,
    getCompactModeState,
    getIsDisplayModeActive,
} from '../../redux/localConfig/selector';
import type { KdsTicket } from '../../redux/tickets/types/ticket';
import { Scroll, ShowMoreContainer, LottieContainer, Container } from './Board.style';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

function verifySameTickets(prevTickets: KdsTicket[], nextTickets: KdsTicket[]): boolean {
    return (
        prevTickets.length === nextTickets.length &&
        prevTickets.every((prevTicket) =>
            nextTickets.some(
                (nextTicket) =>
                    prevTicket.orderId === nextTicket.orderId &&
                    prevTicket.updatedAt === nextTicket.updatedAt &&
                    Object.keys(prevTicket.kdsLines ?? {}).length === Object.keys(nextTicket.kdsLines ?? {}).length,
            ),
        )
    );
}

const Board: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const actualRoute = useSelector(getActualRoute);
    const selector = getSelector(actualRoute);

    const tickets = useSelector(selector, verifySameTickets);
    const isDisplayMode = useSelector(getIsDisplayModeActive);
    const isCompactMode = useSelector(getCompactModeState);
    const columnsNumberToDisplay = useSelector(getColumnsNumberToDisplay);
    const allHistoryTicketsLength = useSelector(getAllHistoryTicketLength);

    const dispatchShowMore = useCallback(() => {
        dispatch(bumpHistoryTicketQuantity());
    }, [dispatch]);

    const isMoreTicketToDisplay = allHistoryTicketsLength !== tickets?.length;

    const shouldDisplayShowMore = actualRoute === Routes.History && isMoreTicketToDisplay;

    const ticketsWithGroups = groupTicketsByKey(tickets);

    return (
        <>
            {tickets?.length > 0 && (
                <Scroll>
                    <Container compactMode={isCompactMode} columns={columnsNumberToDisplay}>
                        {ticketsWithGroups.map((ticket, index) => (
                            <TicketCard
                                key={`${index}-${isGroupedTicket(ticket) ? ticket.groupingKey : ticket.orderId}`}
                                ticket={ticket}
                                route={actualRoute}
                                isDisplayMode={isDisplayMode}
                            />
                        ))}
                    </Container>
                    {shouldDisplayShowMore && (
                        <ShowMoreContainer>
                            <Button text="Charger d’autres commandes" onPress={dispatchShowMore}>
                                <ADD height={17} width={17} />
                            </Button>
                        </ShowMoreContainer>
                    )}
                </Scroll>
            )}
            {tickets?.length === 0 && (
                <LottieContainer>
                    <Lottie {...defaultOptions} height={300} width={300} />
                </LottieContainer>
            )}
        </>
    );
};

export default Board;
