import React from 'react';
import { useSelector } from 'react-redux';
import { getConfigurationState } from '../../../../redux/configuration/selectors';
import Tag from '../Tag';
import { getLocalConfigurationState } from '../../../../redux/localConfig/selector';

const RemoteTags: React.FunctionComponent = () => {
    const configuration = useSelector(getConfigurationState);
    const localConfiguration = useSelector(getLocalConfigurationState);

    return configuration.isTagsWhiteListEnabled && !localConfiguration.tagsWhiteList.length ? (
        <div>
            {configuration.tagsWhiteList.map((tag) => (
                <Tag key={tag} text={tag} />
            ))}
        </div>
    ) : null;
};

export default RemoteTags;
