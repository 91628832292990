import { useCallback, useEffect, useRef } from 'react';
import firebase from 'firebase';
import { useDispatch, useSelector } from 'react-redux';

import { LoggedOutBannerContainer } from './LoggedOutBanner.style';
import { getConnectionErrorCount } from '../../redux/app/selector';
import { setConnectionErrorCount } from '../../redux/app/action';

// Approximately ten seconds before displaying logged out banner
const MAX_CONNECTION_ERROR_BEFORE_BANNER = 5;

export function LoggedOutBanner() {
    const dispatch = useDispatch();
    const connectionErrorCount = useSelector(getConnectionErrorCount);
    let refreshTask = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

    const refreshConnectedStatus = useCallback(async () => {
        await firebase
            .firestore()
            .enableNetwork()
            .then(() => {
                dispatch(setConnectionErrorCount(0));
            })
            .catch(() => {
                dispatch(setConnectionErrorCount(connectionErrorCount + 1));
            });
    }, [connectionErrorCount, dispatch, setConnectionErrorCount]);

    const handleFirebaseLog = useCallback(
        ({ level, type, message }: { level: firebase.LogLevel; message: string; type: string }) => {
            const isErrorLog = ['warn', 'error'].includes(level);
            const isFirestoreLog = type.includes('firestore');
            const isMessageWithConnect = message.toLowerCase().includes('connect');
            const isConnectError = isErrorLog && isFirestoreLog && isMessageWithConnect;

            if (!isConnectError) {
                return;
            }
            // Ensure there is only one task at once
            clearTimeout(refreshTask.current);
            refreshTask.current = setTimeout(refreshConnectedStatus, 10 * 1_000);
            dispatch(setConnectionErrorCount(connectionErrorCount + 1));
        },
        [connectionErrorCount, dispatch, setConnectionErrorCount],
    );

    useEffect(() => {
        firebase.onLog(handleFirebaseLog);
    }, [handleFirebaseLog]);

    if (connectionErrorCount <= MAX_CONNECTION_ERROR_BEFORE_BANNER) {
        return null;
    }

    return (
        <LoggedOutBannerContainer data-testid="connection-status-banner" role="alert">
            La tablette est déconnectée et ne reçoit plus les nouvelles commandes. Merci de la reconnecter au réseau
            internet.
        </LoggedOutBannerContainer>
    );
}
