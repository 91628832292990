import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { ConfigurationResponse, getConfiguration, updateKitchenDisplay } from '../../api/configuration';
import { logout } from '../auth/actions';
import { configurationFail, configurationGet, configurationSet, reloadKDS, setAutoDisplay } from './actions';
import type { UserState } from '../user';
import { getUserState } from '../user/selectors';
import getResponseData from '../../api/getResponseData';
import { getInfoFromUser } from '../user/getInfoFromUser';
import { getConfigurationStateData } from './selectors';

function* fetchConfiguration() {
    try {
        const { accessToken, user } = (yield select(getUserState)) as UserState;

        if (user === null || accessToken == null) {
            yield put(logout());

            return;
        }

        const configuraionResponsePayload = (yield call(
            { context: null, fn: getConfiguration },
            accessToken,
            getInfoFromUser(user).restaurantId.toString(),
        )) as ConfigurationResponse;

        const responseData = getResponseData(configuraionResponsePayload);
        yield put(configurationSet(responseData));
    } catch (error) {
        if (error !== undefined) {
            yield put(logout());
        }
        yield put(configurationFail());
    }
}

function* updateAutoDisplay({ payload: { isAutoDisplayEnabled } }: ActionType<typeof setAutoDisplay>) {
    const { accessToken, user } = (yield select(getUserState)) as ReturnType<typeof getUserState>;

    if (user === null || accessToken == null) {
        yield put(logout());

        return;
    }

    const KDSconfiguration = (yield select(getConfigurationStateData)) as ReturnType<typeof getConfigurationStateData>;

    yield call(updateKitchenDisplay, accessToken, getInfoFromUser(user).restaurantId.toString(), {
        ...KDSconfiguration,
        isAutoDisplayEnabled,
    });

    yield put(reloadKDS());
}

export function* watchConfiguration(): Generator {
    yield takeLatest(configurationGet, fetchConfiguration);
    yield takeLatest(setAutoDisplay, updateAutoDisplay);
}
