import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import DisplayedOrdersList from '../../components/DisplayedOrdersList';
import { Routes } from '../../routes.types';
import styled from '@emotion/styled';

const Container = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${({ theme }) => theme.color.black};
    display: flex;
    flex-direction: column;
`;

const CustomerDisplay: React.FunctionComponent = () => {
    const dispatch = useDispatch();

    const returnToHome = () => {
        dispatch(push(Routes.Home));
    };

    return (
        <Container data-testid="ods-container" onClick={returnToHome}>
            <DisplayedOrdersList />
        </Container>
    );
};

export default CustomerDisplay;
