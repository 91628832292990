import { css, Global } from '@emotion/react';
import * as React from 'react';

const GlobalCss = css`
    body {
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
            'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overscroll-behavior-y: contain;
    }
    @import-normalize; /* bring in normalize.css styles */

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none;
    }

    @font-face {
        font-family: 'avenir';
        src: url('https://static-dev.innovorder.fr/frontkit/fonts/Avenir-Book.ttf');
    }

    @font-face {
        font-family: 'avenirHeavy';
        src: url('https://static-dev.innovorder.fr/frontkit/fonts/Avenir-Heavy.ttf');
    }

    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

    a,
    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    input,
    i,
    u {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        margin: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
    }

    body * {
        user-select: none;
    }
`;

export const GlobalStyle: React.FC = () => <Global styles={GlobalCss} />;
