import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { nextTick } from './filterForthcomingTickets';
import { KdsTicket, TicketStatus } from '../types/ticket';

dayjs.extend(isSameOrBefore);

export const filterPrepareAtIsInThePast = (tickets: KdsTicket[], now: string): KdsTicket[] =>
    tickets.filter(
        (ticket) => dayjs(ticket.prepareAt).isSameOrBefore(nextTick(now)) && ticket.status !== TicketStatus.Waiting,
    );
