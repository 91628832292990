import React from 'react';
import styled from '@emotion/styled';
import * as S from '../../ui';

type Props = {
    text: string;
};

const TagRemoveButton = styled.div`
    border-top: 1px solid ${({ theme }) => theme.color.grey1};
    background-color: ${({ theme }) => theme.color.grey3};
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Text = styled.p`
    ${({ theme }) => theme.font.family};
    font-size: ${({ theme }) => theme.size.text};
    color: ${({ theme }) => theme.color.blackGrey};
    margin-right: 10px;
`;

const Tags: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ children, text }) => {
    return (
        <TagRemoveButton data-testid="tag-container">
            <S.Padded style={{ paddingRight: 10 }}>
                <Flex>
                    <Text data-testid="tag-text"> {text.toLocaleLowerCase()}</Text>
                    {children}
                </Flex>
            </S.Padded>
        </TagRemoveButton>
    );
};

export default Tags;
