import { createReducer, ActionType } from 'typesafe-actions';
import * as printTicketsActions from './print.actions';
import { type PrintActions } from './print.actions';

const defaultState = { printedTicketsMap: {} };
export type PrintState = {
    printedTicketsMap: Record<string, Record<string, boolean>>;
};
export type Printer = {
    bd: string;
    deviceId: string;
    driverType: number;
    ip: string;
    mac: string;
    name: string;
    target: string;
    type: string;
};

const printReducer = createReducer<PrintState, PrintActions>(defaultState)
    .handleAction(printTicketsActions.requestTicketPrint, (state, { payload: { ticket, targetPrinter } }) => {
        const newState = state.printedTicketsMap;
        if (!newState[ticket.orderId]) {
            newState[ticket.orderId] = {};
        }
        return { printedTicketsMap: newState };
    })
    .handleAction(printTicketsActions.printTicketFailed, (state, { payload: { ticket, targetPrinter } }) => {
        const newState = state.printedTicketsMap;
        newState[ticket.orderId][targetPrinter.target] = false;
        return { printedTicketsMap: newState };
    })
    .handleAction(printTicketsActions.printTicketSucceeded, (state, { payload: { ticket, targetPrinter } }) => {
        const newState = state.printedTicketsMap;
        newState[ticket.orderId][targetPrinter.target] = true;
        return { printedTicketsMap: newState };
    })
    .handleAction(printTicketsActions.resetPrintedTickets, () => {
        return defaultState;
    });
export default printReducer;
