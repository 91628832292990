import React from 'react';

import styled from '@emotion/styled';

const Container = styled.span<{ color?: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2px 12px;
    background-color: ${({ theme, color }) => color ?? theme.color.innovorder};
    border-radius: 15px;
    height: 24px;
`;

const Text = styled.p`
    margin: 0;
    color: ${({ theme }) => theme.color.white};
    ${({ theme }) => theme.font.familySemiBold};
    font-size: 14px;
`;

type Props = {
    text: string | number;
    color?: string;
};

const Badge: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ text, color }) => {
    return (
        <Container data-testid="badge" color={color}>
            <Text>{text}</Text>
        </Container>
    );
};

export default Badge;
