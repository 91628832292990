import { KdsLineItem, KdsLineItemsMap } from '../../redux/tickets/types/kds-line-item';

export const groupTicketLines = (kdsLineItems: KdsLineItem[]): KdsLineItem[] => {
    const linesMapByGroupKey: KdsLineItemsMap = {};

    kdsLineItems.forEach((line) => {
        const groupingKey = line.groupingKey as string;

        if (linesMapByGroupKey[groupingKey] == null) {
            linesMapByGroupKey[groupingKey] = line;

            return;
        }
        linesMapByGroupKey[groupingKey] = {
            ...linesMapByGroupKey[groupingKey],
            quantity: linesMapByGroupKey[groupingKey].quantity + line.quantity,
            ticketNumbers: [...(linesMapByGroupKey[groupingKey].ticketNumbers ?? []), ...(line.ticketNumbers ?? [])],
        };
    });

    return Object.values(linesMapByGroupKey);
};
