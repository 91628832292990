import firebase from 'firebase';
import { createReducer } from 'typesafe-actions';
import type { ClockActions } from './action';
import { updateClock } from './action';
import type { ClockState } from './types';

const defaultState: ClockState = {
    date: firebase.firestore.Timestamp.now().toDate().toISOString(),
};

export const clockReducer = createReducer<ClockState, ClockActions>(defaultState).handleAction(
    updateClock,
    (state, { payload: { date } }) => {
        return { ...state, date };
    },
);
