import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Routes } from '../../routes.types';

interface Props {
    children: React.ReactNode;
    component?: React.ComponentType;
    isActive: boolean;
    redirect?: Routes;
}
const Gate: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ redirect, component, isActive, children }) => {
    if (isActive) {
        return (
            <Route>
                <Switch>{children}</Switch>
            </Route>
        );
    }
    if (component !== undefined) {
        const Component = component;

        return (
            <Route>
                <Switch>
                    <Component />
                </Switch>
            </Route>
        );
    }
    if (redirect !== undefined) {
        return (
            <Route>
                <Redirect to={redirect} />
            </Route>
        );
    }
    throw new Error('Gate requires either component or redirect props');
};
export default Gate;
