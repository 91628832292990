import { isEmpty } from 'lodash';
import { lineStartedStatus, ticketStartedStatus } from '../status';
import { KdsTicket, TicketStatus } from '../types/ticket';
import { KdsLinesMap } from '../types/kds-line';

const containsChildrenWithStartedStatus = (linesMap: KdsLinesMap): boolean =>
    Object.values(linesMap).reduce<boolean>((hasChildrenWithDoingStatus, { status, kdsLines }) => {
        if (hasChildrenWithDoingStatus) {
            return true;
        }
        if (!isEmpty(kdsLines)) {
            return containsChildrenWithStartedStatus(kdsLines);
        }

        return lineStartedStatus.includes(status);
    }, false);

const ticketAsStartedStatus = (status: TicketStatus): boolean => ticketStartedStatus.includes(status);

export const filterStartedOrders = (tickets: KdsTicket[]): KdsTicket[] =>
    tickets.filter(
        ({ kdsLines, status }) => ticketAsStartedStatus(status) && containsChildrenWithStartedStatus(kdsLines),
    );
