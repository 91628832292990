import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as S from '../../../ui';
import styled from '@emotion/styled';
import { addTableNameToWhiteList, setTableNameWhiteList } from '../../../../redux/localConfig/actions';
import LocalTables from '../LocalTables';

const Container = styled.div`
    margin-bottom: 8px;
`;

const FilterContent = styled.div`
    padding: 10px 20px 20px;
`;

const TableNameFilter: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const [filterInputText, setFilterInputText] = useState('');

    const addTableName = () => {
        if (filterInputText !== '') {
            dispatch(addTableNameToWhiteList(filterInputText));
            setFilterInputText('');
        }
    };

    const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement> | undefined) => {
        if (event && event.key === 'Enter') {
            event.preventDefault();
            addTableName();
        }
    };

    const handleFilterReset = () => {
        dispatch(setTableNameWhiteList([]));
    };

    return (
        <Container>
            <S.Accordion
                title="Filtrer les tickets par table"
                subtitle="Entrer les tables à filtrer"
                actionText="Effacer les filtres"
                onAtionClick={handleFilterReset}
            >
                <FilterContent>
                    <S.Input
                        onChange={(event) => setFilterInputText(event.target.value)}
                        value={filterInputText}
                        onBlur={addTableName}
                        style={{ marginTop: 14 }}
                        onKeyPress={onKeyPress}
                        data-testid="table-name-input"
                    />
                </FilterContent>
                <LocalTables />
            </S.Accordion>
        </Container>
    );
};

export default TableNameFilter;
