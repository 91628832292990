import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cleanOldTickets } from '../../../redux/tickets/user.actions';
import styled from '@emotion/styled';
import * as S from '../../ui';
import { resetPrintedTickets } from '../../../redux/print/print.actions';
import { getPrinters } from '../../../redux/hardware/hardware.selectors';

const Container = styled(S.Card)`
    margin-bottom: 8px;
`;

const PrintersReset: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const printers = useSelector(getPrinters);

    const dispachResetPrintedTickets = () => {
        dispatch(resetPrintedTickets());
    };

    if (printers && Object.keys(printers).length > 0) {
        return (
            <Container data-testid="setting-reset-printed-tickets" onClick={dispachResetPrintedTickets}>
                <S.SettingsLabel> Re-imprimer les tickets </S.SettingsLabel>
                <S.SettingsSubtitle>
                    {' '}
                    Re-initialise l'historique d'impression, les tickets déjà imprimés peuvent se re-imprimer à nouveau.{' '}
                </S.SettingsSubtitle>
            </Container>
        );
    }
    return <></>;
};

export default PrintersReset;
