import * as React from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { getClockTime } from '../../../redux/clock/selector';

const Container = styled.div`
    width: 130px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${({ theme }) => theme.color.slate};
`;

const Label = styled.p`
    color: ${({ theme }) => theme.color.white};
    ${({ theme }) => theme.font.familySemiBold};
    font-size: 32px;
    width: 100%;
    text-align: center;
`;

const HeaderClock: React.FunctionComponent = () => {
    const clockTime = useSelector(getClockTime);

    return (
        <Container data-testid="header-clock">
            <Label>{dayjs(clockTime).format('HH:mm')}</Label>
        </Container>
    );
};

export default HeaderClock;
