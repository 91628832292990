import { Routes } from '../../../routes.types';
import { getBoardTickets, getDisplayerTickets, getForthcomingTickets, getHistoryTickets } from '../selector';

export type TicketSelector =
    | typeof getHistoryTickets
    | typeof getBoardTickets
    | typeof getForthcomingTickets
    | typeof getDisplayerTickets;

export const getSelector = (route: Routes): TicketSelector => {
    switch (route) {
        case Routes.Home:
            return getBoardTickets;
        case Routes.History:
            return getHistoryTickets;
        case Routes.Forthcoming:
            return getForthcomingTickets;
        case Routes.Displayer:
            return getDisplayerTickets;

        default:
            return getBoardTickets;
    }
};
