import React, { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import HeaderBar from '../../components/header/HeaderBar';
import Drawer from '../../components/Drawer';
import Board from '../../components/Board';
import InfoModal from '../../components/InfoModal';
import { LoggedOutBanner } from '../../components/LoggedOutBanner/LoggedOutBanner';
import { getUserState } from '../../redux/user/selectors';
import { ENV } from '../../constants/env';
import { Container } from './Home.style';

const Home: React.FunctionComponent = () => {
    const ldClient = useLDClient();
    const { user } = useSelector(getUserState);

    useEffect(() => {
        if (!user || !ldClient || !ENV.LAUNCH_DARKLY_ACTIVE) {
            return;
        }

        const { firstName, lastName, email, userId, brandId, restaurants } = user;
        void ldClient.identify({
            key: userId.toString(),
            firstName,
            lastName,
            email,
            custom: {
                brandId,
                restaurantId: restaurants[0].restaurantId,
            },
        });
    }, [ldClient, user]);

    return (
        <Container>
            <HeaderBar />
            <LoggedOutBanner />
            <Board />
            <Drawer />
            <InfoModal />
        </Container>
    );
};

export default Home;
