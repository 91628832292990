import React from 'react';
import { ProducibleItem } from '../../../redux/tickets/types';
import styled from '@emotion/styled';
import ProductionItemCounter from './ProductionItemCounter';

const Container = styled.div`
    ${({ theme }) => theme.font.family};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 8px;
    gap: 16px;
`;

const Label = styled.div`
    color: ${({ theme }) => theme.color.evilBlack};
    font-size: ${({ theme }) => theme.size.text};
`;

const ProductionListItem: React.FunctionComponent<React.PropsWithChildren<ProducibleItem>> = ({
    name,
    toDoQuantity,
    doingQuantity,
}) => {
    return (
        <Container data-testid="production-list-item">
            <ProductionItemCounter {...{ toDoQuantity, doingQuantity }} />
            <Label data-testid="item-name">{name}</Label>
        </Container>
    );
};

export default ProductionListItem;
