import type { ActionType } from 'typesafe-actions';
import { createAction } from 'typesafe-actions';
import { Routes } from '../../routes.types';

export type AuthActions = ActionType<
    | typeof loginRequest
    | typeof loginSuccess
    | typeof loginFailed
    | typeof logout
    | typeof authFirestore
    | typeof setBeforeLoginRoute
>;

export type LoginRequestAction = ActionType<typeof loginRequest>;

export const loginRequest = createAction('LOGIN_REQUEST', (email: string, password: string) => ({
    email,
    password,
}))();

export const loginSuccess = createAction('LOGIN_SUCCESS')();

export const loginFailed = createAction('LOGIN_FAILED', (message: string | null) => ({
    message,
}))();

export const logout = createAction('LOGOUT')();

export type AuthFirestoreAction = ActionType<typeof authFirestore>;

export const authFirestore = createAction('AUTH_FIRESTORE', (accessToken: string, restaurantId: number) => ({
    accessToken,
    restaurantId,
}))();

export const setBeforeLoginRoute = createAction('SET_BEFORE_LOGIN_ROUTE', (route: Routes) => ({
    route,
}))();
