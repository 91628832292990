import { KdsLineItem } from '../../redux/tickets/types/kds-line-item';

export function findItemByLinePath(lineItem: KdsLineItem, linePath: string): KdsLineItem | null {
    if (lineItem.linePath === linePath) {
        return lineItem;
    }
    let foundItem = null;

    for (const line of Object.values(lineItem.kdsLines)) {
        foundItem = findItemByLinePath(line, linePath);

        if (foundItem) {
            break;
        }
    }

    return foundItem;
}
