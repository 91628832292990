import { KdsTicket, KdsTicketGroup, SimpleOrGroupKdsTicket } from '../../redux/tickets/types/ticket';

export const isGroupedTicket = (ticket: SimpleOrGroupKdsTicket): ticket is KdsTicketGroup => {
    return 'tickets' in ticket;
};

export const isSimpleTicketWithGroupKey = (
    ticket: SimpleOrGroupKdsTicket,
): ticket is KdsTicket & { groupingKey: string } => {
    return 'orderId' in ticket && 'groupingKey' in ticket && ticket.groupingKey != null;
};
