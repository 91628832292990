import { RouterState } from 'connected-react-router';
import { Routes } from '../../routes.types';
import { enumValues } from '../../utils/typescript';
import { RootState } from '..';

export const getRouterState = (state: RootState): RouterState => state.router;

export const getRouterPathName = (state: RootState): string => getRouterState(state).location.pathname;

export const getActualRoute = (state: RootState): Routes => {
    const route = getRouterPathName(state);

    return enumValues(Routes).includes(route) ? (route as Routes) : Routes.Home;
};
