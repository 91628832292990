import React from 'react';
import styled from '@emotion/styled';
import * as S from '../../ui';
import OptionDropdown from '../components/OptionDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { setColumnsNumber, setCompactMode } from '../../../redux/localConfig/actions';
import { getColumnsNumberToDisplay, getCompactModeState } from '../../../redux/localConfig/selector';
import OptionCheckBox from '../components/OptionCheckBox';

const Separator = styled.div`
    & > * {
        margin-bottom: 8px;
    }
`;

const OptionInAccordion = styled.div`
    & > div {
        border-radius: 0;
        border-left: none;
        border-right: none;
        border-bottom: none;

        &:first-of-type {
            border-top: none;
        }
    }
`;

const COLUMNS_NUMBER_OPTIONS = [
    {
        value: 0,
        label: 'Auto',
    },
    ...[1, 2, 3, 4, 5, 6].map((value) => ({
        value,
        label: String(value),
    })),
];

export const DisplayPreferences: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const columnsNumber = useSelector(getColumnsNumberToDisplay);
    const isCompactMode = useSelector(getCompactModeState);

    const handleColumnsNumberChange = (value: number) => {
        dispatch(setColumnsNumber(value === 0 ? null : value));
    };

    const handeCompactModeChange = (value: boolean) => {
        dispatch(setCompactMode(value));
    };

    return (
        <Separator>
            <S.Accordion dataTestId="setting-display-preference" title="Préférences d'affichage">
                <OptionInAccordion>
                    <OptionCheckBox
                        dataTestId="setting-display-preference-compact-mode"
                        onChange={handeCompactModeChange}
                        title={'Mode compact (bêta)'}
                        value={isCompactMode}
                        subtitle="Défilement horizontal et affichage plus compact"
                    />
                    {!isCompactMode && (
                        <OptionDropdown
                            dataTestId="setting-display-preference-columns-number"
                            onChange={handleColumnsNumberChange}
                            options={COLUMNS_NUMBER_OPTIONS}
                            title="Nombre de colonnes"
                            subtitle="Défini le nombre de colonne à afficher"
                            value={columnsNumber ?? 0}
                        />
                    )}
                </OptionInAccordion>
            </S.Accordion>
        </Separator>
    );
};
