import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { withStyles } from '@material-ui/core/styles';
import { getDrawerComponentType, getIsDrawerOpen } from '../../redux/app/selector';
import { udpateDrawerPosition } from '../../redux/app/action';
import Settings from '../settings/Settings';
import { DrawerComponentType } from '../../redux/app/types';
import DrawerHeader from './DrawerHeader';
import { theme } from '../../theme';
import Production from '../../pages/Production';

// MUI way to customize components style
const StyledSwipeableDrawer = withStyles({
    paper: {
        backgroundColor: theme.color.white,
        borderRadius: '6px 0 0 6px',
    },
})(SwipeableDrawer);

type DrawerComponentMap = {
    [key in DrawerComponentType]: React.FunctionComponent;
};

const mappedDrawerComponent: DrawerComponentMap = {
    [DrawerComponentType.ProductionList]: Production,
    [DrawerComponentType.Settings]: Settings,
};

const Drawer: React.FunctionComponent = () => {
    const isOpen = useSelector(getIsDrawerOpen);
    const componentType = useSelector(getDrawerComponentType);

    const dispatch = useDispatch();

    const setDrawerStatusOpen = () => {
        if (!isOpen) {
            dispatch(udpateDrawerPosition(true));
        }
    };

    const setDrawerStatusClose = () => {
        if (isOpen) {
            dispatch(udpateDrawerPosition(false));
        }
    };

    const Component = mappedDrawerComponent[componentType];

    return (
        <StyledSwipeableDrawer anchor="right" open={isOpen} onClose={setDrawerStatusClose} onOpen={setDrawerStatusOpen}>
            <DrawerHeader />
            <Component />
        </StyledSwipeableDrawer>
    );
};

export default Drawer;
