import { createReducer } from 'typesafe-actions';
import { Routes } from '../../routes.types';
import { AuthActions, loginFailed, loginRequest, loginSuccess, logout, setBeforeLoginRoute } from './actions';

export type AuthState = {
    isAuthenticated: boolean;
    isFetching: boolean;
    message: string | null;
    beforeLoginRoute: Routes | null;
};

const defaultState = {
    isAuthenticated: false,
    isFetching: false,
    message: null,
    beforeLoginRoute: null,
};

export const authReducer = createReducer<AuthState, AuthActions>(defaultState)
    .handleAction(loginRequest, (state) => ({
        ...state,
        isAuthenticated: false,
        isFetching: true,
        message: null,
    }))
    .handleAction(loginSuccess, (state) => ({
        ...state,
        isFetching: false,
        isAuthenticated: true,
        message: null,
    }))
    .handleAction(loginFailed, (state, { payload: { message } }) => ({
        ...state,
        isFetching: false,
        isAuthenticated: false,
        message,
    }))
    .handleAction(logout, (state) => ({
        ...state,
        ...defaultState,
    }))
    .handleAction(setBeforeLoginRoute, (state, { payload: { route } }) => ({
        ...state,
        beforeLoginRoute: route,
    }));
