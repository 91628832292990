import type { DocumentData, DocumentSnapshot } from '@firebase/firestore-types';
import firebase from 'firebase';
import type { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ActionType, isActionOf } from 'typesafe-actions';

import { inc } from 'semver';
import type { AllActions, RootState } from '..';
import { configurationSync, reloadKDS } from './actions';
import packageJson from '../../../package.json';
import { AppEnv, ENV } from '../../constants/env';
import { getRestaurantId } from '../user/selectors';

const { firestore } = firebase;

const restaurantsPath = 'restaurants/';

const updateVersion = async (version: string, restaurantId: string): Promise<void> =>
    firestore().collection(restaurantsPath).doc(restaurantId).set(
        {
            version,
        },
        { merge: true },
    );

export const checkVersion = async (
    documentSnapshot: DocumentSnapshot<DocumentData>,
    firstSnapshot: boolean,
): Promise<void> => {
    // not use this feature in local env (reloading issue)
    if (ENV.APP_ENV === AppEnv.Local) {
        return;
    }

    const restaurantId = documentSnapshot.id;

    if (!documentSnapshot.exists) {
        await firestore().collection(restaurantsPath).doc(restaurantId.toString()).set({
            restaurantId,
            version: packageJson.version,
        });

        return;
    }

    // document exists => documentSnapshot.data() !== undefined
    const { version } = documentSnapshot.data() as DocumentData;

    if (firstSnapshot || version === undefined) {
        await updateVersion(packageJson.version, restaurantId.toString());

        return;
    }

    if (!firstSnapshot && version !== packageJson.version) {
        window.location.reload();
    }
};

export const handleUpdate = (): ((document: DocumentSnapshot<DocumentData>) => void) => {
    let firstSnapshot = true;

    return (documentSnapshot: DocumentSnapshot<DocumentData>): void => {
        checkVersion(documentSnapshot, firstSnapshot).catch((error) => {
            console.error('Error while checking version update', error);
            throw error;
        });
        firstSnapshot = false;
    };
};

const syncConfigurationWithFirestore = (action: ActionType<typeof configurationSync>) => {
    const {
        payload: { restaurantId },
    } = action;

    // check restaurant version updates
    firestore()
        .collection(restaurantsPath)
        .doc(restaurantId.toString())
        .onSnapshot(handleUpdate(), (error) => {
            console.error('Error connecting to firebase', error);
            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw error;
        });
};

const incrementRestaurantVersion = async (storeApi: MiddlewareAPI<Dispatch<AnyAction>, RootState>): Promise<void> => {
    const restaurantId = getRestaurantId(storeApi.getState());
    const newVersion = inc(packageJson.version, 'patch');

    if (restaurantId === undefined || newVersion === null) {
        return;
    }

    await updateVersion(newVersion, restaurantId.toString());
};

/**
 * Middleware triggering firestrore functions
 */
export const configurationMiddleware: Middleware<
    unknown, // Most middleware do not modify the dispatch return value
    RootState
> =
    (storeApi) =>
    (next) =>
    (action: AllActions): AllActions => {
        const returnValue: AllActions = next(action);

        switch (true) {
            case isActionOf(configurationSync, action):
                syncConfigurationWithFirestore(action as ActionType<typeof configurationSync>);

                return returnValue;
            case isActionOf(reloadKDS, action):
                incrementRestaurantVersion(storeApi).catch((err) => {
                    throw err;
                });

                return returnValue;

            default:
                return returnValue;
        }
    };
