import { createReducer, ActionType } from 'typesafe-actions';
import * as hardwareActions from './hardware.actions';

export type HardwareActions = ActionType<typeof hardwareActions>;

const defaultState = null;
export type Printer = {
    bd: string;
    deviceId: string;
    driverType: number;
    ip: string;
    mac: string;
    name: string;
    target: string;
    type: string;
};

export type PrintersState = {
    [key: string]: Printer;
} | null;

export type HardwareState = {
    isWebBridgeConnected: boolean;
    printers: PrintersState;
} | null;

const hardwareReducer = createReducer<HardwareState, HardwareActions>(defaultState)
    .handleAction(hardwareActions.hardwareUpdate, (state, { payload: { isWebBridgeConnected, printers } }) => ({
        isWebBridgeConnected,
        printers,
    }))
    .handleAction(hardwareActions.hardwareUpdateFailed, () => null);

export default hardwareReducer;
