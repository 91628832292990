import * as React from 'react';
import styled from '@emotion/styled';
import { theme as appTheme } from '../../../../theme';
import ItemIcon from '../ItemIcon';
import CustomizationContainer from '../CustomizationContainer';
import { getMapPath } from '../../../../services/tickets/getMapPath';
import { colorMatrix } from '../../../../constants/colors';
import { LineStatus } from '../../../../redux/tickets/types/kds-line/status';
import { KdsLine } from '../../../../redux/tickets/types/kds-line';
import { getCommentFontColor } from '../utils';
import { css } from '@emotion/react';

const cancelledBackground = `
    repeating-linear-gradient(
    -55deg,
    ${appTheme.color.grey3},
    ${appTheme.color.grey3} 5px,
    ${appTheme.color.white} 5px,
    ${appTheme.color.white} 10px
    );
`;

const Container = styled.article<{
    status: LineStatus;
    isFormulaItem: boolean;
    isFirst: boolean;
    depth: number;
}>`
    border-style: solid;
    border-radius: ${({ isFormulaItem }) => (isFormulaItem ? '0' : '4px')};
    border-width: ${({ isFormulaItem }) => (isFormulaItem ? '2px 0 0 0' : '2px')};
    border-top-width: ${({ isFirst, isFormulaItem }) => (isFirst && isFormulaItem ? '0' : '')};
    border-color: ${({ depth, status }) => colorMatrix[status].item.borderColor[depth]};
    background-color: ${({ depth, status }) => colorMatrix[status].item.backgroundColor[depth]};
    background: ${({ status }) => (status === LineStatus.Cancelled ? cancelledBackground : '')};
`;

const ItemContainerDefault = ({ hasCustomization }: { hasCustomization: boolean }) => css`
    min-height: 70px;
    padding: 30px 10px;
    padding-top: ${hasCustomization ? '20px' : '30px'};
    padding-bottom: ${hasCustomization ? '10px' : '30px'};
`;

const ItemContainerCompact = ({ hasCustomization }: { hasCustomization: boolean }) => css`
    width: 240px;
    min-height: 52px;
    padding: 16px 8px;
    padding-bottom: ${hasCustomization ? '4px' : '16px'};
`;

const ItemContainer = styled.div<{ hasCustomization: boolean; isCompactMode: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${({ isCompactMode, hasCustomization }) =>
        isCompactMode ? ItemContainerCompact({ hasCustomization }) : ItemContainerDefault({ hasCustomization })};
`;

const ItemInnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Label = styled.p<{ status: LineStatus; isFormulaItem: boolean; depth: number }>`
    ${({ theme, isFormulaItem }) => (isFormulaItem ? theme.font.family : theme.font.familySemiBold)};
    font-size: ${({ theme }) => theme.size.text};
    color: ${({ depth, status }) => colorMatrix[status].item.fontColor[depth]};
    text-decoration-line: ${({ status }) => (status === LineStatus.Cancelled ? 'line-through' : '')};
    text-decoration-style: solid;
    max-width: 90%;
`;

const Comment = styled.p<{ status: LineStatus; depth: number }>`
    ${({ theme }) => theme.font.familySemiBold};
    font-size: ${({ theme }) => theme.size.text};
    font-style: italic;
    color: ${({ status }) => getCommentFontColor(status)};
    padding: 4px 8px 8px;
`;

type Props = KdsLine & {
    orderId: string;
    dispatchLineClicked: (orderId: string, linePath: string) => void;
    parentPath?: string;
    isFormulaItem?: boolean;
    isFirst?: boolean;
    depth: number;
    isCompactMode: boolean;
};

const TicketItem: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    name,
    status,
    quantity,
    customizations,
    isFormulaItem = false,
    isFirst = false,
    parentPath,
    uuid,
    orderId,
    comment,
    isCompactMode,
    dispatchLineClicked,
    depth,
}) => {
    const ticketPath = getMapPath({ kdsLineUuid: uuid, parentPath });
    const hasCustomization = customizations?.length > 0;

    return (
        <Container
            {...{ status, isFormulaItem, depth, isFirst }}
            data-testid={`${isFormulaItem ? 'formula-' : ''}ticket-item`}
            className="ticket-item"
            onClick={() => dispatchLineClicked(orderId, ticketPath)}
        >
            <ItemContainer
                hasCustomization={hasCustomization}
                isCompactMode={isCompactMode}
                className="ticket-item-content"
            >
                <ItemInnerContainer>
                    <Label {...{ status, isFormulaItem, depth }}>
                        {quantity}x {name}
                    </Label>
                    <ItemIcon status={status} />
                </ItemInnerContainer>

                {hasCustomization && <CustomizationContainer {...{ customizations, depth, status }} />}
            </ItemContainer>
            {comment !== null && <Comment {...{ status, depth }}>{comment}</Comment>}
        </Container>
    );
};

export default React.memo(TicketItem);
