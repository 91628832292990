import { PersistedState } from 'redux-persist';
import { RootState } from '../..';
import { enumToFilterObject } from '../../../utils/typescript';
import { RootState_V0, RootState_V00 } from './migration_V0';
import { RootState_V1 } from './migration_V1';
import { RootState_V2 } from './migration_V2';
import { RootState_V3 } from './migration_V3';
import { RootState_V4 } from './migration_V4';
import { RootState_V5 } from './migration_V5';
import { ChannelId, ShortConsumptionModeType } from '../../tickets/types/ticket';
import { RootState_V6 } from './migration_V6';
import { RootState_V7 } from './migration_V7';
import { RootState_V8 } from './migration_V8';

export const migrations = {
    0: (state: PersistedState): PersistedState => {
        const previousStateVersion = state as RootState_V00;

        const newState: PersistedState & RootState_V0 = {
            ...previousStateVersion,
            localConfiguration: {
                ...previousStateVersion.localConfiguration,
                displayForthcoming: false,
            },
            _persist: {
                version: 0,
                rehydrated: false,
            },
        };

        return { ...newState };
    },
    1: (state: PersistedState): PersistedState => {
        const previousStateVersion = state as RootState_V0;

        const newState: PersistedState & RootState_V1 = {
            ...previousStateVersion,
            localConfiguration: {
                ...previousStateVersion.localConfiguration,
                isDeviceAssembleKDS: false,
            },
            _persist: {
                version: 1,
                rehydrated: false,
            },
        };

        return { ...newState };
    },
    2: (state: PersistedState): PersistedState => {
        const previousStateVersion = state as RootState_V1;

        const newState: PersistedState & RootState_V2 = {
            ...previousStateVersion,
            localConfiguration: {
                ...previousStateVersion.localConfiguration,
                filterToDoTicketsInCustomerDisplay: false,
            },
            _persist: {
                version: 2,
                rehydrated: false,
            },
        };

        return { ...newState };
    },
    3: (state: PersistedState): PersistedState => {
        const previousStateVersion = state as RootState_V3;

        const newState: PersistedState & RootState_V4 = {
            ...previousStateVersion,
            localConfiguration: {
                ...previousStateVersion.localConfiguration,
                ordersInPreparationInClientDisplay: false,
            },
            _persist: {
                version: 3,
                rehydrated: false,
            },
        };

        return { ...newState };
    },
    // Empty migration to fix the version mismatch between local storage and redux
    4: (state: PersistedState): PersistedState => {
        const previousStateVersion = state as RootState_V4;

        const newState: PersistedState & RootState_V4 = {
            ...previousStateVersion,
            _persist: {
                version: 4,
                rehydrated: false,
            },
        };

        return { ...newState };
    },
    5: (state: PersistedState): PersistedState => {
        const previousStateVersion = state as RootState_V5;

        const newState: PersistedState & RootState_V5 = {
            ...previousStateVersion,
            localConfiguration: {
                ...previousStateVersion.localConfiguration,
                channelOptionFilters: enumToFilterObject(ChannelId, true),
                consumptionModeOptionFilters: enumToFilterObject(ShortConsumptionModeType, true),
            },
            _persist: {
                version: 4,
                rehydrated: false,
            },
        };

        return { ...newState };
    },
    6: (state: PersistedState): PersistedState => {
        const previousStateVersion = state as RootState_V6;

        const newState: PersistedState & RootState_V6 = {
            ...previousStateVersion,
            localConfiguration: {
                ...previousStateVersion.localConfiguration,
                tableNamesWhiteList: [],
            },
            _persist: {
                version: 4,
                rehydrated: false,
            },
        };

        return { ...newState };
    },
    7: (state: PersistedState): PersistedState => {
        const previousStateVersion = state as RootState_V7;

        const newState: PersistedState & RootState_V7 = {
            ...previousStateVersion,
            localConfiguration: {
                ...previousStateVersion.localConfiguration,
                columnsNumber: null,
                compactMode: false,
            },
            _persist: {
                // We jump to version 7 to re-synchronize store version and type version
                version: 7,
                rehydrated: false,
            },
        };

        return { ...newState };
    },
    8: (state: PersistedState): PersistedState => {
        const previousStateVersion = state as RootState_V8;

        const newState: PersistedState & RootState = {
            ...previousStateVersion,
            localConfiguration: {
                ...previousStateVersion.localConfiguration,
                selectedPrinters: {},
                columnsNumber: null,
                compactMode: false,
            },
            hardware: { isWebBridgeConnected: false, printers: {} },
            print: { printedTicketsMap: {} },
            _persist: {
                // We jump to version 7 to re-synchronize store version and type version
                version: 7,
                rehydrated: false,
            },
        };

        return { ...newState };
    },
};
