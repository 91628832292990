import { sortLinesByName } from '../../components/tickets/utils';
import { transformToBase64 } from '../../utils/typescript';
import { getMapPath } from './getMapPath';
import type { KdsLine, KdsLinesMap } from '../../redux/tickets/types/kds-line';
import type { LineCustomization } from '../../redux/tickets/types/kds-line/customization';
import type { KdsTicket } from '../../redux/tickets/types/ticket';
import type { KdsLineItem, KdsLineItemsMap } from '../../redux/tickets/types/kds-line-item';

function generateTicketKey(ticket: KdsTicket) {
    return `${ticket.groupingKey ?? ''}${ticket.comment ?? ''}`;
}

function generateLineKey(line: KdsLine, depth: number = 0) {
    return `${line.name}${line.status}${depth > 0 ? line.quantity : ''}`;
}

function generateCustomizationKey(customization: LineCustomization) {
    return `${customization.name}${customization.value}${customization.quantity}`;
}

function generateCustomizationsKey(customizations: LineCustomization[]) {
    return customizations.reduce(
        (prevKey, customization) => `${prevKey}${generateCustomizationKey(customization)}`,
        '',
    );
}

function generateHashKey(keys: string[]) {
    return transformToBase64(keys.reduce((prevKey, key) => `${prevKey}${key}`, ''));
}

function createParentGroupKey(lines: KdsLine[], customizations: LineCustomization[]): string {
    const reducedLinesKey = sortLinesByName(lines).reduce((prevKey, line) => {
        const subLines = Object.values(line.kdsLines);
        const subCustomizations = Object.values(line.customizations);
        const childrenGroupKey = createParentGroupKey(subLines, subCustomizations);
        const customizationsKey = generateCustomizationsKey(line.customizations);

        return `${prevKey}${generateLineKey(line)}${childrenGroupKey}${customizationsKey}`;
    }, '');
    const reducedCustomizationsKey = sortLinesByName(customizations).reduce((prevKey, customization) => {
        return `${prevKey}${generateCustomizationKey(customization)}`;
    }, '');

    return `${reducedLinesKey}${reducedCustomizationsKey}`;
}

function createLineItems(
    ticket: KdsTicket,
    kdsLines: KdsLinesMap,
    depth = 0,
    parentPath?: string,
    parentKey?: string,
): KdsLineItemsMap {
    const kdsLineItems: KdsLineItemsMap = {};
    const ticketKey = generateTicketKey(ticket);

    Object.entries(kdsLines).forEach(([uuid, line]) => {
        const subLines = Object.values(line.kdsLines);
        const subCustomizations = Object.values(line.customizations);
        const inheritKey = parentKey ?? `${ticketKey}${createParentGroupKey(subLines, subCustomizations)}`;
        const lineKey = generateLineKey(line, depth);
        const linePath = getMapPath({ kdsLineUuid: line.uuid, parentPath });
        const subLineItemsMap: KdsLineItemsMap = createLineItems(
            ticket,
            line.kdsLines,
            depth + 1,
            linePath,
            inheritKey,
        );
        const subLineItemsKeys: string[] = Object.values(subLineItemsMap)
            .map((subLine) => generateLineKey(subLine, depth + 1))
            .sort((a, b) => a.localeCompare(b));
        const groupingKeyArgs = [inheritKey, lineKey, ...subLineItemsKeys];
        const keepComments = ticket.comment != null && depth === 0;
        const blockKey: string = keepComments ? (ticket.comment as string) : '';

        if (blockKey) {
            groupingKeyArgs.push(blockKey);
        }

        kdsLineItems[uuid] = {
            ...line,
            blockKey,
            orderId: ticket.orderId,
            groupingKey: generateHashKey(groupingKeyArgs),
            linePath,
            kdsLines: subLineItemsMap,
            depth,
            ticketNumbers: [ticket.ticketNumber],
        };
    });

    return kdsLineItems;
}

export function transformTicketsToLineItems(tickets: KdsTicket[]): KdsLineItem[] {
    return Object.values(
        tickets.reverse().reduce((map, ticket) => ({ ...createLineItems(ticket, ticket.kdsLines), ...map }), {}),
    );
}
