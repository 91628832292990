import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cleanOldTickets } from '../../../redux/tickets/user.actions';
import styled from '@emotion/styled';
import * as S from '../../ui';
import { resetPrintedTickets } from '../../../redux/print/print.actions';
import { getPrintedTicketsMap } from '../../../redux/print/print.selectors';
import { getPrinters } from '../../../redux/hardware/hardware.selectors';

const Container = styled(S.Card)`
    margin-bottom: 8px;
`;

const PrintersReset: React.FunctionComponent = () => {
    const printers = useSelector(getPrinters);
    const printedTicketsMap = useSelector(getPrintedTicketsMap);

    const displayPrintLogs = () => {
        window.alert(JSON.stringify(printedTicketsMap));
    };

    if (printers && Object.keys(printers).length > 0) {
        return (
            <Container data-testid="setting-reset-printed-tickets" onClick={displayPrintLogs}>
                <S.SettingsLabel> Audit des tickets </S.SettingsLabel>
                <S.SettingsSubtitle> Informations techniques sur les impressions. </S.SettingsSubtitle>
            </Container>
        );
    }
    return <></>;
};

export default PrintersReset;
