import { createSelector } from 'reselect';
import {
    getActiveTags,
    getChannelOptionFilters,
    getConsumptionModeOptionFilters,
    getDisplayClientDisplay,
    getLocalTableNamesWhiteList,
} from '../configuration/selectors';
import type { RootState } from '..';
import { filterActiveAndWaitingBoardTicketsByStatus } from './selectorLibs/filterActiveAndWaitingBoardTicketsByStatus';
import { filterActiveBoardTicketsByStatus } from './selectorLibs/filterActiveBoardTicketsByStatus';
import { getTicketsFilteredByTag } from './selectorLibs/getTicketsFilteredByTag';
import { filterActiveTicketByChildrenStatus } from './selectorLibs/filterActiveTicketByChildrenStatus';
import { filterPrepareAtIsInThePast } from './selectorLibs/filterPrepareAtIsInThePast';
import { sortTicketByPrepareAt, sortTicketByPrepareAtDesc } from './selectorLibs/sortTicketByPrepareAt';
import { getClockTime } from '../clock/selector';
import { reduceTicketsToProducibleMap } from './selectorLibs/getProducibleProducts';
import { filterForthcomingTickets } from './selectorLibs/filterForthcomingTickets';
import { getFilterToDoTicketsInCustomerDisplay, getIsDeviceAssembleKDS } from '../localConfig/selector';
import { filterUnactiveTicketByChildrenStatus } from './selectorLibs/filterUnactiveTicketByChildrenStatus';
import { getHistoryTicketQuantity } from '../app/selector';
import { filterActiveDisplayerTicketsByStatus } from './selectorLibs/filterActiveDisplayerTicketsByStatus';
import { sortTicketByStatus } from './selectorLibs/sortTicketsByStatus';
import { filterDisplayingTicketByStatus } from './selectorLibs/filterDisplayingTicketByStatus';
import { filterStartedOrders } from './selectorLibs/filterStartedOrders';
import { filterByChannels } from './selectorLibs/filterByChannels';
import { filterByConsumptionModes } from './selectorLibs/filterByConsumptionModes';
import { KdsTicket } from './types/ticket';
import { getTicketsFilteredByTableName } from './selectorLibs/getTicketsFilteredByTableName';

export const getAllActiveTickets = (state: RootState): KdsTicket[] => state.tickets.activeTickets;

export const getAllDoneTickets = (state: RootState): KdsTicket[] => state.tickets.doneTickets;

export const getAllTickets = (state: RootState): KdsTicket[] => [
    ...getAllDoneTickets(state),
    ...getAllActiveTickets(state),
];

export const filterHistoryTicketsByQuantity = <T>(quantity: number, tickets: T[]): T[] =>
    tickets.filter((_, index) => index < quantity);

/**
 * Get ticket ready to display in board
 * ticket are filtered by :
 *  - tags
 *  - status
 *  - prepareAt
 *  - channel
 *  - consumption mode
 *
 * And are sorted by prepareAt
 */
export const getBoardTickets = createSelector(
    getAllActiveTickets,
    getActiveTags,
    getLocalTableNamesWhiteList,
    getConsumptionModeOptionFilters,
    getChannelOptionFilters,
    getClockTime,
    getIsDeviceAssembleKDS,
    getDisplayClientDisplay,
    (
        allTickets,
        activeTags,
        tableNames,
        consumptionModeFilters,
        channelFilters,
        time,
        isLocalAssemble,
        displayClientDisplay,
    ) =>
        sortTicketByStatus(
            sortTicketByPrepareAt(
                filterByConsumptionModes(
                    filterByChannels(
                        filterPrepareAtIsInThePast(
                            filterActiveTicketByChildrenStatus(
                                getTicketsFilteredByTableName(
                                    getTicketsFilteredByTag(filterActiveBoardTicketsByStatus(allTickets), activeTags),
                                    tableNames,
                                ),
                                isLocalAssemble,
                                displayClientDisplay,
                            ),
                            time,
                        ),
                        channelFilters,
                    ),
                    consumptionModeFilters,
                ),
            ),
        ),
);

/**
 * Tickets are sorted by status then by preparedAt
 */
export const getDisplayerTickets = createSelector(
    getAllActiveTickets,
    getActiveTags,
    getLocalTableNamesWhiteList,
    getConsumptionModeOptionFilters,
    getChannelOptionFilters,
    getClockTime,
    getIsDeviceAssembleKDS,
    getDisplayClientDisplay,
    getFilterToDoTicketsInCustomerDisplay,
    (
        allTickets,
        activeTags,
        tableNames,
        consumptionModeFilters,
        channelFilters,
        time,
        isLocalAssemble,
        displayClientDisplay,
        filterToDoTicketsInCustomerDisplay,
    ) =>
        sortTicketByStatus(
            sortTicketByPrepareAt(
                filterByConsumptionModes(
                    filterByChannels(
                        filterPrepareAtIsInThePast(
                            filterActiveTicketByChildrenStatus(
                                getTicketsFilteredByTableName(
                                    getTicketsFilteredByTag(
                                        filterActiveDisplayerTicketsByStatus(
                                            allTickets,
                                            filterToDoTicketsInCustomerDisplay,
                                        ),
                                        activeTags,
                                    ),
                                    tableNames,
                                ),
                                isLocalAssemble,
                                displayClientDisplay,
                            ),
                            time,
                        ),
                        channelFilters,
                    ),
                    consumptionModeFilters,
                ),
            ),
        ),
);

export const getInPreparationTickets = createSelector(
    getAllActiveTickets,
    getActiveTags,
    getLocalTableNamesWhiteList,
    getConsumptionModeOptionFilters,
    getChannelOptionFilters,
    getIsDeviceAssembleKDS,
    getDisplayClientDisplay,
    (
        allTickets,
        activeTags,
        tablesNames,
        consumptionModeFilters,
        channelFilters,
        isLocalAssemble,
        displayClientDisplay,
    ) =>
        sortTicketByPrepareAt(
            filterByConsumptionModes(
                filterByChannels(
                    filterStartedOrders(
                        filterActiveTicketByChildrenStatus(
                            getTicketsFilteredByTableName(getTicketsFilteredByTag(allTickets, activeTags), tablesNames),
                            isLocalAssemble,
                            displayClientDisplay,
                        ),
                    ),
                    channelFilters,
                ),
                consumptionModeFilters,
            ),
        ),
);

export const getDisplayingTickets = createSelector(
    getAllActiveTickets,
    getConsumptionModeOptionFilters,
    getChannelOptionFilters,
    (allTickets, consumptionModeFilters, channelFilters) =>
        sortTicketByPrepareAt(
            filterByConsumptionModes(
                filterByChannels(filterDisplayingTicketByStatus(allTickets), channelFilters),
                consumptionModeFilters,
            ),
        ),
);

export const getForthcomingTickets = createSelector(
    getAllActiveTickets,
    getActiveTags,
    getLocalTableNamesWhiteList,
    getConsumptionModeOptionFilters,
    getChannelOptionFilters,
    getClockTime,
    getIsDeviceAssembleKDS,
    getDisplayClientDisplay,
    (
        allTickets,
        activeTags,
        tableNames,
        consumptionModeFilters,
        channelFilters,
        time,
        isLocalAssemble,
        displayClientDisplay,
    ) =>
        sortTicketByPrepareAt(
            filterByConsumptionModes(
                filterByChannels(
                    filterForthcomingTickets(
                        filterActiveTicketByChildrenStatus(
                            getTicketsFilteredByTableName(
                                getTicketsFilteredByTag(
                                    filterActiveAndWaitingBoardTicketsByStatus(allTickets),
                                    activeTags,
                                ),
                                tableNames,
                            ),
                            isLocalAssemble,
                            displayClientDisplay,
                        ),
                        time,
                    ),
                    channelFilters,
                ),
                consumptionModeFilters,
            ),
        ),
);
export const getProducibleMap = createSelector(
    getAllActiveTickets,
    getActiveTags,
    getLocalTableNamesWhiteList,
    (allTickets, activeTags, tableNames) =>
        reduceTicketsToProducibleMap(
            getTicketsFilteredByTableName(
                getTicketsFilteredByTag(filterActiveBoardTicketsByStatus(allTickets), activeTags),
                tableNames,
            ),
        ),
);

export const getBoardTicketsLength = (state: RootState): number => getBoardTickets(state).length;
export const getForthcomingTicketsLength = (state: RootState): number => getForthcomingTickets(state).length;
export const getHistoryTicketsLength = (state: RootState): number => getHistoryTickets(state).length;

export const getAllHistoryTickets = createSelector(
    getAllTickets,
    getActiveTags,
    getLocalTableNamesWhiteList,
    getIsDeviceAssembleKDS,
    getDisplayClientDisplay,
    (tickets, activeTags, tableNames, isLocalAssemble, displayClientDisplay) =>
        filterUnactiveTicketByChildrenStatus(
            getTicketsFilteredByTableName(getTicketsFilteredByTag(tickets, activeTags), tableNames),
            isLocalAssemble,
            displayClientDisplay,
        ),
);

export const getAllHistoryTicketLength = (state: RootState): number => getAllHistoryTickets(state).length;

export const getHistoryTickets = createSelector(
    getAllHistoryTickets,
    getHistoryTicketQuantity,
    (tickets, historyTicketQuantity) =>
        filterHistoryTicketsByQuantity(historyTicketQuantity, sortTicketByPrepareAtDesc(tickets)),
);
