import flattenDeep from 'lodash/flattenDeep';
import keyBy from 'lodash/keyBy';
import { IS_PRODUCIBLE_TAG } from '../../../constants';
import { producibleStatus } from '../status';
import { ProducibleItem, ProducibleMap } from '../types';
import { LineStatus } from '../types/kds-line/status';
import { KdsTicket } from '../types/ticket';
import { KdsLine, KdsLinesMap } from '../types/kds-line';

export const reduceTicketsToProducibleMap = (tickets: KdsTicket[]): ProducibleMap => {
    return tickets.reduce(reduceProducibleProduct, {});
};

export const reduceProducibleProduct = (producibleMap: ProducibleMap, ticket: KdsTicket): ProducibleMap => {
    const producibleItems = computeTicketProducibleItems(ticket);

    return reduceProducibleItems(producibleMap, producibleItems);
};

export const reduceProducibleItems = (
    producibleMap: ProducibleMap,
    producibleItems: ProducibleItem[],
): ProducibleMap => {
    return producibleItems.reduce((map, item) => {
        const itemKey = item.productId && item.productId !== '0' ? item.productId : item.name;
        const oldItem = map[itemKey];

        return {
            ...map,
            [itemKey]: oldItem !== undefined ? mergeItems(oldItem, item) : item,
        };
    }, producibleMap);
};

export const mergeItems = (oldItem: ProducibleItem, item: ProducibleItem): ProducibleItem => {
    if (oldItem.productId !== item.productId) {
        throw new Error('cant merge two different products');
    }

    return {
        name: oldItem.name,
        toDoQuantity: oldItem.toDoQuantity + item.toDoQuantity,
        doingQuantity: oldItem.doingQuantity + item.doingQuantity,
        productId: oldItem.productId,
    };
};

export const mapLineToProducibleMap = ({ name, status, quantity, productId }: KdsLine): ProducibleItem => ({
    name,
    toDoQuantity: status === LineStatus.ToDo ? quantity : 0,
    doingQuantity: status === LineStatus.Doing ? quantity : 0,
    productId,
});

export const computeTicketProducibleItems = ({ kdsLines }: KdsTicket): ProducibleItem[] => {
    const summarizedKdsLines = computeRecursiveQuantityOnKdsLines(kdsLines, 1);
    const flattenedKdsLines = flattenKdsLines(summarizedKdsLines);
    const producibleKdsLines = filterKdsLinesByProducibleStatus(flattenedKdsLines);
    const producibleKdsLineMap = producibleKdsLines.map(mapLineToProducibleMap);

    return producibleKdsLineMap;
};

const isProducible = (line: KdsLine): boolean => line.tags.map((tag) => tag.toLowerCase()).includes(IS_PRODUCIBLE_TAG);

const haveProducibleStatus = ({ status }: KdsLine): boolean => producibleStatus.includes(status);

export const computeRecursiveQuantityOnKdsLines = (kdsLinesMap: KdsLinesMap, quantity: number): KdsLinesMap =>
    keyBy(
        Object.values(kdsLinesMap).map((line) => ({
            ...line,
            quantity: line.quantity * quantity,
            kdsLines: computeRecursiveQuantityOnKdsLines(line.kdsLines, line.quantity * quantity),
        })),
        'uuid',
    );

export const flattenKdsLines = (kdsLinesMap: KdsLinesMap): KdsLine[] =>
    flattenDeep(Object.values(kdsLinesMap).map((line) => [line, flattenKdsLines(line.kdsLines)]));

export const filterKdsLinesByProducibleStatus = (lines: KdsLine[]): KdsLine[] =>
    lines.filter(haveProducibleStatus).filter(isProducible);
