import { KdsLineItem } from '../../redux/tickets/types/kds-line-item';

export function findAllItemsByGroupKey(lineItem: KdsLineItem, groupingKey: string): KdsLineItem[] {
    const foundItem = [];

    if (lineItem.groupingKey === groupingKey) {
        foundItem.push(lineItem);
    }

    for (const line of Object.values(lineItem.kdsLines)) {
        const subItems = findAllItemsByGroupKey(line, groupingKey);
        if (subItems != null) {
            foundItem.push(...subItems);
        }
    }

    return foundItem;
}
