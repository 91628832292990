import React from 'react';
import { useDispatch } from 'react-redux';
import { cleanOldTickets } from '../../../redux/tickets/user.actions';
import styled from '@emotion/styled';
import * as S from '../../ui';

const Container = styled(S.Card)`
    margin-bottom: 8px;
`;

const CleanTickets: React.FunctionComponent = () => {
    const dispatch = useDispatch();

    const disptachClean = () => {
        dispatch(cleanOldTickets());
    };

    return (
        <Container data-testid="setting-clean" onClick={disptachClean}>
            <S.SettingsLabel> Clôturer les commandes en retard </S.SettingsLabel>
            <S.SettingsSubtitle> (Passer tous les tickets les plus vieux dans l’historique) </S.SettingsSubtitle>
        </Container>
    );
};

export default CleanTickets;
