import { isEmpty } from 'lodash';
import { enumValues } from '../../../utils/typescript';
import { LineStatus } from '../types/kds-line/status';
import { KdsTicket, TicketStatus } from '../types/ticket';
import { KdsLinesMap } from '../types/kds-line';

const terminalStatus = [LineStatus.Cancelled, LineStatus.Done];

// Active ticket :
// activeStatus = allStatus - terminalStatus -

export const activeStatus = (isDeviceAssemble: boolean): LineStatus[] =>
    enumValues<LineStatus>(LineStatus).filter((status) =>
        isDeviceAssemble
            ? !terminalStatus.includes(status)
            : ![...terminalStatus, LineStatus.ToAssemble].includes(status),
    );

const containsChildrenWithActiveStatus = (linesMap: KdsLinesMap, isAssembleModeEnabled: boolean): boolean =>
    Object.values(linesMap).reduce<boolean>((hasChildrenWithActiveStatus, { status, kdsLines }) => {
        if (hasChildrenWithActiveStatus) {
            return true;
        }
        if (!isEmpty(kdsLines)) {
            return containsChildrenWithActiveStatus(kdsLines, isAssembleModeEnabled);
        }

        return activeStatus(isAssembleModeEnabled).includes(status);
    }, false);

const filterWithDisplayerStatus = (status: TicketStatus, isClientDisplayEnabled: boolean): boolean =>
    isClientDisplayEnabled && [TicketStatus.ToDo, TicketStatus.ToDisplay, TicketStatus.Displaying].includes(status);

const isPartOfPendingGroup = (
    groupingKey: string,
    tickets: KdsTicket[],
    isAssembleModeEnabled: boolean,
    isClientDisplayEnabled: boolean,
): boolean =>
    tickets.some(
        (ticket) =>
            groupingKey === ticket.groupingKey &&
            ([TicketStatus.ToCancel, TicketStatus.Modified].includes(ticket.status) ||
                filterWithDisplayerStatus(ticket.status, isClientDisplayEnabled) ||
                containsChildrenWithActiveStatus(ticket.kdsLines, isAssembleModeEnabled)),
    );

export const filterActiveTicketByChildrenStatus = (
    tickets: KdsTicket[],
    isAssembleModeEnabled: boolean,
    isClientDisplayEnabled: boolean,
): KdsTicket[] =>
    tickets.filter(
        ({ kdsLines, status, groupingKey }) =>
            [TicketStatus.Modified, TicketStatus.ToCancel].includes(status) ||
            filterWithDisplayerStatus(status, isClientDisplayEnabled) ||
            containsChildrenWithActiveStatus(kdsLines, isAssembleModeEnabled) ||
            (groupingKey != null &&
                isPartOfPendingGroup(groupingKey, tickets, isAssembleModeEnabled, isClientDisplayEnabled)),
    );
