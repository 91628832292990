import firebase from 'firebase';
import type { ActionType } from 'typesafe-actions';
import { createAction } from 'typesafe-actions';

export type ClockActions = ActionType<typeof updateClock>;

export const updateClock = createAction('UPDATE_CLOCK', () => {
    /* We use firebase to synchronize clocks among each kds */
    const firebaseTimestamp = firebase.firestore.Timestamp.now();
    const firebaseDate = firebaseTimestamp.toDate();
    const firebaseISODate = firebaseDate.toISOString();

    return {
        date: firebaseISODate,
    };
})();
