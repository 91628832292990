import styled from '@emotion/styled';
import { lighten } from '@material-ui/core';
import React from 'react';
import { theme } from '../../theme';
import opacity from '../../utils/polyfills/opacity.polyfill';

const STRIPE_WIDTH = 10;

const OverlayElement = styled.div<{ isVisible?: boolean; color: string }>`
    display: ${({ isVisible }) => (isVisible === true ? 'block' : 'none')};
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    ${opacity(20)}
    background: repeating-linear-gradient(
        45deg,
        ${({ color }) => color},
        ${({ color }) => color} ${STRIPE_WIDTH}px,
        ${({ color }) => lighten(color, 0.3)} ${STRIPE_WIDTH}px,
        ${({ color }) => lighten(color, 0.3)} ${STRIPE_WIDTH * 2}px
    );
`;

type Props = {
    isVisible: boolean;
    color?: string;
};

const Overlay = ({ isVisible, color = theme.color.blackGrey }: Props) => {
    return <OverlayElement isVisible={isVisible} color={color} />;
};

export default React.memo(Overlay);
