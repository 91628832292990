import styled from '@emotion/styled';

export const Input = styled.input`
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }): string => theme.color.grey2};
    color: ${({ theme }): string => theme.color.evilBlack};
    ${({ theme }) => theme.font.family};
    border-radius: 4px;
    width: 100%;
    height: 30px;
    padding: 18px 15px;
    font-size: 14px;

    &:focus {
        outline: none;
        border-color: ${({ theme }): string => theme.color.blackGrey};
    }
`;
