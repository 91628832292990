import styled from '@emotion/styled';

export const LoggedOutBannerContainer = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: ${({ theme }) => theme.color.bloodyMary};
    color: ${({ theme }) => theme.color.white};
    font-weight: ${({ theme }) => theme.font.familyBold};
    padding: 15px 10%;
    box-shadow: 0 0 2px ${({ theme }) => theme.color.bloodyMary}, -2px 0 8px ${({ theme }) => theme.color.bloodyMary},
        2px 0 8px ${({ theme }) => theme.color.bloodyMary};
`;
