import { theme } from '../theme';
import { LineStatus } from '../redux/tickets/types/kds-line/status';

type StatusStyle = {
    [key in LineStatus]: DepthStyle;
};

type DepthStyle = {
    item: DynamicStyleAttribute;
    formula: DynamicStyleAttribute;
    formulaHeader: DynamicStyleAttribute;
    customisation: DynamicStyleAttribute;
};

export type DynamicStyleAttribute = {
    fontColor: string[];
    backgroundColor: string[];
    borderColor: string[];
};

export const colorMatrix: StatusStyle = {
    [LineStatus.Waiting]: {
        item: {
            fontColor: [theme.color.blackGrey, theme.color.blackGrey, theme.color.blackGrey, theme.color.blackGrey],
            backgroundColor: [theme.color.grey3, theme.color.grey3, theme.color.grey3, theme.color.grey3],
            borderColor: [theme.color.grey2, theme.color.grey2, theme.color.grey2, theme.color.grey2],
        },
        customisation: {
            fontColor: [theme.color.blackGrey, theme.color.blackGrey, theme.color.blackGrey, theme.color.blackGrey],
            backgroundColor: [theme.color.grey3, theme.color.grey3, theme.color.grey3, theme.color.grey3],
            borderColor: [theme.color.grey2, theme.color.grey2, theme.color.grey2, theme.color.grey2],
        },
        formula: {
            fontColor: [theme.color.white, theme.color.white, theme.color.black],
            backgroundColor: [theme.color.evilBlack, theme.color.blackGrey, theme.color.grey1],
            borderColor: [theme.color.white, theme.color.white, theme.color.white],
        },
        formulaHeader: {
            fontColor: [theme.color.white, theme.color.white, theme.color.black],
            backgroundColor: [theme.color.evilBlack, theme.color.blackGrey, theme.color.grey1],
            borderColor: [theme.color.white, theme.color.white, theme.color.white],
        },
    },
    [LineStatus.ToDo]: {
        item: {
            fontColor: [theme.color.black, theme.color.black, theme.color.black, theme.color.black],
            backgroundColor: [theme.color.grey3, theme.color.grey3, theme.color.grey3, theme.color.grey3],
            borderColor: [theme.color.grey1, theme.color.grey1, theme.color.grey1, theme.color.grey1],
        },
        customisation: {
            fontColor: [theme.color.black, theme.color.black, theme.color.black, theme.color.black],
            backgroundColor: [theme.color.grey3, theme.color.grey3, theme.color.grey3, theme.color.grey3],
            borderColor: [theme.color.grey1, theme.color.grey1, theme.color.grey1, theme.color.grey1],
        },
        formula: {
            fontColor: [theme.color.white, theme.color.white, theme.color.black],
            backgroundColor: [theme.color.evilBlack, theme.color.blackGrey, theme.color.grey1],
            borderColor: [theme.color.evilBlack, theme.color.grey1, theme.color.grey1],
        },
        formulaHeader: {
            fontColor: [theme.color.white, theme.color.white, theme.color.black],
            backgroundColor: [theme.color.evilBlack, theme.color.blackGrey, theme.color.grey1],
            borderColor: [theme.color.evilBlack, theme.color.grey1, theme.color.grey1],
        },
    },
    [LineStatus.Cancelled]: {
        item: {
            fontColor: [theme.color.bloodyMary, theme.color.bloodyMary, theme.color.bloodyMary, theme.color.bloodyMary],
            backgroundColor: [theme.color.grey3, theme.color.grey3, theme.color.grey3, theme.color.grey3],
            borderColor: [theme.color.grey1, theme.color.grey1, theme.color.grey1, theme.color.grey1],
        },
        customisation: {
            fontColor: [theme.color.bloodyMary, theme.color.bloodyMary, theme.color.bloodyMary, theme.color.bloodyMary],
            backgroundColor: [theme.color.grey3, theme.color.grey3, theme.color.grey3, theme.color.grey3],
            borderColor: [theme.color.grey1, theme.color.grey1, theme.color.grey1, theme.color.grey1],
        },
        formula: {
            fontColor: [theme.color.white, theme.color.white, theme.color.black],
            backgroundColor: [theme.color.evilBlack, theme.color.blackGrey, theme.color.grey1],
            borderColor: [theme.color.white, theme.color.white, theme.color.white],
        },
        formulaHeader: {
            fontColor: [theme.color.white, theme.color.white, theme.color.black],
            backgroundColor: [theme.color.evilBlack, theme.color.blackGrey, theme.color.grey1],
            borderColor: [theme.color.white, theme.color.white, theme.color.white],
        },
    },
    [LineStatus.Doing]: {
        item: {
            fontColor: [theme.color.white, theme.color.white, theme.color.white, theme.color.white],
            backgroundColor: [
                theme.color.electricBlue2,
                theme.color.electricBlue3,
                theme.color.electricBlue3,
                theme.color.electricBlue3,
            ],
            borderColor: [theme.color.sweetBlue, theme.color.white, theme.color.white, theme.color.white],
        },
        customisation: {
            fontColor: [theme.color.white, theme.color.white, theme.color.white, theme.color.white],
            backgroundColor: [
                theme.color.electricBlue2,
                theme.color.electricBlue3,
                theme.color.electricBlue3,
                theme.color.electricBlue3,
            ],
            borderColor: [theme.color.white, theme.color.white, theme.color.white, theme.color.white],
        },
        formula: {
            fontColor: [theme.color.white, theme.color.white, theme.color.white],
            backgroundColor: [theme.color.electricBlue, theme.color.electricBlue2, theme.color.electricBlue3],
            borderColor: [theme.color.electricBlue, theme.color.white, theme.color.white],
        },
        formulaHeader: {
            fontColor: [theme.color.white, theme.color.white, theme.color.white],
            backgroundColor: [theme.color.electricBlue, theme.color.electricBlue2, theme.color.electricBlue3],
            borderColor: [theme.color.electricBlue, theme.color.white, theme.color.white],
        },
    },
    [LineStatus.ToAssemble]: {
        item: {
            fontColor: [theme.color.grey1, theme.color.grey1, theme.color.grey1, theme.color.grey1],
            backgroundColor: [theme.color.white, theme.color.white, theme.color.white, theme.color.white],
            borderColor: [theme.color.grey3, theme.color.grey3, theme.color.grey3, theme.color.grey3],
        },
        customisation: {
            fontColor: [theme.color.grey1, theme.color.grey1, theme.color.grey1, theme.color.grey1],
            backgroundColor: [theme.color.white, theme.color.white, theme.color.white, theme.color.white],
            borderColor: [theme.color.grey3, theme.color.grey3, theme.color.grey3, theme.color.grey3],
        },
        formula: {
            fontColor: [theme.color.grey1, theme.color.grey1, theme.color.grey1],
            backgroundColor: [theme.color.white, theme.color.white, theme.color.white],
            borderColor: [theme.color.grey1, theme.color.grey1, theme.color.grey1],
        },
        formulaHeader: {
            fontColor: [theme.color.grey1, theme.color.grey1, theme.color.grey1],
            backgroundColor: [theme.color.white, theme.color.white, theme.color.white],
            borderColor: [theme.color.grey1, theme.color.grey1, theme.color.grey1],
        },
    },
    [LineStatus.Done]: {
        item: {
            fontColor: [theme.color.grey1, theme.color.grey1, theme.color.grey1, theme.color.grey1],
            backgroundColor: [theme.color.white, theme.color.white, theme.color.white, theme.color.white],
            borderColor: [theme.color.grey3, theme.color.grey3, theme.color.grey3, theme.color.grey3],
        },
        customisation: {
            fontColor: [theme.color.grey1, theme.color.grey1, theme.color.grey1, theme.color.grey1],
            backgroundColor: [theme.color.white, theme.color.white, theme.color.white, theme.color.white],
            borderColor: [theme.color.grey3, theme.color.grey3, theme.color.grey3, theme.color.grey3],
        },
        formula: {
            fontColor: [theme.color.grey1, theme.color.grey1, theme.color.grey1],
            backgroundColor: [theme.color.white, theme.color.white, theme.color.white],
            borderColor: [theme.color.grey1, theme.color.grey1, theme.color.grey1],
        },
        formulaHeader: {
            fontColor: [theme.color.grey1, theme.color.grey1, theme.color.grey1],
            backgroundColor: [theme.color.white, theme.color.white, theme.color.white],
            borderColor: [theme.color.grey1, theme.color.grey1, theme.color.grey1],
        },
    },
    [LineStatus.New]: {
        item: {
            fontColor: [theme.color.white, theme.color.white, theme.color.white, theme.color.white],
            backgroundColor: [
                theme.color.goldenHour,
                theme.color.goldenHour,
                theme.color.goldenHour,
                theme.color.goldenHour,
            ],
            borderColor: [theme.color.peach, theme.color.peach, theme.color.peach, theme.color.peach],
        },
        customisation: {
            fontColor: [theme.color.white, theme.color.white, theme.color.white, theme.color.white],
            backgroundColor: [
                theme.color.goldenHour,
                theme.color.goldenHour,
                theme.color.goldenHour,
                theme.color.goldenHour,
            ],
            borderColor: [theme.color.peach, theme.color.peach, theme.color.peach, theme.color.peach],
        },
        formula: {
            fontColor: [theme.color.white, theme.color.white, theme.color.black],
            backgroundColor: [theme.color.evilBlack, theme.color.blackGrey, theme.color.grey1],
            borderColor: [theme.color.white, theme.color.white, theme.color.white],
        },
        formulaHeader: {
            fontColor: [theme.color.white, theme.color.white, theme.color.black],
            backgroundColor: [theme.color.evilBlack, theme.color.blackGrey, theme.color.grey1],
            borderColor: [theme.color.white, theme.color.white, theme.color.white],
        },
    },
    [LineStatus.QuantityModified]: {
        item: {
            fontColor: [theme.color.white, theme.color.white, theme.color.white, theme.color.white],
            backgroundColor: [
                theme.color.goldenHour,
                theme.color.goldenHour,
                theme.color.goldenHour,
                theme.color.goldenHour,
            ],
            borderColor: [theme.color.peach, theme.color.peach, theme.color.peach, theme.color.peach],
        },
        customisation: {
            fontColor: [theme.color.white, theme.color.white, theme.color.white, theme.color.white],
            backgroundColor: [
                theme.color.goldenHour,
                theme.color.goldenHour,
                theme.color.goldenHour,
                theme.color.goldenHour,
            ],
            borderColor: [theme.color.peach, theme.color.peach, theme.color.peach, theme.color.peach],
        },
        formula: {
            fontColor: [theme.color.white, theme.color.white, theme.color.black],
            backgroundColor: [theme.color.evilBlack, theme.color.blackGrey, theme.color.grey1],
            borderColor: [theme.color.white, theme.color.white, theme.color.white],
        },
        formulaHeader: {
            fontColor: [theme.color.white, theme.color.white, theme.color.black],
            backgroundColor: [theme.color.evilBlack, theme.color.blackGrey, theme.color.grey1],
            borderColor: [theme.color.white, theme.color.white, theme.color.white],
        },
    },
};
