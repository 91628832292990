import React from 'react';
import { useSelector } from 'react-redux';
import { getProducibleMap } from '../../redux/tickets/selector';
import ProductionList from '../../components/ProductionList';

const Production: React.FunctionComponent = () => {
    const items = useSelector(getProducibleMap);

    return <ProductionList items={Object.values(items)} />;
};

export default Production;
