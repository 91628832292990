import firebase from 'firebase/app';
import { firebaseConfig } from '../../constants/firebaseEnv';
import { getAuthFirestoreToken } from '../../api/firestore';

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const authenticateToFirestore = async (apiAccessToken: string, restaurantId: number): Promise<void> => {
    const firestoreToken = await getAuthFirestoreToken(apiAccessToken, restaurantId);
    await firebase.auth().signInWithCustomToken(firestoreToken);
};
