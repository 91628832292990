import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { createBrowserHistory, History } from 'history';
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { crashReporterMiddleware } from './middlewares/crashReporter';
import { ticketsMiddleware } from './tickets/middleware';
import { configurationMiddleware } from './configuration/middleware';
import { watchAuth } from './auth/saga';
import { watchConfiguration } from './configuration/saga';
import { watchClock } from './clock/saga';
import { ticketInteractionSaga } from './tickets/user.saga';
import { appReducer } from './app';
import { AppActions } from './app/action';
import { AppState } from './app/types';
import { authReducer, AuthState } from './auth';
import { clockReducer } from './clock';
import { ClockActions } from './clock/action';
import { ClockState } from './clock/types';
import { configurationReducer, ConfigurationState } from './configuration';
import { ConfigurationActions } from './configuration/actions';
import { localConfigurationReducer, LocalConfigurationState } from './localConfig';
import { ticketsReducer } from './tickets';
import { FirestoreTicketsActions } from './tickets/firestore.actions';
import { StoredTicketsActions } from './tickets/stored.actions';
import { userReducer, UserState } from './user';
import { persistConfig } from './persist';
import { PersistRehydrateAction } from './persist/actions';
import { TicketsState } from './tickets/types/ticket';
import hardwareReducer, { HardwareActions, HardwareState } from './hardware/hardware.reducer';
import { watchHardwareActions } from './hardware/hardware.sagas';
import printReducer, { PrintState } from './print/print.reducer';
import { PrintActions } from './print/print.actions';
import { watchPrintActions } from './print/print.sagas';

// SETUP SAGA
const sagaMiddleware = createSagaMiddleware();

function* rootSaga(): Generator {
    yield all([
        watchAuth(),
        watchConfiguration(),
        ticketInteractionSaga(),
        watchClock(),
        watchHardwareActions(),
        watchPrintActions(),
    ]);
}

// COMBINE REDUCERS
export type RootState = {
    auth: AuthState;
    user: UserState;
    configuration: ConfigurationState;
    localConfiguration: LocalConfigurationState;
    tickets: TicketsState;
    clock: ClockState;
    app: AppState;
    router: RouterState;
    hardware: HardwareState;
    print: PrintState;
};

export type AllActions =
    | StoredTicketsActions
    | FirestoreTicketsActions
    | ClockActions
    | AppActions
    | ConfigurationActions
    | PersistRehydrateAction
    | HardwareActions
    | PrintActions;

const createCombinedReducers = (history: History<any>) => {
    const rootReducer = combineReducers({
        router: connectRouter(history),
        hardware: hardwareReducer,
        print: printReducer,
        auth: authReducer,
        user: userReducer,
        configuration: configurationReducer,
        localConfiguration: localConfigurationReducer,
        tickets: ticketsReducer,
        clock: clockReducer,
        app: appReducer,
    });

    return persistReducer(persistConfig, rootReducer);
};

// CREATE STORE
export const history = createBrowserHistory();
// FIXME
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const composeEnhancers =
    // FIXME
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/no-unsafe-member-access
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    createCombinedReducers(history),
    {},
    // FIXME
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    composeEnhancers(
        applyMiddleware(
            crashReporterMiddleware,
            sagaMiddleware,
            routerMiddleware(history),
            ticketsMiddleware,
            configurationMiddleware,
        ),
    ),
);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export default { store, persistor };
