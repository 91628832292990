import * as React from 'react';
import styled from '@emotion/styled';
import HeaderClock from '../HeaderClock';
import HeaderIcons from '../HeaderIcons';
import HeaderTabs from '../HeaderTabs';

export const HEADER_BAR_HEIGHT = '80px';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: ${HEADER_BAR_HEIGHT};
    justify-content: space-between;
    background-color: ${({ theme }) => theme.color.midnightBlue};
`;

const Left = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
`;

const HeaderBar: React.FunctionComponent = () => (
    <Container>
        <Left>
            <HeaderClock />
            <HeaderTabs />
        </Left>
        <HeaderIcons />
    </Container>
);

export default HeaderBar;
