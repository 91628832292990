export const assertUnreachable = (x: never): never => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    throw new Error(`assertUnreachable called with ${x}`);
};

export const enumKeys = (theEnum: { [key: string]: unknown }): string[] =>
    Object.keys(theEnum ?? {}).filter((key) => Number.isNaN(Number(key)));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const enumValues = <T>(theEnum: { [key: string]: T } | any): T[] =>
    enumKeys(theEnum).map<T>((key) => (theEnum as { [key: string]: T })[key]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const enumEntries = <T>(sourceEnum: { [key: string]: T } | any): [string, T][] => {
    const keys = enumKeys(sourceEnum);
    const values = enumValues(sourceEnum);

    return keys.map((key, index) => [key, values[index] as T]);
};

export const enumToFilterObject = <T extends string | number, U>(
    sourceEnum: Record<string, T>,
    value: U,
): Record<T, U> =>
    enumValues(sourceEnum).reduce((filterObject: Partial<Record<T, U>>, currentValue) => {
        filterObject[currentValue as T] = value;

        return filterObject;
    }, {} as Partial<Record<T, U>>) as Record<T, U>;

export function throwIfFalsy<T>(x: T | null | undefined, message = 'This should never happen'): asserts x is T {
    if (!x) {
        throw new Error(message);
    }
}

export function transformToBase64(source: string): string {
    return Buffer.from(source).toString('base64');
}
