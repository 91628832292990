export enum CustomizationType {
    Choice = 'CHOICE',
    Add = 'ADD',
    Remove = 'REMOVE',
}

export type LineCustomization = {
    name: string;
    type: CustomizationType;
    value: string;
    quantity: number;
};
