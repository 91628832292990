import { createReducer } from 'typesafe-actions';
import { ConfigurationActions, configurationFail, configurationGet, configurationSet } from './actions';

export type ConfigurationData = {
    kitchenDisplayId: number | undefined;
    isTagsWhiteListEnabled: boolean;
    tagsWhiteList: string;
    minutesBeforeEmergency: number;
    emergencyColor: string;
    minutesBeforeFirstRecall: number;
    firstRecallColor: string;
    minutesBeforeDisplayTakeAway: number | null;
    minutesBeforeDisplayDelivering: number | null;
    minutesBeforeDisplayEatIn: number | null;
    odsTitleColor: string;
    odsHeaderColor: string;
    odsBackgroundColor: string;
    odsLogoImage: string | null;
    odsBackgroundImage: string | null;
    brandId: number | undefined;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    isAssembleEnabled: boolean;
    isDisplayEnabled: boolean;
    isAutoDisplayEnabled: boolean;
};

export type ConfigurationState = {
    data: ConfigurationData;
    isTagsWhiteListEnabled: boolean;
    tagsWhiteList: string[];
    isFetching: boolean;
};

const defaultState: ConfigurationState = {
    // all of theses data will be overwritten by api config (only here for typing).
    data: {
        kitchenDisplayId: undefined,
        isTagsWhiteListEnabled: false,
        tagsWhiteList: '',
        minutesBeforeEmergency: 15,
        emergencyColor: '#D56A39',
        minutesBeforeFirstRecall: 5,
        firstRecallColor: '#F3C14E',
        minutesBeforeDisplayTakeAway: null,
        minutesBeforeDisplayDelivering: null,
        minutesBeforeDisplayEatIn: null,
        odsTitleColor: '',
        odsHeaderColor: '',
        odsBackgroundColor: '',
        odsLogoImage: null,
        odsBackgroundImage: null,
        brandId: undefined,
        created_at: '',
        updated_at: '',
        deleted_at: '',
        isAssembleEnabled: false,
        isDisplayEnabled: false,
        isAutoDisplayEnabled: false,
    },
    isTagsWhiteListEnabled: false,
    tagsWhiteList: [],
    isFetching: false,
};

export const configurationReducer = createReducer<ConfigurationState, ConfigurationActions>(defaultState)
    .handleAction(configurationGet, (state) => ({
        ...state,
        isFetching: true,
    }))
    .handleAction(configurationSet, (state, { payload: { configuration, receivedAt } }) => ({
        ...state,
        data: configuration,
        isTagsWhiteListEnabled: configuration.isTagsWhiteListEnabled,
        tagsWhiteList: configuration.isTagsWhiteListEnabled ? configuration.tagsWhiteList.split(',') : [],
        lastUpdated: receivedAt,
        isFetching: false,
    }))
    .handleAction(configurationFail, (state) => ({
        ...state,
        isFetching: false,
    }));
