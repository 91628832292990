import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { getDisplayingTickets, getInPreparationTickets } from '../../redux/tickets/selector';
import DisplayedOrder from './DisplayedOrder';
import PreparingOrder from './PreparingOrder';
import { ReactComponent as DONE } from './icons/DONE.svg';
import { ReactComponent as CLOCK } from './icons/CLOCK.svg';
import { getOrdersInPreparationInClientDisplay } from '../../redux/localConfig/selector';

const Container = styled.div`
    display: flex;
    overflow: hidden;
`;
const Ready = styled.div`
    flex: 1;
    background-color: ${({ theme }) => theme.color.black};
`;

const DisplayedOrderContainer = styled.div<{ orderInPreparation: boolean }>`
    padding: 20px;
    display: grid;
    align-content: start;
    grid-template-columns: repeat(${({ orderInPreparation }) => (orderInPreparation ? 4 : 6)}, 1fr);
    gap: 20px;
    justify-items: center;

    @media screen and (max-width: 1530px) {
        grid-template-columns: repeat(${({ orderInPreparation }) => (orderInPreparation ? 4 : 5)}, 1fr);
    }

    @media screen and (max-width: 1500px) {
        grid-template-columns: repeat(${({ orderInPreparation }) => (orderInPreparation ? 3 : 4)}, 1fr);
    }

    @media screen and (max-width: 1000px) {
        grid-template-columns: repeat(${({ orderInPreparation }) => (orderInPreparation ? 2 : 3)}, 1fr);
    }
`;

const SideList = styled.div`
    width: 32vw;
    background-color: ${({ theme }) => theme.color.sweetBlack};
`;

const NumberContainer = styled.div`
    height: 100vh;

    overflow: scroll;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const Title = styled.p`
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ theme }) => theme.font.familySemiBold};
    font-size: ${({ theme }) => theme.size.textBig};
    color: ${({ theme }) => theme.color.white};
    text-align: center;
    padding: 30px;
`;

const DisplayedOrdersList: React.FunctionComponent = () => {
    const tickets = useSelector(getDisplayingTickets);
    const ordersInPreparationInClientDisplay = useSelector(getOrdersInPreparationInClientDisplay);
    const preparingTickets = useSelector(getInPreparationTickets);

    return (
        <Container data-testid="displayed-orders-list">
            <>
                <Ready>
                    <Title>
                        <DONE style={{ marginRight: '10px' }} />
                        Commandes disponibles
                    </Title>
                    <DisplayedOrderContainer orderInPreparation={ordersInPreparationInClientDisplay}>
                        {tickets.length > 0 &&
                            tickets.map((ticket) => <DisplayedOrder ticket={ticket} key={ticket.orderId} />)}
                    </DisplayedOrderContainer>
                </Ready>
                {ordersInPreparationInClientDisplay && (
                    <SideList>
                        <Title>
                            <CLOCK style={{ marginRight: '10px' }} />
                            En préparation
                        </Title>
                        <NumberContainer>
                            {preparingTickets.map((ticket) => (
                                <PreparingOrder ticket={ticket} key={ticket.orderId} />
                            ))}
                        </NumberContainer>
                    </SideList>
                )}
            </>
        </Container>
    );
};

export default DisplayedOrdersList;
