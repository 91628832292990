import React from 'react';

// @deprecated: do not use beside ticket resizing
export const TicketAutoSize: React.FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = (props) => {
    const ref = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        const handleResize = () => {
            for (let index = 0; index < (ref.current?.children?.length ?? 0); index++) {
                const parentElt = ref.current as HTMLDivElement;
                const element = ref.current?.children[index] as HTMLDivElement;
                if (element.scrollWidth > element.clientWidth) {
                    const defaultPadding =
                        Number(window.getComputedStyle(element, 'padding').padding?.replace('px', '')) ?? 0;
                    parentElt.style.width = `${element.scrollWidth + defaultPadding}px`;
                }
            }
        };
        handleResize();
        ref.current?.addEventListener('resize', handleResize);
        return () => ref.current?.removeEventListener('resize', handleResize);
    }, []);

    return <div ref={ref} {...props} />;
};
