import * as React from 'react';
import { isEqual } from 'lodash';
import { Routes } from '../../../routes.types';
import { isGroupedTicket } from '../../../services/tickets/isGroupedTicket';
import GroupedTicket from '../components/GroupedTicket';
import SimpleTicket from '../components/SimpleTicket';
import { useTicketCardVm } from './TicketCard.viewmodel';
import { SimpleOrGroupKdsTicket } from '../../../redux/tickets/types/ticket';

type Props = {
    ticket: SimpleOrGroupKdsTicket;
    route: Routes;
    isDisplayMode: boolean;
};

const TicketCard = ({ ticket, route, isDisplayMode }: Props): JSX.Element => {
    const {
        isCompactMode,
        dispatchUndisplayTicketClicked,
        dispatchGroupedUndisplayTickets,
        dispatchGroupedTicketClicked,
        dispatchGroupedLineClicked,
        dispatchTicketClicked,
        dispatchLineClicked,
        dispatchStatusChange,
    } = useTicketCardVm({ isDisplayMode, route });

    return (
        <>
            {isGroupedTicket(ticket) ? (
                <GroupedTicket
                    key={ticket.groupingKey}
                    ticket={ticket}
                    isCompactMode={isCompactMode}
                    dispatchGroupedTicketClicked={dispatchGroupedTicketClicked}
                    dispatchGroupedLineClicked={dispatchGroupedLineClicked}
                    dispatchGroupedUndisplayTickets={dispatchGroupedUndisplayTickets}
                    dispatchStatusChange={dispatchStatusChange}
                    route={route}
                />
            ) : (
                <SimpleTicket
                    key={ticket.orderId}
                    ticket={ticket}
                    isCompactMode={isCompactMode}
                    dispatchTicketClicked={dispatchTicketClicked}
                    dispatchLineClicked={dispatchLineClicked}
                    dispatchUndisplayTicketClicked={dispatchUndisplayTicketClicked}
                    dispatchStatusChange={dispatchStatusChange}
                    route={route}
                />
            )}
        </>
    );
};

export default React.memo(TicketCard, isEqual);
