import styled from '@emotion/styled';

export const Container = styled.div<{ color: string }>`
    border-radius: 6px 6px 0 0;
    padding: 8px 12px;
    background-color: ${({ color }) => color};
`;

export const Flex = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const TicketHeaderSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const TicketNumber = styled.p`
    color: ${({ theme }) => theme.color.white};
    ${({ theme }) => theme.font.familySemiBold};
    font-size: 36px;
    height: 45px;
    margin: 4px 0 0 10px;
`;

export const HeaderText = styled.p`
    color: ${({ theme }) => theme.color.white};
    ${({ theme }) => theme.font.family};
    margin: 5px 0 0;
    font-size: ${({ theme }) => theme.size.text};
`;

export const HeaderIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
