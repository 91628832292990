import * as React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { theme as mainTheme } from '../../../theme';
import { KdsTicket } from '../../../redux/tickets/types/ticket';
import { getRecallColors, getRecallTimeBoundaries } from '../../../redux/configuration/selectors';
import { getClockTime } from '../../../redux/clock/selector';
import { getActualRoute } from '../../../redux/router/selector';
import { isOrderPaid } from '../utils';
import ConsumptionModeIcon from './ConsumptionModeIcon';
import Timer from './Timer';
import TicketHeaderStatus from './TicketHeaderStatus';
import { computeHeaderBackgroundColor, computeLegendText, computeShouldDisplayTimer } from './utils';
import ChannelIcon from './ChannelIcon';
import {
    Container,
    TicketHeaderSection,
    Flex,
    TicketNumber,
    HeaderText,
    HeaderIconContainer,
} from './TicketHeader.style';
import { ReactComponent as ToPayIcon } from '../../../icons/TO_PAY.svg';

type Props = KdsTicket & {
    dispatchTicketClicked: (orderId: string) => void;
};

const TicketHeader: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    dispatchTicketClicked,
    consumptionMode,
    ticketNumber,
    prepareAt,
    customerFirstName,
    tableName,
    orderId,
    customerLastName,
    expectedAt,
    channelId,
    status,
    orderStatus,
}) => {
    const colors = useSelector(getRecallColors);
    const timeBoundaries = useSelector(getRecallTimeBoundaries);
    const clockTime = useSelector(getClockTime);
    const actualRoute = useSelector(getActualRoute);

    const duration = dayjs(clockTime).diff(prepareAt, 's');
    const headerColor = computeHeaderBackgroundColor(
        duration,
        timeBoundaries,
        [mainTheme.color.sweetMint, ...colors],
        actualRoute,
        status,
    );

    const legendTextContent = computeLegendText({
        customerFirstName,
        customerLastName,
        tableName,
        consumptionMode,
        expectedAt,
        ticketNumber,
    });

    const shouldDisplayTimer = computeShouldDisplayTimer(status, actualRoute);
    const shouldDisplayToPayIcon = !isOrderPaid(orderStatus);

    return (
        <Container color={headerColor} data-testid="ticket-header" onClick={() => dispatchTicketClicked(orderId)}>
            <TicketHeaderSection>
                <Flex>
                    <ConsumptionModeIcon
                        mode={consumptionMode}
                        stroke={mainTheme.color.white}
                        fill={mainTheme.color.white}
                    />
                    <TicketNumber data-testid="ticket-number">{ticketNumber}</TicketNumber>
                </Flex>
                {shouldDisplayTimer && <Timer duration={duration} />}
                {!shouldDisplayTimer && <TicketHeaderStatus status={status} />}
            </TicketHeaderSection>
            <TicketHeaderSection>
                <HeaderText data-testid="ticket-info-text">{legendTextContent}</HeaderText>
                <HeaderIconContainer>
                    {shouldDisplayToPayIcon && (
                        <ToPayIcon
                            data-testid="to-pay-icon"
                            width={35}
                            height={35}
                            stroke={mainTheme.color.white}
                            fill={mainTheme.color.white}
                            strokeWidth={0.5}
                            style={{ padding: '6px' }}
                        />
                    )}
                    <ChannelIcon channelId={channelId} stroke={mainTheme.color.white} fill={mainTheme.color.white} />
                </HeaderIconContainer>
            </TicketHeaderSection>
        </Container>
    );
};

export default React.memo(TicketHeader);
