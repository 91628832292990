import React from 'react';
import styled from '@emotion/styled';

const InfoLineContainer = styled.div`
    padding: 24px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const InfoLineTitle = styled.p`
    ${({ theme }) => theme.font.family};
    color: ${({ theme }) => theme.color.evilBlack};
    font-size: ${({ theme }) => theme.size.text};
    width: 100%;
`;

const InfoLineId = styled.p`
    ${({ theme }) => theme.font.familySemiBold};
    color: ${({ theme }) => theme.color.midnightBlue};
    font-size: ${({ theme }) => theme.size.textMedium};
    width: fit-content;
`;

const InfoLineName = styled.p`
    ${({ theme }) => theme.font.familySemiBold};
    color: ${({ theme }) => theme.color.midnightBlue};
    font-size: ${({ theme }) => theme.size.text};
    width: fit-content;
`;

type Props = {
    title: string;
    id?: number;
    name: string;
};

export const InfoLine: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ title, id, name }) => (
    <InfoLineContainer>
        <InfoLineTitle>{title}</InfoLineTitle>
        {id !== undefined && <InfoLineId>{id}</InfoLineId>}
        <InfoLineName>{name}</InfoLineName>
    </InfoLineContainer>
);
