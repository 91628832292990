import { KdsTicket } from '../types/ticket';

export const getTicketsFilteredByTableName = (tickets: KdsTicket[], tableNames: string[]): KdsTicket[] => {
    if (tableNames.length === 0) {
        return tickets;
    }

    return tickets.filter(({ tableName }) =>
        tableNames
            .map((filterTableName) => filterTableName.toLowerCase())
            .includes(tableName?.toLowerCase() ?? 'undefined'),
    );
};
