import * as React from 'react';
import styled from '@emotion/styled';
import { TicketStatus } from '../../../../redux/tickets/types/ticket';

const Label = styled.p`
    ${({ theme }) => theme.font.familyBold};
    color: ${({ theme }) => theme.color.white};
    font-size: ${({ theme }) => theme.size.text};
    text-transform: uppercase;
`;

type Props = {
    status: TicketStatus;
};

const mapStatusToLabel = (status: TicketStatus): string => {
    switch (status) {
        case TicketStatus.ToDisplay:
            return 'PRÊTE';
        case TicketStatus.Displaying:
            return 'AFFICHÉE';
        case TicketStatus.Cancelled:
            return 'ANNULÉE';

        default:
            return '';
    }
};

const TicketHeaderStatus: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ status }) => (
    <Label data-testid="header-status">{mapStatusToLabel(status)}</Label>
);

export default TicketHeaderStatus;
